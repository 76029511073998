import React, { useState, useEffect } from "react";
import Loader from "../../layout/Loader";
import { ToastContainer, toast } from "react-toastify";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { addBusinessDetails, updateBusinessDetails } from "../../../service/superAdmin.service";
import { useLocation, useNavigate } from "react-router-dom";
import "react-phone-number-input/style.css";
import PhoneInput, {
  formatPhoneNumber,
  formatPhoneNumberIntl,
  isPossiblePhoneNumber,
  isValidPhoneNumber,
} from "react-phone-number-input";

export default function UpdateBusiness() {
  const navigate = useNavigate();
  let location = useLocation();
  const token = useSelector((state) => state.status.token.token);
  const [businessDetails, setBusinessDetails] = useState();
  const [isLoading, setIsLoading] = useState(false); // Add loading state
  const [businessLogo, setBusinessLogo] = useState(null);
  const [currency, setCurrency] = useState("");
  const [currencySymbol, setCurrencySymbol] = useState("");
  const [editedImage, setEditedImage] = useState(null);
  const [updateBusiness, setUpdateBusiness] = useState();
  const [phoneValue, setPhoneValue] = useState("");
  const [country, setCountry] = useState("IN");
  const [availablePlans, setAvailablePlans] = useState([
    { value: "1", label: "$200.00" },
    { value: "2", label: "$300.00" },
  ]);

  useEffect(() => {
    window.scrollTo(0, 1);
    if (location?.state?.businessDetails) {
      const initialUpdateBusiness = { ...location.state.businessDetails };
      setBusinessDetails(initialUpdateBusiness);
      setUpdateBusiness(initialUpdateBusiness);
      setEditedImage(initialUpdateBusiness.restaurant_url);
      setCurrency(initialUpdateBusiness.currency);
      setCurrencySymbol(initialUpdateBusiness.currency_symbol);
      // Set values for react-hook-form
      setValue("businessName", initialUpdateBusiness.name);
      setValue("email", initialUpdateBusiness.email);
      setValue("phone", initialUpdateBusiness.phone);
      setPhoneValue(initialUpdateBusiness.phone);
      setValue("address", initialUpdateBusiness.address);
      setValue("country", initialUpdateBusiness.country);
      setValue("state", initialUpdateBusiness.state);
      setValue("city", initialUpdateBusiness.city);
      setValue("currency", initialUpdateBusiness.currency.toUpperCase());
      setValue("currencySymbol", initialUpdateBusiness.currency_symbol);
      setValue("zip", initialUpdateBusiness.zip);
      setValue("plan", initialUpdateBusiness.plan);
      setValue("aboutBusiness", initialUpdateBusiness.desc);
      setValue("workingHour", initialUpdateBusiness.working_hours);
      handleCountryChange(initialUpdateBusiness.country);
    }
  }, [location.state.businessDetails]);

  const {
    register,
    setValue,
    watch,
    setError,
    reset,
    handleSubmit: handleSubmitUpdate,
    formState: { errors },
  } = useForm({
    defaultValues: {
      businessName: businessDetails?.name || "",
      email: businessDetails?.email || "",
      phone: businessDetails?.phone || "",
      address: businessDetails?.address || "",
      country: businessDetails?.country || "",
      state: businessDetails?.state || "",
      city: businessDetails?.city || "",
      zip: businessDetails?.zip || "",
      currency: businessDetails?.currency || "",
      currencySymbol: businessDetails?.currency_symbol || "",
      plan: businessDetails?.plan || "",
      aboutBusiness: businessDetails?.desc || "",
      workingHour: businessDetails?.working_hours || "",
    },
  });

  const addData = (item) => {

    let data1 = {
        restaurant_id: updateBusiness.restaurant_id,
        name: item.businessName,
        email: item.email,
        phone: item.phone,
        address: item.address,
        country: item.country,
        state: item.state,
        city: item.city,
        zip: item.zip,
        currency: item.currency,
        currency_symbol: item.currencySymbol,
        plan: item.plan,
        desc: item.aboutBusiness,
        working_hours: item.workingHour,
        template_name: "Template_1.png",
        nearby_zip: "NULL",
        status: 'ACTIVE'
    };

    if (typeof editedImage === "string") {
        data1.restaurant_url = editedImage;
      } else {
          const sanitizedFileName = editedImage.name.replace(/ /g, '_').replace(/&/g, '');
          const updatedFile = new File([editedImage], sanitizedFileName, { type: editedImage.type });
          data1.image = updatedFile;
          data1.restaurant_url = process.env.REACT_APP_IMAGE_STORAGE_PATH+updatedFile.name;
      }

    updateBusinessDetails(token, data1).then((res) => {
      toast.success("Business Updated...!", {
        autoClose: 1500,
        style: {
          backgroundColor: "black",
          color: "white",
        },
      });

      reset();
    });
    setCurrency("");
    setCurrencySymbol("");
    reset({ uploadImage: true });
    setBusinessLogo(null);
    setTimeout(() => {
      navigate("/business");
    }, 3000);
  };

  const updateImage = (event) => {
    const input = event.target;
    if (input.files && input.files[0]) {
      const originalFile = input.files[0];
      
      const timestamp = Date.now();
      const uniqueFileName = `${timestamp}_${originalFile.name.replace(/ /g, "_").replace(/&/g, "")}`;
      
      const uniqueFile = new File([originalFile], uniqueFileName, { type: originalFile.type });
    //   setEditedImage(""); 
      setEditedImage(uniqueFile);
    //   setBusinessLogo(uniqueFile); 
  
      const reader = new FileReader();
      reader.onload = function (e) {
        document.getElementById("displayedImage").src = e.target.result;
        // setError("displayedImage", { type: "manual", message: "" });
      };
  
      reader.readAsDataURL(uniqueFile);
    }
  };

  const handleUpdateImageClick = () => {
    // Trigger the click event on the file input when the displayed image is clicked
    document.getElementById("imageInputUpdate").click();
  };

  // currency change handler
  const handleCurrencyChange = (event) => {
    const selectedCurrency = event.target.value;
    setCurrency(selectedCurrency);
    setValue("currency", selectedCurrency);
  };

  // currency symbol change handler
  const handleCurrencySymbolChange = (event) => {
    const selectedCurrencySymbol = event.target.value;
    setCurrencySymbol(selectedCurrencySymbol);
    setValue("currencySymbol", selectedCurrencySymbol);
  };

  // Function to handle the country selection
  const handleCountryChange = (country) => {
    switch (country) {
      case "US":
        setCurrency("USD");
        setCurrencySymbol("$");
        setValue("currency", "USD");
        setValue("currencySymbol", "$");
        setAvailablePlans([
          { value: 1, label: "$200.00" },
          { value: 2, label: "$300.00" },
        ]);
        break;
      case "UK":
        setCurrency("GBP");
        setCurrencySymbol("£");
        setValue("currency", "GBP");
        setValue("currencySymbol", "£");
        setAvailablePlans([
          { value: 1, label: "$200.00" },
          { value: 2, label: "$300.00" },
        ]);
        break;
      case "IN":
        setCurrency("INR");
        setCurrencySymbol("₹");
        setValue("currency", "INR");
        setValue("currencySymbol", "₹");
        setAvailablePlans([
          { value: 21, label: "₹3500.00" },
          { value: 22, label: "₹5000.00" },
          { value: 24, label: "₹6000.00" },
        ]);
        break;
      default:
        setCurrency("");
        setCurrencySymbol("");
        setValue("currency", "");
        setValue("currencySymbol", "");
        setAvailablePlans([
          { value: 1, label: "$200.00" },
          { value: 2, label: "$300.00" },
        ]);
    }
  };

  // Handle Input Change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUpdateBusiness({
      ...updateBusiness,
      [name]: value,
    });
    setValue(name, value);
  };

  const handlePhoneChange = (value) => {
    setPhoneValue(value);
    setValue("phone", value);
  };

  const getMaxLengthForCountry = () => {
    switch (country) {
      case "US":
        return 14; // US phone numbers usually have 14 characters (including country code)
      case "IN":
        return 13; // India phone numbers usually have 13 characters (including country code)
      case "UK":
        return 14; // UK phone numbers usually have 14 characters (including country code)
      default:
        return 15;
    }
  };

  return (
    <div>
      {isLoading ? (
        // Conditional rendering of loader
        <Loader />
      ) : (
        <div className="page-content ms-3 fade-in-up">
          <ToastContainer position="top-center" className="mt-4" limit={1} />

          <div className="">
            <div className="ms-2 me-3">
              <form
                id="editForm"
                data-form-label="update"
                onSubmit={handleSubmitUpdate(addData)}
                // encType="multipart/form-data"
              >
                <div className="modal-content business-modal">
                  <div className="modal-header">
                    <h4 className="modal-title fw-bold fs-5">
                      UPDATE BUSINESS
                    </h4>
                    <button
                      type="button"
                      className="close"
                      onClick={() => window.history.back()}
                    >
                      &times;
                    </button>
                  </div>
                  <div className="modal-body business-data">
                    {/* <p>Some text in the modal.</p> */}
                    <table width={"100%"}>
                      <tbody>
                        <tr>
                          <td>
                            <label>
                              Business Name <i className="fa fa-asterisk"></i>
                            </label>

                            <input
                              {...register("businessName", {
                                required: "Enter business name",
                              })}
                              placeholder="Enter your business name"
                              className="mb-2"
                              onChange={handleInputChange}
                            />
                            {errors.businessName && (
                              <p className="text-danger">
                                {errors.businessName.message}
                              </p>
                            )}
                          </td>
                          <td>
                            <label>
                              Email <i className="fa fa-asterisk"></i>
                            </label>
                            <input
                              {...register("email", {
                                required: "Enter email address",
                                pattern: {
                                  value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/, // Regex for email validation
                                  message: "Enter a valid email address", // Error message for invalid email
                                },
                              })}
                              placeholder="Enter your email"
                              onChange={handleInputChange}
                            />
                            {errors.email && (
                              <p className="text-danger">
                                {errors.email.message}
                              </p>
                            )}
                          </td>
                          <td>
                            <label>
                              Phone <i className="fa fa-asterisk"></i>
                            </label>

                            {/* <input
                              {...register("phone", {
                                required: "Enter phone number",
                                pattern: {
                                  value: /^[0-9]{10}$/, // Regex for validating a 10-digit phone number
                                  message:
                                    "Enter a valid 10-digit phone number", // Error message for invalid phone number
                                },
                              })}
                              placeholder="Enter your phone number"
                              className="mb-2"
                              onChange={handleInputChange}
                            />
                            {errors.phone && (
                              <p className="text-danger">
                                {errors.phone.message}
                              </p>
                            )} */}
                            <PhoneInput
                              international
                              country={country}
                              value={phoneValue}
                              onChange={handlePhoneChange}
                              inputClass="business-modal-phone-input"
                              containerClass="business-modal-phone-container"
                              placeholder="Enter phone number"
                              inputProps={{
                                maxLength: getMaxLengthForCountry(),
                              }}
                            />
                            {/* Phone validation error */}
                            {!phoneValue || !isValidPhoneNumber(phoneValue) ? (
                              <p className="text-danger">
                                Invalid phone number
                              </p>
                            ) : null}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <label>
                              Address <i className="fa fa-asterisk"></i>
                            </label>

                            <input
                              {...register("address", {
                                required: "Enter address",
                              })}
                              placeholder="Enter your address"
                              onChange={handleInputChange}
                            />
                            {errors.address && (
                              <p className="text-danger">
                                {errors.address.message}
                              </p>
                            )}
                          </td>
                          <td>
                            <label>
                              Country <i className="fa fa-asterisk"></i>
                            </label>

                            <select
                              {...register("country", {
                                required: "Select a country",
                              })}
                              className="form-select"
                              onChange={(e) =>
                                handleCountryChange(e.target.value)
                              }
                              disabled
                            >
                              <option value="" disabled>
                                Select your country
                              </option>
                              <option value="US">
                                United States of America
                              </option>
                              <option value="UK">United Kingdom</option>
                              <option value="IN">India</option>
                            </select>
                            {errors.country && (
                              <p className="text-danger">
                                {errors.country.message}
                              </p>
                            )}
                          </td>
                          <td>
                            <label>
                              State <i className="fa fa-asterisk"></i>
                            </label>

                            <input
                              {...register("state", {
                                required: "Enter state",
                              })}
                              placeholder="Enter your state"
                              className="mb-2"
                              onChange={handleInputChange}
                            />
                            {errors.state && (
                              <p className="text-danger">
                                {errors.state.message}
                              </p>
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <label>
                              City <i className="fa fa-asterisk"></i>
                            </label>

                            <input
                              {...register("city", {
                                required: "Enter city",
                              })}
                              placeholder="Enter your city"
                              className="mb-2"
                              onChange={handleInputChange}
                            />
                            {errors.city && (
                              <p className="text-danger">
                                {errors.city.message}
                              </p>
                            )}
                          </td>
                          <td>
                            <label>
                              Zip <i className="fa fa-asterisk"></i>
                            </label>

                            <input
                              {...register("zip", {
                                required: "Enter zip",
                                pattern: {
                                  value: /^\d{6}$|^\d{5}(-\d{4})?$/, // India (6 digits) or US (5 digits or 9 digits with hyphen)
                                  message: "Enter a valid zip code",
                                },
                              })}
                              placeholder="Enter your zip"
                              className="mb-2"
                              onChange={handleInputChange}
                            />
                            {errors.zip && (
                              <p className="text-danger">
                                {errors.zip.message}
                              </p>
                            )}
                          </td>
                          <td>
                            <label>
                              Currency <i className="fa fa-asterisk"></i>
                            </label>

                            <select
                              onChange={(e) => handleCurrencyChange(e)} // Ensure the state is updated on change
                              {...register("currency", {
                                required: "Select a currency",
                              })}
                              className="form-select"
                              disabled
                            >
                              <option value="" disabled>
                                Select your currency
                              </option>
                              <option value="USD">USD</option>
                              <option value="GBP">GBP</option>
                              <option value="INR">INR</option>
                            </select>
                            {errors.currency && currency === "" && (
                              <p className="text-danger">
                                {errors.currency.message}
                              </p>
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <label>
                              Currency Symbol <i className="fa fa-asterisk"></i>
                            </label>

                            <select
                              onChange={(e) => handleCurrencySymbolChange(e)} // Ensure the state is updated on change
                              {...register("currencySymbol", {
                                required: "Select a currency symbol", // Validation message
                              })}
                              className="form-select"
                              disabled
                            >
                              <option value="" disabled>
                                Select your currency symbol
                              </option>
                              <option value="$">$</option>
                              <option value="£">£</option>
                              <option value="₹">₹</option>
                            </select>
                            {errors.currencySymbol && currencySymbol === "" && (
                              <p className="text-danger">
                                {errors.currencySymbol.message}
                              </p>
                            )}
                          </td>
                          <td>
                            <label>
                              Select Plan <i className="fa fa-asterisk"></i>
                            </label>
                            <div className="radio-buttons-container">
                              {availablePlans.map((plan) => (
                                <label key={plan.value} className="radio-label">
                                  <input
                                    value={plan.value}
                                    type="radio"
                                    {...register("plan", {
                                      required: "Please select a plan", // Validation message
                                    })}
                                    checked={
                                        String(watch("plan")) === String(plan.value)
                                    } // Use watch to control the checked state
                                    onChange={handleInputChange}
                                    className="mt-3"
                                  />{" "}
                                  {plan.label}
                                </label>
                              ))}
                            </div>
                            {errors.plan && (
                              <p className="text-danger">
                                {errors.plan.message}
                              </p>
                            )}
                          </td>
                          <td>
                            <label>
                              Working Hours <i className="fa fa-asterisk"></i>
                            </label>

                            <input
                              {...register("workingHour", {
                                required: "Enter working hours",
                              })}
                              placeholder="Enter your working hours"
                              className="mb-2"
                              onChange={handleInputChange}
                            />
                            {errors.workingHour && (
                              <p className="text-danger">
                                {errors.workingHour.message}
                              </p>
                            )}
                          </td>
                        </tr>
                        <tr>
                        <td>
                              <label>
                                Business Logo <i className="fa fa-asterisk"></i>
                              </label>

                              {/* Hidden file input */}
                              <input
                                type="file"
                                id="imageInputUpdate"
                                accept="image/*"
                                style={{ display: "none" }}
                                onChange={updateImage}
                              />
                              {/* Displayed image */}
                              <div className="ms-3">
                                <img
                                  src={editedImage}
                                  id="displayedImage"
                                  alt={`Image`}
                                  onClick={() => handleUpdateImageClick()}
                                  className="ml-0"
                                  // {...register("editedImage", {
                                  //   required: "Add Image",
                                  // })}
                                  // data-bs-dismiss="#editSubCategoryModal"
                                  // data-bs-target="#addImageModal"
                                  // data-bs-toggle="modal"
                                  // onClick={hideSubcategoryModal}
                                />
                              </div>
                              {/* {errors.editedImage && (
                  <p className="text-danger">
                    {errors.editedImage.message}
                  </p>
                )} */}
                            </td>
                          <td>
                            <label>
                              About Business <i className="fa fa-asterisk"></i>
                            </label>
                            <textarea
                              {...register("aboutBusiness", {
                                required: "Enter your business about us",
                              })}
                              cols={30}
                              rows={5}
                              onChange={handleInputChange}
                            />
                            {errors.aboutBusiness && (
                              <p className="text-danger position-absolute textarea">
                                {errors.aboutBusiness.message}
                              </p>
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="modal-footer justify-content-center">
                    <button type="submit" className="btn btn-default w-50">
                      UPDATE BUSINESS
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
