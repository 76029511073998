import React, { useState, useEffect } from "react";
import Loader from "../../layout/Loader";
import { ToastContainer, toast } from "react-toastify";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import "react-phone-number-input/style.css";
import { addSubCategoryData, getMasterCategory } from "../../../service/ecommerce.service";

export default function AddRestCategory() {
  const navigate = useNavigate();
  const token = useSelector((state) => state.status.token.token);
  const restaurantId = useSelector((state) => state?.admin?.admin?.restaurant);

  const [isLoading, setIsLoading] = useState(false);
  const [catImage, setCatImage] = useState(null);
  const [categoryType, setCategoryType] = useState("All Time");
  const [masterCatData, setMasterCatData] = useState(null);
  const [mainCatId, setMainCatId] = useState(null);
  // Fetch subcategory data
  const fetchMainCategory = async () => {
    if (!token || !restaurantId) return;
    setIsLoading(true);
    try {
      const res = await getMasterCategory(token, restaurantId);
      const masterCats = res?.data?.sort((a, b) => {
        return b.master_category_id - a.master_category_id;
      });

      setMasterCatData(masterCats || []);
    } catch (error) {
      toast.error("Failed to fetch data.");
    }
    setIsLoading(false);
  };

  useEffect(() => {
    window.scrollTo(0, 1);
    fetchMainCategory();
  }, []);

  const {
    register,
    setValue,
    setError,
    clearErrors,
    reset,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const addData = (myData) => {
    const sanitizedFileName = catImage.name
      .replace(/ /g, "_")
      .replace(/&/g, "");
    const updatedFile = new File([catImage], sanitizedFileName, {
      type: catImage.type,
    });

    const mainCatId = Number(watch("mainCat")); // Ensure it's a number

    const mainData = masterCatData?.find((item) => item.master_category_id === mainCatId);

    const data = {
      category: myData.categoryName,
      restaurant_id: restaurantId,
      // category_url: process.env.REACT_APP_IMAGE_STORAGE_PATH + updatedFile.name,
      image: updatedFile,
      available_time: myData.customTime ? myData.customTime : categoryType,
      sequence: myData.catSeq,
      category_for: mainData ? mainData.master_category_name : "",
      master_category: myData.mainCat,
    };

    addSubCategoryData(token, data).then((res) => {
      toast.success("Category Added...!", {
        autoClose: 1500,
        style: {
          backgroundColor: "black",
          color: "white",
        },
      });

      reset();
    });
    reset({ uploadImage: true });
    setCatImage(null);
    setTimeout(() => {
      navigate("/rest-category");
    }, 3000);
  };

  const addImage = (event) => {
    const input = event.target;
    if (input.files && input.files[0]) {
      const originalFile = input.files[0];

      const timestamp = Date.now();
      const uniqueFileName = `${timestamp}_${originalFile.name
        .replace(/ /g, "_")
        .replace(/&/g, "")}`;

      const uniqueFile = new File([originalFile], uniqueFileName, {
        type: originalFile.type,
      });
      setCatImage(uniqueFile);

      const reader = new FileReader();
      reader.onload = function (e) {
        document.getElementById("displayedImageAdd").src = e.target.result;

        setError("catImage", { type: "manual", message: "" });
      };

      reader.readAsDataURL(uniqueFile);
    }
  };

  const handleAddImageClick = () => {
    // Trigger the click event on the file input when the displayed image is clicked
    document.getElementById("imageInputAdd").click();
  };

  return (
    <div>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="page-content ms-3 fade-in-up">
          <ToastContainer position="top-center" className="mt-4" limit={1} />

          <div className="">
            <div className="ms-2 me-3">
              <form id="editForm" onSubmit={handleSubmit(addData)}>
                <div className="modal-content business-modal">
                  <div className="modal-header">
                    <h4 className="modal-title fw-bold fs-5">ADD CATEGORY</h4>
                    <button type="button" className="close" onClick={() => window.history.back()}>
                      &times;
                    </button>
                  </div>
                  <div className="modal-body business-data">
                    <table width={"100%"}>
                      <tbody>
                        <tr>
                          <td>
                            <label>Main Category <i className="fa fa-asterisk"></i></label>
                            <select {...register("mainCat", { required: "Select a main category" })} className="form-select"
                            onChange={(e)=>setMainCatId(e.target.value)}>
                              <option value="" disabled>Select main category</option>
                              {
                                masterCatData && masterCatData.map((item, index) => (
                              <option value={item.master_category_id}>{item.master_category_name}</option>
                              ))}
                            </select>
                            {errors.mainCat && <p className="text-danger">{errors.mainCat.message}</p>}
                          </td>
                          <td>
                            <label>Category Name <i className="fa fa-asterisk"></i></label>
                            <input {...register("categoryName", { required: "Enter category name" })} placeholder="Enter category name" />
                            {errors.categoryName && <p className="text-danger">{errors.categoryName.message}</p>}
                          </td>
                        </tr>
                        <tr>

                        <td>
                            <label>Category Sequence <i className="fa fa-asterisk"></i></label>
                            <input {...register("catSeq", { required: "Enter category sequence" })} placeholder="Enter category sequence" />
                            {errors.catSeq && <p className="text-danger">{errors.catSeq.message}</p>}
                          </td>
                          <td>
                            <label>Category Availability Time <i className="fa fa-asterisk"></i></label>
                            <div className="d-flex radio-button mt-1">
                              <div className="form-check me-4">
                                <input className="form-check-input" type="radio" name="categoryAvailTime" value="All Time" checked={categoryType === "All Time"} onChange={() => setCategoryType("All Time")} />
                                <label className="form-check-label">All Time</label>
                              </div>
                              <div className="form-check">
                                <input className="form-check-input" type="radio" name="categoryAvailTime" value="Custom" checked={categoryType === "Custom"} onChange={() => setCategoryType("Custom")} />
                                <label className="form-check-label">Custom Time</label>
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                        <td>
                            <label>
                              Category Image <i className="fa fa-asterisk"></i>
                            </label>

                            {/* Hidden file input */}
                            <input
                              type="file"
                              id="imageInputAdd"
                              accept="image/*"
                              defaultValue={catImage}
                              {...register("catImage", {
                                required: "Please upload category image",
                              })}
                              style={{ display: "none", marginLeft: 0 }}
                              onChange={addImage}
                            />

                            {/* Displayed image */}
                            <div>
                              <img
                                src={
                                  catImage ||
                                  "/assets/img/custom/uploadImage.png"
                                }
                                id="displayedImageAdd"
                                alt="Upload"
                                onClick={() => handleAddImageClick()}
                                style={{ marginLeft: 0 }}
                              />
                            </div>

                            {errors.catImage && (
                              <p className="text-danger position-absolute img-error">
                                {errors.catImage.message}
                              </p>
                            )}
                          </td>

                          {categoryType === "Custom" && (
                            <td>
                              <label>Custom Time <i className="fa fa-asterisk"></i></label>
                              <input type="time" {...register("customTime", { required: "Select time" })} placeholder="Select Time" />
                              {errors.customTime && <p className="text-danger">{errors.customTime.message}</p>}
                            </td>
                          )}
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="modal-footer justify-content-center">
                    <button type="submit" className="btn btn-default w-50">ADD CATEGORY</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
