import axios from "axios";

export const getBusinessDetails = async (token, page) => {
  const url = process.env.REACT_APP_API_URL + `restaurant/?status=ACTIVE&page=${page}`;
  console.log(url);

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `token ${token}`,
    },
  };

  try {
    const response = await axios.get(url, config);
    return response;
  } catch (error) {
    if (
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500
    ) {
      console.error("Unauthorized Access.", error);
    } else {
      console.error(error);
      throw error;
    }
  }
};

export const addBusinessDetails = async(token, data) => {
  const url = process.env.REACT_APP_API_URL + `restaurant/`;

  const formData = new FormData();

  Object.keys(data).forEach((key) => {
    if (key === "image" && Array.isArray(data[key]) && data[key].length > 0) {
      data[key].forEach((file, index) => {
        if (file instanceof File) {
          formData.append(key, file, file.name);
        }
      });
    } else {
      formData.append(key, data[key]);
    }
  });

  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `token ${token}`,
    },
  };

  try {
    const response = await axios.post(url, formData, config);
    return response;
  } catch (error) {
    if (
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500
    ) {
      console.error("Unauthorized Access.", error);
    } else {
      console.error(error);
      throw error;
    }
  }
}

export const updateBusinessDetails = async (token, data) => {
  const url = process.env.REACT_APP_API_URL+`restaurant/${data.restaurant_id}/`;
  const formData = new FormData();

  Object.keys(data).forEach((key) => {
    if (key === "image" && Array.isArray(data[key]) && data[key].length > 0) {
      data[key].forEach((file, index) => {
        if (file instanceof File) {
          formData.append(key, file, file.name);
        }
      });
    } else {
      formData.append(key, data[key]);
    }
  });

  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `token ${token}`,
    },
  };
  try {
    const response = await axios.put(url, formData, config);
    return response;
  } catch (error) {
    if (
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500
    ) {
      console.log("Unauthorized Access.", error);
    } else {
      console.error(error);
      throw error;
    }
  }
};

export const deleteBusinessDetails = async (token, data) => {
  const url = process.env.REACT_APP_API_URL+`restaurant/${data.restaurant_id}/`;
  // const formData = new FormData();

  // Object.keys(data).forEach((key) => {
  //   if (key === "image" && Array.isArray(data[key]) && data[key].length > 0) {
  //     data[key].forEach((file, index) => {
  //       if (file instanceof File) {
  //         formData.append(key, file, file.name);
  //       }
  //     });
  //   } else {
  //     formData.append(key, data[key]);
  //   }
  // });

  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `token ${token}`,
    },
  };
  try {
    const response = await axios.put(url, data, config);
    return response;
  } catch (error) {
    if (
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500
    ) {
      console.log("Unauthorized Access.", error);
    } else {
      console.error(error);
      throw error;
    }
  }
};

export const getBusinessAdminDetails = async (token, page) => {
  const url = process.env.REACT_APP_API_URL + `restaurantadmin/?status=ACTIVE&page=${page}`;

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `token ${token}`,
    },
  };

  try {
    const response = await axios.get(url, config);
    return response;
  } catch (error) {
    if (
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500
    ) {
      console.error("Unauthorized Access.", error);
    } else {
      console.error(error);
      throw error;
    }
  }
};

export const getOnboardingDetails = async (token, page) => {
  const url = process.env.REACT_APP_API_URL + `onboarding/`;

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `token ${token}`,
    },
  };

  try {
    const response = await axios.get(url, config);
    return response;
  } catch (error) {
    if (
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500
    ) {
      console.error("Unauthorized Access.", error);
    } else {
      console.error(error);
      throw error;
    }
  }
};

export const deleteOnboardingDetails = async (token, onboardId) => {
  const url = process.env.REACT_APP_API_URL + `onboarding/${onboardId}/`;

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `token ${token}`,
    },
  };

  try {
    const response = await axios.delete(url, config);
    return response;
  } catch (error) {
    if (
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500
    ) {
      console.log("Unauthorized Access.", error);
    } else {
      console.error(error);
      throw error;
    }
  }
};

export const addBusinessDetailsadmin = async(token,data) => {
  const url = process.env.REACT_APP_API_URL + `restaurantadmin/`;

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `token ${token}`,
      
    },
  };

  try {
    const response = await axios.post(url, data, config);
    return response;
  } catch (error) {
    if (
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500
    ) {
      console.error("Unauthorized Access.", error);
    } else {
      console.error(error);
      throw error;
    }
  }
}

export const searchRestaurants = async (token, name) => {
  const url = process.env.REACT_APP_API_URL + 'graphql/';
  
  // GraphQL query with properly defined variables
  const query = `
    query restaurant($token: String, $restaurantName: String, $first: Int, $skip: Int) {
      restaurantSearch(token: $token, restaurantName: $restaurantName, first: $first, skip: $skip) {
        restaurantId,
        name,
        zip,
        state
      }
    }
  `;
  
  const variables = {
    restaurantName: name,  // Pass the 'name' value to 'restaurantName'
    first: 20,              // Set default value for 'first'
    skip: 0,                // Set default value for 'skip'
    token: '0o6jcui8mfhmp56we69kcmu5rkejtock'            // Pass the token for authorization
  };
  
  const data = {
    query,
    variables
  };
  
  const config = {
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer `+token,  // Pass the token in the Authorization header
    },
  };

  try {
    const response = await axios.post(url, data, config);
    return response.data.data.restaurantSearch || [];  // Ensure you return an empty array if no data
  } catch (error) {
    console.error("Error fetching restaurants:", error);
    throw error;
  }
};

export const updateAdminDetails = async(token, data)=>{

  const url = process.env.REACT_APP_API_URL+`restaurantadmin/${data.id}/`;

  const config = {
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Token ${token}`,
    },
  };
  try {
    const response = await axios.put(url, data, config);
    return response;
  } catch (error) {
    if (
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500
    ) {
      console.log("Unauthorized Access.", error);
    } else {
      console.error(error);
      throw error;
    }
  }
  
}

export const getBusinessName = async (token, id) => {
  const url = process.env.REACT_APP_API_URL + "restaurant/"+id+'/';
  console.log("Request URL:", url);

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`, // Ensure correct token format (Token ${token})
    },
  };

  try {
    const response = await axios.get(url, config); // Use POST instead of GET
    return response;
  } catch (error) {
    // Handle errors and provide useful messages
    if (error.response && error.response.status >= 400 && error.response.status < 500) {
      console.error("Unauthorized Access.", error);
      // toast.error("Unauthorized Access.");
    } else {
      console.error(error);
      throw error;
    }
  }
};