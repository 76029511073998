  import React, { useEffect, useState, useRef } from "react";
  import { ToastContainer, toast } from "react-toastify";
  import "react-toastify/dist/ReactToastify.css";
  import { useSelector } from "react-redux";
  import { Link, useLocation, useNavigate } from "react-router-dom";
  import { useReactToPrint } from "react-to-print";
  import Loader from "../../../layout/Loader";
  import { getPaymentDetails, getProductDetails } from "../../../../service/ecommerce.service";

  export default function RestOrderDetails() {
    let location = useLocation();
    let navigate = useNavigate();
    const token = useSelector((state) => state?.status?.token?.token);
    const restaurantId = useSelector((state) => state?.admin?.admin?.restaurant);
    const currency_symbol = useSelector(
      (state) => state?.restaurant?.restaurant?.currency_symbol
    );
    const [isLoading, setIsLoading] = useState(true); // Add loading state
    const [orderID, setOrderID] = useState();
    const [productDetailsData, setProductDetailsData] = useState();
    const [orderDetails, setOrderDetails] = useState();
    const [orderDetailsData, setOrderDetailsData] = useState();
    const [paymentID, setPaymentId] = useState(null);
    const [paymentDetails, setPaymentDetails] = useState();
    const [confirmOrderDetails, setConfirmOrderDetails] = useState();

    useEffect(() => {
      window.scrollTo(0, 1);
      if (location?.state?.orderDetails) {
        //   setHideAll(location.state.hideAll);
        //   setHidden(location.state.hidden);
        //   setDisabled(location.state.disabled);
        setOrderDetailsData(location?.state?.orderDetails);
        setOrderID(location?.state?.orderDetails?.order?.order_id);
        getProductDetails(
          token,
          location?.state?.orderDetails?.order?.order_id
        ).then((res) => {
          setProductDetailsData(res.data);
          setOrderDetails(res.data.results);
          setOrderDetailsData(location?.state?.orderDetails);
          // setWaybill(location?.state?.orderDetails?.delhivery_obj?.waybill);
          setPaymentId(location?.state?.orderDetails?.payment_id);
          setIsLoading(false);
        });
          const orderID = location?.state?.orderDetails?.order?.order_id;
          orderID &&
            getPaymentDetails(
              token,
              location.state.orderDetails.order.order_id
            ).then((res) => {
              setPaymentDetails(res?.data);
              
              setIsLoading(false);
            });
      }
    }, [location.state.orderDetails, token]);

    const formatDateTime = (createdAt, currency) => {
      const date = new Date(createdAt);
      
      if (currency !== '₹') {
        // Convert to London Time (Europe/London)
        const londonTime = date.toLocaleString('en-GB', {
          timeZone: 'Europe/London',
          hour12: false,
        });
        return londonTime;
      } else {
        // Convert to India Time (Asia/Kolkata)
        const indiaTime = date.toLocaleString('en-IN', {
          timeZone: 'Asia/Kolkata',
          hour12: false,
        });
        return indiaTime;
      }
    };
    // Ref for printable content
    const contentRef = useRef(null); // Create a ref for the printable content

  const handlePrint = () => {
    const printWindow = window.open("", "_blank", "width=600,height=800");
    
    // Get the HTML content of the div you want to print
    const content = contentRef.current.innerHTML;
    
    // Write the content into the new window
    printWindow.document.write(`
      <html>
        <head>
          <title>Print Order</title>
          <style>
            /* Add some basic styling for printing */
            body { font-family: Arial, sans-serif; }
            .invoice { width: 90%; margin: 0 auto; padding: 20px; }
            .invoice h1 { text-align: center; }
            .invoice h3 { text-align: center; }
            .invoice p { font-size: 14px;text-align: center; }
            table { width: 100%; border-collapse: collapse; margin-top: 20px; justify-content: start; }
            table, th, td { border: 1px solid black; }
            th, td { padding: 8px; text-align: left; }
          </style>
        </head>
        <body>
          <div class="invoice">
            ${content}
          </div>
        </body>
      </html>
    `);

    printWindow.document.close(); // Close the document stream
    printWindow.print(); // Call the print dialog
  };

    return (
      <div>
        {isLoading ? (
          // Conditional rendering of loader
          <Loader />
        ) : (
          <div className="page-content ms-3 fade-in-up">
            <ToastContainer position="top-center" className="mt-4" limit={1} />

            <div className="ibox">
              <div className="ibox-body m-3">
                <div className="row">
                  <div className="row justify-content-center">
                    {/* Print Button */}
                    <p className="text-center">
                      <button
                        id="printPageButton"
                        className="button text-center col-2"
                        style={{
                          background: "#ff6f40",
                          color: "white",
                          fontWeight: "600",
                        }}
                        disabled={isLoading}
                        onClick={handlePrint}
                      >
                        <h3>Print</h3>
                      </button>
                    </p>
                    <div ref={contentRef} className="product-calculation col-md-10 address-box2 mb-5 p-10 myPrintDiv">
                      <div id="invoice-POS">
                        {/* Store Info */}
                        <h1 className="text-center">
                          {orderDetailsData?.restaurant?.name}
                        </h1>
                        <p className="text-center">
                          <strong>Address : </strong>{" "}
                          {orderDetailsData?.restaurant?.address}
                        </p>

                        {/* Customer Info */}
                        <div id="top" className="text-center mb-3">
                          <h3 className="text-red-500">Customer Info</h3>
                          <p>
                            <strong>Customer Name:</strong>{" "}
                            {
                              orderDetailsData?.order?.customer?.customer
                                ?.first_name
                            }{" "}
                            {
                              orderDetailsData?.order?.customer?.customer
                                ?.last_name
                            }
                          </p>
                          <p>
                            <strong>Contact No.:</strong>{" "}
                            {orderDetailsData?.order?.customer?.phone_number}
                          </p>
                          <p>
                            <strong>Order Type:</strong>{" "}
                            {orderDetailsData?.shippingmethod?.name}
                          </p>
                        </div>

                        {/* Order Table */}

                        <div className="overflow-x-auto">
                          <table className="w-full border border-gray-300 m-auto">
                            <thead>
                              <tr className="bg-gray-200 text-center">
                                <th className="border border-gray-300 px-4 py-2">
                                  Menu Item
                                </th>
                                <th className="border border-gray-300 px-4 py-2">
                                  Price
                                </th>
                                <th className="border border-gray-300 px-4 py-2">
                                  Quantity
                                </th>
                                <th className="border border-gray-300 px-4 py-2">
                                  Total
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {orderDetails &&
                                orderDetails.map((item, index) => (
                                  <tr
                                    className="border border-gray-300 text-center"
                                    key={index}
                                  >
                                    <td className="border border-gray-300 px-4 py-2 text-left">
                                      {item?.product?.product_name} <br />
                                      <span className="text-gray-500">
                                        <b>Size: </b>
                                        {item?.size?.size}
                                      </span>
                                      {item.extra && (
                                        <small>
                                          Cooking Instruction: {item?.extra}
                                        </small>
                                      )}
                                    </td>
                                    <td className="border border-gray-300 px-4 py-2">
                                      {currency_symbol}
                                      {item?.product_price}
                                    </td>
                                    <td className="border border-gray-300 px-4 py-2">
                                      {item?.quantity}
                                    </td>
                                    <td className="border border-gray-300 px-4 py-2 font-bold">
                                      {currency_symbol}
                                      {(
                                        item?.quantity * item?.product_price
                                      ).toFixed(2)}
                                    </td>
                                  </tr>
                                ))}

                              <tr className="border border-gray-300 text-center bg-gray-50">
                                <td
                                  className="border border-gray-300 px-4 py-2 text-right"
                                  colSpan={3}
                                >
                                  <b>SUB TOTAL : </b>
                                </td>
                                <td className="border border-gray-300 px-4 py-2">
                                  {currency_symbol}{paymentDetails?.subtotal}
                                </td>
                              </tr>
                              <tr className="border border-gray-300 text-center bg-gray-50">
                                <td
                                  className="border border-gray-300 px-4 py-2 text-right"
                                  colSpan={3}
                                >
                                  <b>TAX : </b>
                                </td>
                                <td className="border border-gray-300 px-4 py-2">
                                {currency_symbol}{paymentDetails?.tax}
                                </td>
                              </tr>
                              <tr className="border border-gray-300 text-center bg-gray-50">
                                <td
                                  className="border border-gray-300 px-4 py-2 text-right"
                                  colSpan={3}
                                >
                                  <b>TIP : </b>
                                </td>
                                <td className="border border-gray-300 px-4 py-2">
                                {currency_symbol}{paymentDetails?.tip}
                                </td>
                              </tr>
                              <tr className="border border-gray-300 text-center bg-gray-50">
                                <td
                                  className="border border-gray-300 px-4 py-2 text-right"
                                  colSpan={3}
                                >
                                  <b>SHIPPING FEE : </b>
                                </td>
                                <td className="border border-gray-300 px-4 py-2">
                                {currency_symbol}{paymentDetails?.shipping_fee}
                                </td>
                              </tr>
                              <tr className="border border-gray-300 text-center bg-gray-50">
                                <td
                                  className="border border-gray-300 px-4 py-2 text-right"
                                  colSpan={3}
                                >
                                  <b>DISCOUNT : </b>
                                </td>
                                <td className="border border-gray-300 px-4 py-2">
                                {currency_symbol}{paymentDetails?.discount}
                                </td>
                              </tr>
                              <tr className="border border-gray-300 text-center bg-gray-50">
                                <td
                                  className="border border-gray-300 px-4 py-2 text-right"
                                  colSpan={3}
                                >
                                  <b>ORDER TOTAL : </b>
                                </td>
                                <td className="border border-gray-300 px-4 py-2">
                                {currency_symbol}{paymentDetails?.total}
                                </td>
                              </tr>

                              <tr className="border border-gray-300 text-center bg-gray-50">
                                <td
                                  className="border border-gray-300 px-4 py-2 text-left"
                                  colSpan={2}
                                >
                                  <p>
                                    <b>Payment Status : </b> {orderDetailsData?.payment_method === 'CASH' ? 'CASH ON DELIVERY' : 'ONLINE PAID'}
                                  </p>
                                  <p>
                                    <b>Date : </b> {formatDateTime(paymentDetails?.created_at, currency_symbol)}
                                  </p>
                                  {paymentDetails?.extra ? <p>
                                    <b>Special Instruction : </b> {paymentDetails?.extra}
                                  </p> : <p>
                                    <b>Special Instruction : </b> No Special Instruction
                                  </p> }
                                </td>
                                <td
                                  className="border border-gray-300 px-4 py-2 text-left"
                                  colSpan={2}
                                >
                                  { paymentDetails?.shipping_address_text ? <p>
                                    <b>Shipping Address : </b> {paymentDetails?.shipping_address_text}
                                  </p> : null }
                                  { paymentDetails?.billing_address_text ? <p>
                                    <b>Billing Address : </b> Lorem Ipsume
                                  </p> : null }
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>

                        <p className="text-center font-semibold mt-2">
                          Thank you!
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
