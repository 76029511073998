import React, { useEffect, useState } from "react";
import Loader from "../../layout/Loader";
import { ToastContainer, toast } from "react-toastify";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import ReactPaginate from "react-paginate";
import { useSelector } from "react-redux";
// import update from "immutability-helper";
import { deleteBusinessDetails, getBusinessDetails } from "../../../service/superAdmin.service";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

export default function Business() {
  const navigate = useNavigate();
  const token = useSelector((state) => state?.status?.token?.token);
  const [isLoading, setIsLoading] = useState(false);
  const [businessData, setBusinessData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [isButtonActive, setIsButtonActive] = useState(false);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(30);
  const [deletedItem, setDeletedItem] = useState(null);

  // Fetch business data with pagination
  const fetchBusinessData = async (pageNumber) => {
    setIsLoading(true);
    getBusinessDetails(token, pageNumber).then((res) => {
      const businessData = res?.data?.results?.sort((a, b) => {
        return (b.restaurant_id) - (a.restaurant_id);
      });
      setBusinessData(businessData);
      setCount(res?.data?.count);
      setIsLoading(false);
    });
  };

  // Call the get business details API
  useEffect(() => {
    window.scrollTo(0, 1);
    fetchBusinessData(page);
  }, [page]);

  // Filtered the business details
  const filteredBusiness = businessData?.filter(
    (item) =>
      item.name
        .toLowerCase()
        .includes(searchQuery.replace(/\s+/g, " ").trim().toLowerCase()) ||
      item.email
        .toLowerCase()
        .includes(searchQuery.replace(/\s+/g, " ").trim().toLowerCase()) ||
      item.phone
        .toLowerCase()
        .includes(searchQuery.replace(/\s+/g, " ").trim().toLowerCase()) ||
      item.address
        .toLowerCase()
        .includes(searchQuery.replace(/\s+/g, " ").trim().toLowerCase()) ||
      item.country
        .toLowerCase()
        .includes(searchQuery.replace(/\s+/g, " ").trim().toLowerCase()) ||
      item.created_at
        .toLowerCase()
        .includes(searchQuery.replace(/\s+/g, " ").trim().toLowerCase())
  );

  const startIndex = currentPage * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const paginatedData = filteredBusiness?.slice(startIndex, endIndex);

  // handle Items as per page
  const handleItemsPerPageChange = (e) => {
    setItemsPerPage(parseInt(e.target.value, 10));
    setCurrentPage(0);
    setPage(1); // Reset to first page whenever the items per page is changed
  };

  // set the current state of page
  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  // set the search change value
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  // Function to toggle button's active state
  const toggleButton = () => {
    setIsButtonActive(!isButtonActive);
  };

  // Function to handle editing
  const handleEdit = (item) => {
    navigate("/update-business", {
      state: {
        businessDetails: item,
      },
    });
  };

  // delete form
  const {
    register: register2,
    handleSubmit: handleSubmitDelete,
    watch,
    reset: reset2,
    formState: { errors: errors2 },
  } = useForm();

  // Function to handle editing
  const handleDelete = (item) => {
    reset2({ confirmBusiness: "" });
    setDeletedItem(item);
  };

  // Delete method
  const deleteData = () => {

    const data = {
      restaurant_id: deletedItem.restaurant_id,
      name: deletedItem.name,
      email: deletedItem.email,
      phone: deletedItem.phone,
      address: deletedItem.address,
      currency: deletedItem.currency,
      city: deletedItem.city,
      state: deletedItem.state,
      zip: deletedItem.zip,
      nearby_zip: deletedItem.nearby_zip,
      country: deletedItem.country,
      status: "INACTIVE",
      working_hours: deletedItem.working_hours,
      desc: deletedItem.desc,
      plan: deletedItem.plan
    }
    deleteBusinessDetails(token, data).then((res) => {
      toast.error("Business Deleted...!", {
        autoClose: 1500,
        style: {
          backgroundColor: "black",
          color: "white",
        },
      });
      reset2({ confirmBusiness: "" });
      document.getElementById("closeDeleteModal").click();
      fetchBusinessData(1);
    });
  };

  return (
    <div>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="page-content ms-3 fade-in-up">
          <ToastContainer position="top-center" className="mt-4" limit={1} />

          <div className="ibox">
            <div className="ibox-body m-3">
              <Link to="/add-business">
                <div
                  className={`sticky-button ${isButtonActive ? "active" : ""}`}
                  onClick={() => toggleButton()}
                  // data-toggle="modal"
                  // data-target="#addSubCategoryModal"
                >
                  <i className="fa fa-plus"></i>
                </div>
              </Link>

              <div className="d-flex justify-content-between my-3">
                <div className="fw-light">
                  Show{" "}
                  <select
                    value={itemsPerPage}
                    onChange={handleItemsPerPageChange}
                    className="fw-light"
                  >
                    {/* <option value="5">5</option> */}
                    <option value="10">10</option>
                    {/* <option value="15">15</option> */}
                    <option value="20">20</option>
                    {/* <option value="25">25</option> */}
                    <option value="30">30</option>
                    <option value="40">40</option>
                    <option value="50">50</option>
                    <option value="60">60</option>
                    <option value="70">70</option>
                    <option value="80">80</option>
                    <option value="90">90</option>
                    <option value="100">100</option>
                  </select>{" "}
                  Results
                </div>
                <div className="fw-light">
                  Search:{" "}
                  <input
                    type="text"
                    value={searchQuery}
                    onChange={handleSearchChange}
                    className="btn-outline-secondary"
                  />
                </div>
              </div>

              <DragDropContext>
                <Droppable droppableId="Products">
                  {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      <table
                        className="table table-striped table-bordered table-hover"
                        cellSpacing="0"
                        width="100%"
                      >
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Phone</th>
                            <th>Address</th>
                            <th>Country</th>
                            <th>Created (Date/Time)</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {paginatedData?.map((item, i) => (
                            <Draggable
                              key={item.restaurant_id}
                              draggableId={item.restaurant_id.toString()}
                              index={i}
                            >
                              {(provided) => (
                                <TableRow
                                  provided={provided}
                                  key={i}
                                  index={startIndex + i}
                                  item={item}
                                  handleEdit={handleEdit}
                                  handleDelete={handleDelete}
                                />
                              )}
                            </Draggable>
                          ))}
                        </tbody>
                      </table>
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>

              {count > itemsPerPage && (
                <div className="d-flex justify-content-between my-3">
                  <div className="fw-light">
                    Showing{" "}
                    {filteredBusiness?.length > 0 ? startIndex + 1 : startIndex}{" "}
                    to {Math.min(endIndex, filteredBusiness?.length)} of{" "}
                    {filteredBusiness?.length} entries
                  </div>
                  <div>
                    {filteredBusiness?.length > itemsPerPage && (
                      <ReactPaginate
                        previousLabel={
                          <button
                            type="button"
                            className="btn btn-outline-secondary w-100"
                          >
                            Previous
                          </button>
                        }
                        nextLabel={
                          <button
                            type="button"
                            className="btn btn-outline-secondary w-100"
                          >
                            Next
                          </button>
                        }
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={Math.ceil(
                          filteredBusiness?.length / itemsPerPage
                        )}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={handlePageChange}
                        containerClassName={"pagination"}
                        subContainerClassName={"pages pagination"}
                        activeClassName={
                          "active-class btn btn-outline-secondary w-100 fw-bold"
                        }
                        pageClassName={
                          "page-class btn btn-outline-secondary w-100"
                        }
                      />
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      {/* <!-- Delete Modal --> */}
      <div className="container ">
        <div className="modal fade" id="deleteBusinessModal" role="dialog">
          <div className="modal-dialog">
            {/* <!-- Delete Modal content--> */}
            <form
              id="deleteForm"
              data-form-label="delete"
              onSubmit={handleSubmitDelete(deleteData)}
            >
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title fw-bold">WARNING</h4>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    id="closeDeleteModal"
                  >
                    &times;
                  </button>
                </div>
                <div className="modal-body">
                  <p className="fs-5 fw-bold">
                    Do you want to delete this 'Business'!
                  </p>
                </div>
                <div className="modal-footer">
                  <button type="submit" className="btn btn-default">
                    DELETE
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

const TableRow = ({ provided, item, index, handleEdit, handleDelete }) => (
  <tr
    ref={provided.innerRef}
    {...provided.draggableProps}
    {...provided.dragHandleProps}
    style={{ position: "relative", cursor: "auto" }}
  >
    <td>{index + 1}</td>
    <td>{item.name}</td>
    <td>{item.email}</td>
    <td>{item.phone}</td>
    <td>{item.address}</td>
    <td>
      {item.country === "US" || item.country === "United States"
        ? "United States of America"
        : item.country === "UK" || item.country === "United Kingdom"
        ? "United Kingdom"
        : item.country === "IN" || item.country === "India"
        ? "India"
        : item.country}
    </td>
    <td>
      {new Intl.DateTimeFormat("en-US", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      }).format(new Date(item.created_at))}
    </td>
    <td>
      <button
        type="button"
        className="btn btn-link p-0"
        onClick={() => handleEdit(item)}
      >
        <i className="fa fa-pencil popup me-4 pencil-small"></i>
      </button>
      <button
        type="button"
        className="btn btn-link p-0"
        onClick={() => handleDelete(item)}
        data-toggle="modal"
        data-target="#deleteBusinessModal"
      >
        <i className="fa fa-trash popup me-4 pencil-small"></i>
      </button>
    </td>
  </tr>
);
