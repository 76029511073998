import React, { useState, useEffect, useRef } from "react";
import Loader from "../../layout/Loader";
import { ToastContainer, toast } from "react-toastify";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { addBusinessDetailsadmin, searchRestaurants } from "../../../service/superAdmin.service";
import { useNavigate } from "react-router-dom";
import { debounce } from "lodash";
import "react-phone-number-input/style.css";
import PhoneInput, {
  formatPhoneNumber,
  formatPhoneNumberIntl,
  isPossiblePhoneNumber,
  isValidPhoneNumber,
} from "react-phone-number-input";

export default function AddBusinessAdmin() {
  const navigate = useNavigate();
  const token = useSelector((state) => state.status.token.token);
  const [isLoading, setIsLoading] = useState(false);
  const [restaurantSuggestions, setRestaurantSuggestions] = useState([]);
  const [selectedBusiness, setSelectedBusiness] = useState(null);
  const [phoneValue, setPhoneValue] = useState("");
  const [country, setCountry] = useState("IN");
  const {
    register,
    setValue,
    setError,
    reset,
    handleSubmit: handleSubmitAdd,
    formState: { errors },
  } = useForm();

  const handleBusinessNameChange = debounce(async (event) => {
    const name = event.target.value;

    if (name.length >= 1) {
      try {
        const results = await searchRestaurants(token, name);
        setRestaurantSuggestions(results);
      } catch (error) {

        toast.error("Failed to load suggestions.");
      }
    } else {
      setRestaurantSuggestions([]);
    }
  }, 500);

  const handleSelectBusiness = (business) => {
    setSelectedBusiness(business);
    setValue("businessName", business.name);
    setRestaurantSuggestions([]);
  };

  const addData = (item) => {
    const data = {
      username: item.userName,
      email: item.email,
      phone_number: item.phone,
      restaurant: selectedBusiness ? selectedBusiness.restaurantId : item.businessName,
      status: 'ACTIVE',
    };
    addBusinessDetailsadmin(token, data).then((res) => {
      toast.success("Business Admin Added...!", {
        autoClose: 1500,
        style: {
          backgroundColor: "black",
          color: "white",
        },
      });

      reset();
    });

    setTimeout(() => {
      navigate("/admin");
    }, 3000);
  };

  const handlePhoneChange = (value) => {
    setPhoneValue(value);
    setValue("phone", value);
  };

  const getMaxLengthForCountry = () => {
    switch (country) {
      case "US":
        return 14; // US phone numbers usually have 14 characters (including country code)
      case "IN":
        return 13; // India phone numbers usually have 13 characters (including country code)
      case "UK":
        return 14; // UK phone numbers usually have 14 characters (including country code)
      default:
        return 15;
    }
  };

  return (
    <div>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="page-content ms-3 fade-in-up">
          <ToastContainer position="top-center" className="mt-4" limit={1} />

          <div className="">
            <div className="ms-2 me-3">
              <form
                id="editForm"
                data-form-label="update"
                onSubmit={handleSubmitAdd(addData)}
              >
                <div className="modal-content business-modal">
                  <div className="modal-header">
                    <h4 className="modal-title fw-bold fs-5">ADD BUSINESS ADMIN</h4>
                    <button
                      type="button"
                      className="close"
                      onClick={() => window.history.back()}
                    >
                      &times;
                    </button>
                  </div>
                  <div className="modal-body business-data">
                    <table width={"100%"}>
                      <tbody>
                        <tr>
                          <td>
                            <label>
                              User Name <i className="fa fa-asterisk"></i>
                            </label>
                            <input
                              {...register("userName", { required: "Enter user name" })}
                              placeholder="Enter user name"
                              className="mb-2"
                            />
                            {errors.userName && (
                              <p className="text-danger">{errors.userName.message}</p>
                            )}
                          </td>
                          <td>
                            <label>
                              Business Name <i className="fa fa-asterisk"></i>
                            </label>
                            <input
                              {...register("businessName", { required: "Enter business name" })}
                              placeholder="Enter your business name"
                              className="mb-2"
                              onChange={handleBusinessNameChange}
                            />
                            {errors.businessName && (
                              <p className="text-danger">{errors.businessName.message}</p>
                            )}

                            {/* Dropdown for displaying business suggestions */}
                            {restaurantSuggestions.length > 0 && (
                              <ul className="autocomplete-suggestions">
                                {restaurantSuggestions.map((business) => (
                                  <li
                                    key={business.restaurantId}
                                    onClick={() => handleSelectBusiness(business)}
                                  >
                                    {business.name}
                                  </li>
                                ))}
                              </ul>
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <label>
                              Email <i className="fa fa-asterisk"></i>
                            </label>
                            <input
                              {...register("email", {
                                required: "Enter email address",
                                pattern: {
                                  value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                                  message: "Enter a valid email address",
                                },
                              })}
                              placeholder="Enter your email"
                            />
                            {errors.email && <p className="text-danger">{errors.email.message}</p>}
                          </td>
                          <td>
                            <label>
                              Phone <i className="fa fa-asterisk"></i>
                            </label>
                            {/* <input
                              {...register("phone", {
                                required: "Enter phone number",
                                pattern: {
                                  value: /^[0-9]{10}$/,
                                  message: "Enter a valid 10-digit phone number",
                                },
                              })}
                              placeholder="Enter your phone number"
                              className="mb-2"
                            />
                            {errors.phone && <p className="text-danger">{errors.phone.message}</p>} */}
                            <PhoneInput
                              international
                              country={country}
                              value={phoneValue}
                              onChange={handlePhoneChange}
                              inputClass="business-modal-phone-input"
                              containerClass="business-modal-phone-container"
                              placeholder="Enter phone number"
                              inputProps={{
                                maxLength: getMaxLengthForCountry(),
                              }}
                            />
                            {/* Phone validation error */}
                            {!phoneValue || !isValidPhoneNumber(phoneValue) ? (
                              <p className="text-danger">
                                Invalid phone number
                              </p>
                            ) : null}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="modal-footer justify-content-center">
                    <button type="submit" className="btn btn-default w-50">
                      ADD ADMIN
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}



