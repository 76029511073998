import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  addNewProduct,
  getMasterCategory,
  getSubCategory,
} from "../../../../service/ecommerce.service";
import { useSelector } from "react-redux";
import Loader from "../../../layout/Loader";

export default function AddMenu() {
  let location = useLocation();
  let navigate = useNavigate();
  const token = useSelector((state) => state.status.token.token);
  const restaurantId = useSelector((state) => state.admin.admin.restaurant);
  const currencySymbol = useSelector(
    (state) => state?.restaurant?.restaurant?.currency_symbol
  );

  const [categoryData, setCategoryData] = useState();
  const [uploadImage, setUploadImage] = useState(null);
  const [masterCategory, setMasterCategory] = useState();
  const [isLoading, setIsLoading] = useState(true); // Add loading state
  const [selectedCategory, setSelectedCategory] = useState("");
  const [categoryType, setCategoryType] = useState("All Time");
  const [deliveryType, setDeliveryType] = useState("Both");

  const {
    register,
    setError,
    reset,
    handleSubmit: handleSubmitAdd,
    formState: { errors },
  } = useForm();

  const fetchCategory = () => {
    // setIsLoading(true);
    getSubCategory(token, restaurantId).then((res) => {
      setCategoryData(res.data);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    window.scrollTo(0, 1);
    fetchCategory();
  }, []);

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
    setError("category_name", { type: "manual", message: "" });
  };

  const addData = (item) => {
    const data = {
      restaurant: restaurantId,
      sequence: item.sequence,
      product_name: item.menu_name,
      image: uploadImage,
      price: item.price,
      MRP: item.MRP,
      caption: "NA",
      tax_exempt: item.tax_exempt,
      category: selectedCategory,
      optional: "true",
      in_stock: true,
      extra: item.extra,
      status: "ACTIVE",
      available_time: item.customTime ? item.customTime : categoryType,
      delivery_type: deliveryType,
    };
    addNewProduct(token, data).then((res) => {
      toast.success("Menu Added...!", {
        autoClose: 1500,
        style: {
          backgroundColor: "black",
          color: "white",
        },
      });

      reset();
    });
    reset({ uploadImage: true });
    setUploadImage(null);
    setTimeout(() => {
      navigate("/menu");
    }, 3000);
  };

  const addImage = (event) => {
    const input = event.target;
    if (input.files && input.files[0]) {
      const reader = new FileReader();

      setUploadImage(input.files[0]);

      reader.onload = function (e) {
        // Update the src attribute of the displayed image
        document.getElementById("displayedImageAdd").src = e.target.result;

        setError("uploadImage", { type: "manual", message: "" });
      };

      // Read the chosen file as a data URL
      reader.readAsDataURL(input.files[0]);
    }
  };

  const handleAddImageClick = () => {
    // Trigger the click event on the file input when the displayed image is clicked
    document.getElementById("imageInputAdd").click();
  };

  return (
    <div>
      {isLoading ? (
        // Conditional rendering of loader
        <Loader />
      ) : (
        <div className="page-content ms-3 fade-in-up">
          <ToastContainer position="top-center" className="mt-4" limit={1} />

          <div className="">
            <div className="ms-2 me-3">
              <form
                id="editForm"
                data-form-label="update"
                onSubmit={handleSubmitAdd(addData)}
              >
                <div className="modal-content product-modal">
                  <div className="modal-header">
                    <h4 className="modal-title fw-bold fs-5">ADD MENU</h4>
                    <button
                      type="button"
                      className="close"
                      onClick={() => window.history.back()}
                    >
                      &times;
                    </button>
                  </div>
                  <div className="modal-body product-data">
                    {/* <p>Some text in the modal.</p> */}
                    <table>
                      <tbody>
                        <tr>
                          <td>
                            <label>
                              Sequence <i className="fa fa-asterisk"></i>
                            </label>

                            <input
                              {...register("sequence", {
                                required: "Enter Sequence",
                              })}
                            />
                            {errors.sequence && (
                              <p className="text-danger">
                                {errors.sequence.message}
                              </p>
                            )}
                          </td>
                          <td>
                            <label>
                              Menu Name <i className="fa fa-asterisk"></i>
                            </label>

                            <input
                              {...register("menu_name", {
                                required: "Enter Menu Name",
                              })}
                            />
                            {errors.menu_name && (
                              <p className="text-danger">
                                {errors.menu_name.message}
                              </p>
                            )}
                          </td>
                          <td>
                            <label>
                              Menu Price (MRP) ( {currencySymbol}{" "}
                              <i className="fa fa-asterisk"></i>)
                            </label>

                            <input
                              {...register("MRP", {
                                required: "Enter Menu Price (MRP) ",
                                pattern: {
                                  value: /^\d*\.?\d*$/,
                                  message: "Please Enter Valid MRP",
                                },
                                validate: (value) =>
                                  parseFloat(value) > 0 ||
                                  "Value must be greater than 0",
                              })}
                            />
                            {errors.MRP && (
                              <p className="text-danger position-absolute">
                                {errors.MRP.message}
                              </p>
                            )}
                          </td>
                          <td>
                            <label>
                              Selling Price ( {currencySymbol}{" "}
                              <i className="fa fa-asterisk"></i>)
                            </label>

                            <input
                              {...register("price", {
                                required: "Enter Selling Price",
                                pattern: {
                                  value: /^\d*\.?\d*$/,
                                  message: "Please Enter Valid Price",
                                },
                                validate: (value) =>
                                  parseFloat(value) > 0 ||
                                  "Value must be greater than 0",
                              })}
                            />
                            {errors.price && (
                              <p className="text-danger position-absolute">
                                {errors.price.message}
                              </p>
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <label>
                              Category <i className="fa fa-asterisk"></i>
                            </label>
                            <select
                              id="category"
                              value={selectedCategory}
                              className="form-select"
                              {...register("category_name", {
                                required: "Enter Sub Category",
                              })}
                              onChange={handleCategoryChange}
                            >
                              <option value="" disabled>
                                Select Sub Category
                              </option>
                              {categoryData &&
                                categoryData.map((category) => (
                                  <option
                                    key={category.category_id}
                                    value={category.category_id}
                                  >
                                    {category.category}
                                  </option>
                                ))}
                            </select>

                            {errors.category_name && (
                              <p className="text-danger position-absolute">
                                {errors.category_name.message}
                              </p>
                            )}
                          </td>
                          <td>
                            <label>
                              TAX <i className="fa fa-asterisk"></i>
                            </label>

                            <select
                              className="form-select"
                              defaultValue=""
                              {...register("tax_exempt", {
                                required: "Select TAX",
                              })}
                            >
                              <option disabled value="">
                                Select TAX
                              </option>
                              <option value="true">Yes</option>
                              <option value="false">No</option>
                            </select>
                            {errors.tax_exempt && (
                              <p className="text-danger position-absolute">
                                {errors.tax_exempt.message}
                              </p>
                            )}
                          </td>
                          <td colSpan={2}>
                            <label>
                              Delivery Type <i className="fa fa-asterisk"></i>
                            </label>
                            <div className="d-flex radio-button mt-1">
                              <div className="form-check mb-5 me-4">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="deliveryType"
                                  value="Both"
                                  checked={deliveryType === "Both"}
                                  onChange={() => setDeliveryType("Both")}
                                />
                                <label className="form-check-label">Both</label>
                              </div>
                              <div className="form-check mb-5 me-4">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="deliveryType"
                                  value="Delivery Only"
                                  checked={deliveryType === "Delivery Only"}
                                  onChange={() =>
                                    setDeliveryType("Delivery Only")
                                  }
                                />
                                <label className="form-check-label">
                                  Delivery Only
                                </label>
                              </div>
                              <div className="form-check mb-5">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="deliveryType"
                                  value="Pickup Only"
                                  checked={deliveryType === "Pickup Only"}
                                  onChange={() =>
                                    setDeliveryType("Pickup Only")
                                  }
                                />
                                <label className="form-check-label">
                                  Pickup Only
                                </label>
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={2}>
                            <label>
                              Category Availability Time{" "}
                              <i className="fa fa-asterisk"></i>
                            </label>
                            <div className="d-flex radio-button mt-1 mb-5">
                              <div className="form-check me-4">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="categoryAvailTime"
                                  value="All Time"
                                  checked={categoryType === "All Time"}
                                  onChange={() => setCategoryType("All Time")}
                                />
                                <label className="form-check-label">
                                  All Time
                                </label>
                              </div>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="categoryAvailTime"
                                  value="Custom"
                                  checked={categoryType === "Custom"}
                                  onChange={() => setCategoryType("Custom")}
                                />
                                <label className="form-check-label">
                                  Custom Time
                                </label>
                              </div>
                            </div>
                          </td>
                          {categoryType === "Custom" && (
                            <td>
                              <label>
                                Custom Time <i className="fa fa-asterisk"></i>
                              </label>
                              <input
                                type="time"
                                {...register("customTime", {
                                  required: "Select time",
                                })}
                                placeholder="Select Time"
                              />
                              {errors.customTime && (
                                <p className="text-danger">
                                  {errors.customTime.message}
                                </p>
                              )}
                            </td>
                          )}
                        </tr>
                        <tr>
                          <td>
                            <label>
                              Product Image <i className="fa fa-asterisk"></i>
                            </label>

                            {/* Hidden file input */}
                            <input
                              type="file"
                              id="imageInputAdd"
                              accept="image/*"
                              defaultValue={uploadImage}
                              {...register("uploadImage", {
                                required: "Please Upload Image",
                              })}
                              style={{ display: "none" }}
                              onChange={addImage}
                            />

                            {/* Displayed image */}
                            <div className="ms-3">
                              <img
                                src={
                                  uploadImage ||
                                  "/assets/img/custom/uploadImage.png"
                                }
                                id="displayedImageAdd"
                                alt="Upload"
                                onClick={() => handleAddImageClick()}
                              />
                            </div>

                            {errors.uploadImage && (
                              <p className="text-danger position-absolute img-error">
                                {errors.uploadImage.message}
                              </p>
                            )}
                          </td>
                          <td>
                            <label>
                              Product Description{" "}
                              <i className="fa fa-asterisk"></i>
                            </label>

                            <textarea
                              {...register("extra", {
                                required: "Write down Description",
                              })}
                            />
                            {errors.extra && (
                              <p className="text-danger position-absolute textarea">
                                {errors.extra.message}
                              </p>
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="submit"
                      className="btn btn-default"
                      // data-dismiss="modal"
                      // onClick={updateData}
                    >
                      ADD MENU
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
