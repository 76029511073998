import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getOrdersByStatus, markOrderDelivered } from "../../../../service/ecommerce.service";
import Loader from "../../../layout/Loader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";;

export default function DispatchedOrders() {
  let navigate = useNavigate();
  const [dispatchOrdersData, setDispatchOrdersData] = useState([]);
  const [count, setCount] = useState(null);
  const token = useSelector((state) => state?.status?.token?.token);
  const restaurantId = useSelector((state) => state?.admin?.admin?.restaurant);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true); // Add loading state
  const [isHovered, setIsHovered] = useState(false);
  const [checkedItems, setCheckedItems] = useState({});

  const business = useSelector((state) => state?.admin?.admin?.username);
  const currency_symbol = useSelector(
    (state) => state?.restaurant?.restaurant?.currency_symbol
  );

  const loginSession = () => {
    if (!business) {
      navigate("/");
    }
  };

  const fetchDispatchOrders = () => {
    setIsLoading(true);
    getOrdersByStatus(token, page, restaurantId, "DISPATCHED").then(
      (res) => {
        setDispatchOrdersData(res.data.results);
        setCount(res.data.count);
        setIsLoading(false);
      }
    );
  };

  useEffect(() => {
    window.scrollTo(0, 1);
    fetchDispatchOrders();
    loginSession();
  }, []);

  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 10;

  const startIndex = currentPage * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const handleOrderDetails = (item) => {
    navigate("/rest-order-details", {
      state: {
        orderDetails: item,
        hideAll: false,
        hidden: false,
      },
    });
  };

  const getShippingMethodName = (shippingMethod) => {
    if (!shippingMethod) {
      return "";
    }

    const nameParts = shippingMethod.name.split(" ");
    let shipName = nameParts[0];

    if (shipName === "Restaurant") {
      const newShipName = nameParts[0] + (nameParts[1] || "");
      if (newShipName !== "PickUp") {
        shipName = nameParts[1];
      } else {
        shipName = "Pickup";
      }
    }

    return shipName;
  };

  const handleStatusChange = async(item) => {
    setCheckedItems(prevState => ({
      ...prevState,
      [item.order.order_id]: !prevState[item.order.order_id] // Toggle only the clicked switch
    }));

    try {

      const status  = "Completed";
      const data = {
        status : status
      }
      markOrderDelivered(token, item.payment_id, data).then((res) => {
        if (res.status === 200) {
          setCheckedItems(prevState => ({
            ...prevState,
            [item.order.order_id]: true // Toggle only the clicked switch
            }));
          }            
          toast.success(`Status of Order ${item.order.order_id} is changed to ${status}`, {
            autoClose: 1500,
            style: {
              backgroundColor: "black",
              color: "white",
            },
          });
          setTimeout(() => {
            navigate("/orderlist/completed");
          }, 2500);
      });
    } catch (error) {
      toast.error("Failed to update order status");
    }
  };

  return (
    <div>
      <div className="ibox-body m-3">
        {isLoading ? ( // Conditional rendering of loader
          <Loader />
        ) : (
          <><ToastContainer position="top-center" className="mt-4" limit={1} />
          <table
            className="table table-striped table-bordered table-hover"
            // id="example-table"
            cellSpacing="0"
            width="100%"
          >
            <thead>
              <tr>
                <th>Order No.</th>
                <th>Name</th>
                {/* <th>Branch Name</th> */}
                <th>Date</th>
                <th>Phone</th>
                <th>Amount</th>
                <th>Order Type</th>
                <th>Payment Method</th>
                <th>Status</th>
              </tr>
            </thead>

            <tbody>
              {dispatchOrdersData &&
                dispatchOrdersData.map((item, i) => (
                  <tr key={i} className="tr">
                    <td
                      className="orderid"
                      onClick={() => handleOrderDetails(item)}
                    >
                      {item.order.order_id}
                    </td>
                    <td>
                      {item.order.customer.customer.first_name
                        ? item.order.customer.customer.first_name
                        : "Guest"}{" "}
                      {item.order.customer.customer.last_name}
                    </td>
                    <td>
                      {new Date(item.order.created_at)
                        .toLocaleDateString("en-US", {
                          day: "numeric",
                          month: "short",
                          year: "numeric",
                        })
                        .replace(/(\d+) (\w+), (\d+)/, "$1 $2 $3")}
                    </td>
                    <td className="text-start">
                      {item.order.customer.phone_number}
                    </td>
                    <td>
                      {/* <i className="fa fa-inr"></i> */}
                      {currency_symbol}
                      {(
                        item.order.subtotal - item.order.discount
                      ).toLocaleString("en-IN", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </td>
                    <td>{getShippingMethodName(item.shippingmethod)}</td>
                    <td>{item.payment_method == "CASH" ? "COD" : "Card"}</td>
                    <td
                      className="orderid"
                    >
                      <div className="form-check product-check form-switch d-flex justify-content-center fs-4">
                        <input
                          className="form-check-input product-input"
                          type="checkbox"
                          id={`flexSwitchCheckChecked_${item.order.order_id}`}
                          value={"Completed"}
                          checked={checkedItems[item.order.order_id] ?? true} // Ensure it refers to the individual item's state
                          onChange={() => handleStatusChange(item)}
                          onMouseEnter={() => setIsHovered(true)}
                          onMouseLeave={() => setIsHovered(false)}
                          title={"Change it to Completed"}
                        />
                      </div>
                    </td>
                    {/* // <td>ORDER PLACED</td> */}
                  </tr>
                ))}
            </tbody>
          </table>
          </>
        )}
        {count > itemsPerPage ? (
          <div className="d-flex justify-content-between my-3">
            <div className="fw-light">
              Showing{" "}
              {dispatchOrdersData.length > 0 ? startIndex + 1 : startIndex} to{" "}
              {Math.min(endIndex, count)} of {count} entries
            </div>
            <div>
              <ReactPaginate
                previousLabel={
                  <button
                    type="button"
                    className="btn btn-outline-secondary w-100"
                  >
                    Previous
                  </button>
                }
                nextLabel={
                  <button
                    type="button"
                    className="btn btn-outline-secondary w-100"
                  >
                    Next
                  </button>
                }
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={Math.ceil(count / itemsPerPage)}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={({ selected }) => {
                  setPage(selected + 1);
                  setCurrentPage(selected);
                  setIsLoading(true);
                  getOrdersByStatus(
                    token,
                    selected + 1,
                    restaurantId,
                    "DISPATCHED"
                  ).then((res) => {
                    setDispatchOrdersData(res.data.results);
                    setIsLoading(false);
                  });
                }}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={
                  "active-class btn btn-outline-secondary w-100 fw-bold"
                }
                pageClassName={"page-class btn btn-outline-secondary w-100"}
              />
            </div>
          </div>
        ) : (
          <div></div>
        )}
      </div>
    </div>
  );
}
