import React, { useEffect, useState } from "react";
import Loader from "../../layout/Loader";
import { ToastContainer, toast } from "react-toastify";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import ReactPaginate from "react-paginate";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { getMainCat, getMainCategoryList } from "../../../service/restAdmin.service";

export default function MainCategory() {
  // Fixed to start with an uppercase "M"
  const navigate = useNavigate();
  const token = useSelector((state) => state?.status?.token?.token);
  const restaurantId = useSelector((state) => state?.admin?.admin?.restaurant);
  const [isLoading, setIsLoading] = useState(false);
  const [businessAdminData, setBusinessAdminData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [isButtonActive, setIsButtonActive] = useState(false);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [viewData, setViewData] = useState(null);
  const [viewDataOpenHours, setViewDataOpenHours] = useState(null);

  // Fetch business data with pagination
  const fetchMainCategoryData = async (pageNumber) => {
    setIsLoading(true);
    getMainCategoryList(token, restaurantId).then((res) => {
      // const businessAdminData = res.data
      const businessData = res?.data?.sort((a, b) => {
        return b.master_category_id - a.master_category_id;
      });
      setBusinessAdminData(businessData);
      setCount(res?.data?.length);
      setIsLoading(false);
    });
  };

  // Call the get business details API
  useEffect(() => {
    window.scrollTo(0, 1);
    fetchMainCategoryData(page);
  }, [page]);

  // Filter the business details
  const filteredMainCategory = businessAdminData.filter((item) =>
    item.master_category_name
      .toLowerCase()
      .includes(searchQuery.replace(/\s+/g, " ").trim().toLowerCase())
  );

  const startIndex = currentPage * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const paginatedData = filteredMainCategory.slice(startIndex, endIndex);

  const handleItemsPerPageChange = (e) => {
    setItemsPerPage(parseInt(e.target.value, 10));
    setCurrentPage(0);
    setPage(1);
  };

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const toggleButton = () => {
    setIsButtonActive(!isButtonActive);
  };

  const handleEdit = (item) => {
    navigate("/Update-main-category", {
      state: {
        businessAdminDetails: item,
      },
    });
  };

  const {
    register: register2,
    watch,
    reset: reset2,
    formState: { errors: errors2 },
  } = useForm();

  const handleView = async(item) => {
    setViewData(item);

    try {
      setIsLoading(true);
      const res = await getMainCat(item.master_category_id, token);

      if(res) {
        setViewDataOpenHours(res.Master_Category_Open_hours);
      }
    } catch(error) {
      toast.error("Failed to fetch open hours")
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="page-content ms-3 fade-in-up">
          <ToastContainer position="top-center" className="mt-4" limit={1} />

          <div className="ibox">
            <div className="ibox-body m-3">
              <Link to="/Addmain-category">
                <div
                  className={`sticky-button ${isButtonActive ? "active" : ""}`}
                  onClick={() => toggleButton()}
                >
                  <i className="fa fa-plus"></i>
                </div>
              </Link>

              <div className="d-flex justify-content-between my-3">
                <div className="fw-light">
                  Show{" "}
                  <select
                    value={itemsPerPage}
                    onChange={handleItemsPerPageChange}
                    className="fw-light"
                  >
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="30">30</option>
                    <option value="40">40</option>
                    <option value="50">50</option>
                    <option value="60">60</option>
                    <option value="70">70</option>
                    <option value="80">80</option>
                    <option value="90">90</option>
                    <option value="100">100</option>
                  </select>{" "}
                  Results
                </div>
                <div className="fw-light">
                  Search:{" "}
                  <input
                    type="text"
                    value={searchQuery}
                    onChange={handleSearchChange}
                    className="btn-outline-secondary"
                  />
                </div>
              </div>

              <DragDropContext>
                <Droppable droppableId="Products">
                  {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      <table
                        className="table table-striped table-bordered table-hover"
                        cellSpacing="0"
                        width="100%"
                      >
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Main Category</th>
                            <th>View Opening Hours</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {paginatedData.map((item, i) => (
                            <Draggable
                              key={item.id}
                              draggableId={item.master_category_id.toString()}
                              index={i}
                            >
                              {(provided) => (
                                <TableRow
                                  provided={provided}
                                  key={i}
                                  index={startIndex + i}
                                  item={item}
                                  handleEdit={handleEdit}
                                  handleView={handleView}
                                />
                              )}
                            </Draggable>
                          ))}
                        </tbody>
                      </table>
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>

              {count > itemsPerPage && (
                <div className="d-flex justify-content-between my-3">
                  <div className="fw-light">
                    Showing{" "}
                    {filteredMainCategory.length > 0
                      ? startIndex + 1
                      : startIndex}{" "}
                    to {Math.min(endIndex, filteredMainCategory.length)} of{" "}
                    {filteredMainCategory.length} entries
                  </div>
                  <div>
                    {filteredMainCategory.length > itemsPerPage && (
                      <ReactPaginate
                        previousLabel={
                          <button
                            type="button"
                            className="btn btn-outline-secondary w-100"
                          >
                            Previous
                          </button>
                        }
                        nextLabel={
                          <button
                            type="button"
                            className="btn btn-outline-secondary w-100"
                          >
                            Next
                          </button>
                        }
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={Math.ceil(
                          filteredMainCategory.length / itemsPerPage
                        )}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={handlePageChange}
                        containerClassName={"pagination"}
                        subContainerClassName={"pages pagination"}
                        activeClassName={
                          "active-class btn btn-outline-secondary w-100 fw-bold"
                        }
                        pageClassName={
                          "page-class btn btn-outline-secondary w-100"
                        }
                      />
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      {/* <!-- Edit Modal --> */}

      <div className="container ">
        <div className="modal fade" id="viewMainCategoryModal" role="dialog">
          <div className={`modal-dialog modal-lg`} id="categoryModal">
            {/* <!-- Edit Modal content--> */}
            <form
              id="editForm"
              data-form-label="update"
              // onSubmit={handleSubmitUpdate(updateData)}
            >
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title fw-bold">
                    View Open Hours - {viewData?.master_category_name}
                  </h4>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    id="closeUpdateModal"
                  >
                    &times;
                  </button>
                </div>
                <div className="modal-body">
                  {/* <p>Some text in the modal.</p> */}
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th className="text-center">Day</th>
                        <th className="text-center">Start Time</th>
                        <th className="text-center">End Time</th>
                        <th className="text-center">Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      { viewDataOpenHours && viewDataOpenHours.map((item, index) => (
                        <tr key={item?.Hours_id}>
                        <td>{item?.day}</td>
                        <td>{item?.start_time}</td>
                        <td>{item?.end_time}</td>
                        <td>{item?.status === true ? "TRUE" : "FALSE"}</td>
                      </tr>))}
                    </tbody>
                  </table>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

const TableRow = ({ provided, item, index, handleEdit, handleView }) => (
  <tr
    ref={provided.innerRef}
    {...provided.draggableProps}
    {...provided.dragHandleProps}
    style={{ position: "relative", cursor: "auto" }}
  >
    <td>{index + 1}</td>
    <td>{item.master_category_name}</td>
    <td>
      <button
        type="button"
        className="btn btn-link p-0"
        onClick={() => handleView(item)}
        data-toggle="modal"
        data-target="#viewMainCategoryModal"
      >
        <i className="fa fa-eye popup me-4 pencil-small"></i>
      </button>
    </td>
    <td>
      <button
        type="button"
        className="btn btn-link p-0"
        onClick={() => handleEdit(item)}
      >
        <i className="fa fa-pencil popup me-4 pencil-small"></i>
      </button>
    </td>
  </tr>
);
