import React, { useEffect, useState } from "react";
import Loader from "../../layout/Loader";
import { ToastContainer, toast } from "react-toastify";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import ReactPaginate from "react-paginate";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  addWorkingHours,
  deleteWorkingHoursData,
  getWorkingHours,
  updateWorkingHours,
} from "../../../service/restAdmin.service";
import { useForm } from "react-hook-form";

export default function WorkingHours() {
  const navigate = useNavigate();
  const token = useSelector((state) => state?.status?.token?.token);
  const restaurantId = useSelector((state) => state?.admin?.admin?.restaurant);

  const [isLoading, setIsLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [workingHoursData, setWorkingHoursData] = useState(null);
  const [count, setCount] = useState();
  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [isButtonActive, setIsButtonActive] = useState(false);
  const [deletedItem, setDeletedItem] = useState(null);
  const [editedItem, setEditedItem] = useState();
  const [updateDayOfWeek, setDayOfWeek] = useState(null);
  const [updateStartTime, setStartTime] = useState(null);
  const [updateEndTime, setEndTime] = useState(null);
  const [updateStartDate, setStartDate] = useState(null);
  const [updateEndDate, setEndDate] = useState(null);

  const fetchWorkingHours = () => {
    setIsLoading(true);
    getWorkingHours(token, restaurantId, page).then((res) => {
      setWorkingHoursData(res?.data?.results);
      setCount(res?.data?.count);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    window.scrollTo(0, 1);
    fetchWorkingHours();
  }, []);

  // Filter categories based on search query with safe checks
  const filteredWorkingHours =
    workingHoursData?.filter((item) => {
      const start_time = item?.start_time?.toLowerCase() || "";
      const end_time = item?.end_time?.toLowerCase() || "";
      const dayofweek = item?.dayofweek?.toLowerCase() || "";
      const query = searchQuery.trim().toLowerCase();

      return (
        start_time.includes(query) ||
        end_time.includes(query) ||
        dayofweek.includes(query)
      );
    }) || [];

  // Pagination logic
  const startIndex = currentPage * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const paginatedData = filteredWorkingHours?.slice(startIndex, endIndex);

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  const toggleButton = () => {
    reset({
      dayofweek: "",
      start_time: "",
      end_time: "",
      start_date: "",
      end_date: "",
    });
    setIsButtonActive(!isButtonActive);
  };

  const {
    register,
    handleSubmit: handleSubmitAdd,
    watch,
    reset,
    formState: { errors },
  } = useForm({ mode: "onChange" }); // Enables real-time validation

  const selectedDay = watch("dayofweek");
  const startDate = watch("start_date");
  const endDate = watch("end_date");
  const startTime = watch("start_time");
  const endTime = watch("end_time");

  const onSubmit = (data) => {
    console.log("Form Data:", data);

    let st, et;

    if (data.dayofweek === "Holiday") {
      console.log("iff")
      // Holiday Logic
      st = `${data.start_date} 00:00:01`;
      et =
        new Date(
          new Date(data.end_date).setDate(new Date(data.end_date).getDate())
        )
          .toISOString()
          .split("T")[0] + " 23:59:59";
    } else {
      // Normal Days Logic
      st = `2020-01-01 ${data.start_time}:00`;
      et = `2020-01-01 ${data.end_time}:00`;
    }

    // API Request Data
    const payload = {
      start_time: st,
      end_time: et,
      dayofweek: data.dayofweek,
      restaurant: restaurantId,
      display: "true",
      time_zone: "PST",
    };

    addWorkingHours(token, payload).then((res) => {
      toast.success("Working Hour Added...!", {
        autoClose: 1500,
        style: {
          backgroundColor: "black",
          color: "white",
        },
      });
      setTimeout(() => {
        document.getElementById("closeAddModal").click();
        fetchWorkingHours();
        reset();
      }, [1500]);
    });
  };

  const handleDelete = (item) => {
    setDeletedItem(item);
  };

  // delete form
  const {
    register: register2,
    handleSubmit: handleSubmitDelete,
    reset: reset2,
    formState: { errors: errors2 },
  } = useForm();

  const deleteData = () => {
    console.log();
    deleteWorkingHoursData(token, deletedItem.id).then((res) => {
      toast.error("Addon Deleted...!", {
        autoClose: 1500,
        style: {
          backgroundColor: "black",
          color: "white",
        },
      });
      setTimeout(() => {
        document.getElementById("closeDeleteModal").click();
        fetchWorkingHours();
      }, [1500]);
    });
  };

  const {
    register: register1,
    handleSubmit: handleSubmitUpdate,
    formState: { errors1 },
    clearErrors,
    setValue, // Add setValue to set field values dynamically
  } = useForm();

  // Function to handle editing
  const handleEdit = (item) => {
    setEditedItem(item);
    setDayOfWeek(item.dayofweek);
    // Ensure date format is "YYYY-MM-DD"
    setStartDate(item.start_time ? item.start_time.split("T")[0] : "");
    setEndDate(item.end_time ? item.end_time.split("T")[0] : "");
  
    // Ensure time format is "HH:MM"
    setStartTime(item.start_time ? item.start_time.split("T")[1].slice(0, 5) : "");
    setEndTime(item.end_time ? item.end_time.split("T")[1].slice(0, 5) : "");
  
    // Set default values for controlled form fields
    setValue("dayofweek", item.dayofweek);
    setValue("start_date", item.start_time ? item.start_time.split("T")[0] : "");
    setValue("end_date", item.end_time ? item.end_time.split("T")[0] : "");
    setValue("start_time", item.start_time ? item.start_time.split("T")[1].slice(0, 5) : "");
    setValue("end_time", item.end_time ? item.end_time.split("T")[1].slice(0, 5) : "");
  
    clearErrors(); // Clear errors on edit
  };

  const updateData = (item) => {
    let st, et;

    if (updateDayOfWeek === "Holiday") {
      console.log("iff")
      // Holiday Logic
      st = `${updateStartDate} 00:00:01`;
      et =
        new Date(
          new Date(updateEndDate).setDate(new Date(updateEndDate).getDate())
        )
          .toISOString()
          .split("T")[0] + " 23:59:59";
    } else {
      // Normal Days Logic
      st = `2020-01-01 ${updateStartTime}:00`;
      et = `2020-01-01 ${updateEndTime}:00`;
    }

    // API Request Data
    const payload = {
      start_time: st,
      end_time: et,
      dayofweek: updateDayOfWeek,
      restaurant: restaurantId,
      display: "true",
      time_zone: "PST",
    };
    updateWorkingHours(token, payload, editedItem.addon_id).then(
      (res) => {
        toast.success("Working Hour Updated...!", {
          autoClose: 1500,
          style: {
            backgroundColor: "black",
            color: "white",
          },
        });

        setTimeout(() => {
          document.getElementById("closeUpdateModal").click();
          fetchWorkingHours();
        }, [1500]);
      }
    );
  };

  return (
    <div>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="page-content ms-3 fade-in-up">
          <ToastContainer position="top-center" className="mt-4" limit={1} />
          <div className="ibox">
            <div className="ibox-body m-3">
              <div
                className={`sticky-button ${isButtonActive ? "active" : ""}`}
                onClick={() => toggleButton()}
                data-toggle="modal"
                data-target="#addWorkingHoursModal"
              >
                <i className="fa fa-plus"></i>
              </div>
              <div className="d-flex justify-content-between my-3">
                <div>
                  Show{" "}
                  <select
                    value={itemsPerPage}
                    onChange={(e) =>
                      setItemsPerPage(parseInt(e.target.value, 10))
                    }
                  >
                    {[10, 20, 30, 40, 50, 100].map((num) => (
                      <option key={num} value={num}>
                        {num}
                      </option>
                    ))}
                  </select>{" "}
                  Results
                </div>
                <div className="floating-notification fw-light">
                  NOTE - Time display in Greenwich Mean Time (GMT) Timezone
                </div>
                <div>
                  Search:{" "}
                  <input
                    type="text"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    className="btn-outline-secondary"
                  />
                </div>
              </div>

              <DragDropContext
              //  onDragEnd={handleDragEnd}
              >
                <Droppable droppableId="categories">
                  {(provided) => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                      <table className="table table-striped table-bordered table-hover">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Start Date/Time</th>
                            <th>End Date/Time</th>
                            <th>Weeks</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {paginatedData?.map((item, index) => (
                            <Draggable
                              key={item.id || `draggable-${index}`}
                              draggableId={
                                item.id
                                  ? item.id.toString()
                                  : `draggable-${index}`
                              }
                              index={index}
                            >
                              {(provided) => (
                                <TableRow
                                  provided={provided}
                                  index={startIndex + index}
                                  item={item}
                                  handleEdit={handleEdit}
                                  handleDelete={handleDelete}
                                />
                              )}
                            </Draggable>
                          ))}
                        </tbody>
                      </table>
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
              {count > itemsPerPage && (
                <div className="d-flex justify-content-between my-3">
                  <div className="fw-light">
                    Showing{" "}
                    {filteredWorkingHours.length > 0
                      ? startIndex + 1
                      : startIndex}{" "}
                    to {Math.min(endIndex, filteredWorkingHours.length)} of{" "}
                    {filteredWorkingHours.length} entries
                  </div>
                  <div>
                    {filteredWorkingHours.length > itemsPerPage && (
                      <ReactPaginate
                        previousLabel={
                          <button
                            type="button"
                            className="btn btn-outline-secondary w-100"
                          >
                            Previous
                          </button>
                        }
                        nextLabel={
                          <button
                            type="button"
                            className="btn btn-outline-secondary w-100"
                          >
                            Next
                          </button>
                        }
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={Math.ceil(
                          filteredWorkingHours.length / itemsPerPage
                        )}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={handlePageChange}
                        containerClassName={"pagination"}
                        subContainerClassName={"pages pagination"}
                        activeClassName={
                          "active-class btn btn-outline-secondary w-100 fw-bold"
                        }
                        pageClassName={
                          "page-class btn btn-outline-secondary w-100"
                        }
                      />
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      {/* <!-- Add Modal --> */}
      <div className="container ">
        <div className="modal fade" id="addWorkingHoursModal" role="dialog">
          <div className="modal-dialog">
            {/* <!-- Add Modal content--> */}
            <form
              id="addForm"
              data-form-label="add"
              onSubmit={handleSubmitAdd(onSubmit)}
            >
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title fw-bold">ADD WORKING HOURS</h4>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    id="closeAddModal"
                  >
                    &times;
                  </button>
                </div>
                <div className="modal-body">
                  {/* Day of Week Dropdown */}
                  <div className="mb-3">
                    <label className="form-label">
                      Day Of Week <i className="fa fa-asterisk text-danger"></i>
                    </label>
                    <select
                      className="form-select"
                      {...register("dayofweek", {
                        required: "Please select a day",
                      })}
                    >
                      <option value="">Select Day of Week</option>
                      <option value="Holiday">Holiday</option>
                      <option value="Monday">Monday</option>
                      <option value="Tuesday">Tuesday</option>
                      <option value="Wednesday">Wednesday</option>
                      <option value="Thursday">Thursday</option>
                      <option value="Friday">Friday</option>
                      <option value="Saturday">Saturday</option>
                      <option value="Sunday">Sunday</option>
                    </select>
                    {errors.dayofweek && (
                      <p className="text-danger">{errors.dayofweek.message}</p>
                    )}
                  </div>

                  {/* Conditionally Show Date or Time Pickers */}
                  {selectedDay === "Holiday" ? (
                    <>
                      <div className="mb-3">
                        <label className="form-label">Start Date</label>
                        <input
                          type="date"
                          className="form-control"
                          {...register("start_date", {
                            required: "Start Date is required",
                          })}
                        />
                        {errors.start_date && (
                          <p className="text-danger">
                            {errors.start_date.message}
                          </p>
                        )}
                      </div>

                      <div className="mb-3">
                        <label className="form-label">End Date</label>
                        <input
                          type="date"
                          className="form-control"
                          min={startDate || ""}
                          {...register("end_date", {
                            required: "End Date is required",
                            validate: (value) =>
                              !startDate ||
                              value >= startDate ||
                              "End Date must be after Start Date",
                          })}
                        />
                        {errors.end_date && (
                          <p className="text-danger">
                            {errors.end_date.message}
                          </p>
                        )}
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="mb-3">
                        <label className="form-label">Start Time</label>
                        <input
                          type="time"
                          className="form-control"
                          {...register("start_time", {
                            required: "Start Time is required",
                          })}
                        />
                        {errors.start_time && (
                          <p className="text-danger">
                            {errors.start_time.message}
                          </p>
                        )}
                      </div>

                      <div className="mb-3">
                        <label className="form-label">End Time</label>
                        <input
                          type="time"
                          className="form-control"
                          min={startTime || ""}
                          {...register("end_time", {
                            required: "End Time is required",
                            validate: (value) =>
                              !startTime ||
                              value > startTime ||
                              "End Time must be after Start Time",
                          })}
                        />
                        {errors.end_time && (
                          <p className="text-danger">
                            {errors.end_time.message}
                          </p>
                        )}
                      </div>
                    </>
                  )}
                </div>
                <div className="modal-footer">
                  <button type="submit" className="btn btn-default">
                    ADD
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      {/* <!-- Edit Modal --> */}

      <div className="container ">
        <div className="modal fade" id="editWorkingHourModal" role="dialog">
          <div className={`modal-dialog`} id="workingHourModal">
            {/* <!-- Edit Modal content--> */}
            <form
              id="editForm"
              data-form-label="update"
              onSubmit={handleSubmitUpdate(updateData)}
            >
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title fw-bold">UPDATE WORKING HOURS</h4>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    id="closeUpdateModal"
                  >
                    &times;
                  </button>
                </div>
                <div className="modal-body">
                  {/* <p>Some text in the modal.</p> */}
                  {editedItem && (
                    <div>
                      {/* Day of Week Dropdown */}
                      <div className="mb-3">
                        <label className="form-label">
                          Day Of Week{" "}
                          <i className="fa fa-asterisk text-danger"></i>
                        </label>
                        <select
                            value={updateDayOfWeek} // Ensure this matches "HOLIDAY"
                            className="form-select"
                            {...register1("dayofweek1", {
                              required: "Please select a day",
                            })}
                            onChange={(e) => setDayOfWeek(e.target.value)}
                          >
                            <option value="">Select Day of Week</option>
                            <option value="Holiday">Holiday</option>
                            <option value="Monday">Monday</option>
                            <option value="Tuesday">Tuesday</option>
                            <option value="Wednesday">Wednesday</option>
                            <option value="Thursday">Thursday</option>
                            <option value="Friday">Friday</option>
                            <option value="Saturday">Saturday</option>
                            <option value="Sunday">Sunday</option>
                          </select>
                        {/* {errors1.dayofweek1 && (
                          <p className="text-danger">
                            {errors1.dayofweek1.message}
                          </p>
                        )} */}
                      </div>

                      {/* Conditionally Show Date or Time Pickers */}
                      {updateDayOfWeek === "Holiday" ? (
                        <>
                          <div className="mb-3">
                            <label className="form-label">Start Date</label>
                            <input
                              value={updateStartDate}
                              type="date"
                              className="form-control"
                              {...register1("start_date1", {
                                required: "Start Date is required",
                              })}
                              onChange={(e)=>setStartDate(e.target.value)}
                            />
                            {/* {errors1.start_date && (
                              <p className="text-danger">
                                {errors1.start_date.message}
                              </p>
                            )} */}
                          </div>

                          <div className="mb-3">
                            <label className="form-label">End Date</label>
                            <input
                              value={updateEndDate}
                              type="date"
                              className="form-control"
                              min={startDate || ""}
                              {...register1("end_date1", {
                                required: "End Date is required",
                                validate: (value) =>
                                  !startDate ||
                                  value >= startDate ||
                                  "End Date must be after Start Date",
                              })}
                              onChange={(e) => setEndDate(e.target.value)}
                            />
                            {/* {errors1.end_date && (
                              <p className="text-danger">
                                {errors1.end_date.message}
                              </p>
                            )} */}
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="mb-3">
                            <label className="form-label">Start Time</label>
                            <input
                              value={updateStartTime}
                              type="time"
                              className="form-control"
                              {...register1("start_time1", {
                                required: "Start Time is required",
                              })}
                              onChange={(e) => setStartTime(e.target.value)}
                            />
                            {/* {errors1.start_time && (
                              <p className="text-danger">
                                {errors1.start_time.message}
                              </p>
                            )} */}
                          </div>

                          <div className="mb-3">
                            <label className="form-label">End Time</label>
                            <input
                            value={updateEndTime}
                              type="time"
                              className="form-control"
                              min={startTime || ""}
                              {...register1("end_time1", {
                                required: "End Time is required",
                                validate: (value) =>
                                  !startTime ||
                                  value > startTime ||
                                  "End Time must be after Start Time",
                              })}
                              onChange={(e) => setEndTime(e.target.value)}
                            />
                            {/* {errors1.end_time && (
                              <p className="text-danger">
                                {errors1.end_time.message}
                              </p>
                            )} */}
                          </div>
                        </>
                      )}
                    </div>
                  )}
                </div>
                <div className="modal-footer">
                  <button
                    type="submit"
                    className="btn btn-default"
                    // data-dismiss="modal"
                    // onClick={updateData}
                  >
                    UPDATE
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      {/* <!-- Delete Modal --> */}
      <div className="container ">
        <div className="modal fade" id="deleteWorkingHourModal" role="dialog">
          <div className="modal-dialog">
            {/* <!-- Delete Modal content--> */}
            <form
              id="deleteForm"
              data-form-label="delete"
              onSubmit={handleSubmitDelete(deleteData)}
            >
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title fw-bold">WARNING</h4>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    id="closeDeleteModal"
                  >
                    &times;
                  </button>
                </div>
                <div className="modal-body">
                  <p className="fs-5 fw-bold">
                    Do you want to delete this 'Working Hour'!
                  </p>
                </div>
                <div className="modal-footer">
                  <button type="submit" className="btn btn-default">
                    DELETE
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

// TableRow Component
const TableRow = ({ provided, item, index, handleEdit, handleDelete }) => (
  <tr
    ref={provided.innerRef}
    {...provided.draggableProps}
    {...provided.dragHandleProps}
  >
    <td>{index + 1}</td>
    <td>
      {item.dayofweek === "Holiday"
        ? item.start_time.split("T")[0]
        : item.start_time.split("T")[1] || "N/A"}
    </td>
    <td>
      {item.dayofweek === "Holiday"
        ? item.end_time.split("T")[0]
        : item.end_time.split("T")[1] || "N/A"}
    </td>
    <td>{(item.dayofweek).toUpperCase() || "N/A"}</td>
    <td>
      <button
        type="button"
        className="btn btn-link p-0"
        onClick={() => handleEdit(item)}
        data-toggle="modal"
        data-target="#editWorkingHourModal"
      >
        <i className="fa fa-pencil popup me-4 pencil-small"></i>
      </button>
      <button
        type="button"
        className="btn btn-link p-0"
        onClick={() => handleDelete(item)}
        data-toggle="modal"
        data-target="#deleteWorkingHourModal"
      >
        <i className="fa fa-trash popup me-4 trash-small"></i>
      </button>
    </td>
  </tr>
);
