import React, { useState, useEffect } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
import Loader from "../../layout/Loader";
import { getOrdersByStatus } from "../../../service/ecommerce.service";
import { useSelector } from "react-redux";

export default function Orderlist() {
  const token = useSelector((state) => state.status.token.token);
  const restaurantId = useSelector((state) => state.admin.admin.restaurant);
  const [isLoading, setIsLoading] = useState(true); // Add loading state

  const [placedCount, setPlacedCount] = useState(null);
  const [fullFillCount, setFullFillCount] = useState(null);
  const [pickupCount, setPickupCount] = useState(null);
  const [dispatchCount, setDispatchCount] = useState(null);
  const [compCount, setCompCount] = useState(null);

  const location = useLocation();
  const [activeTab, setActiveTab] = useState("fullfill");
  const page = 1;

  const fetchOrders = () => {
    getOrdersByStatus(token, page, restaurantId, "Order_Placed").then((res) => {
      setPlacedCount(res.data.count);
      setIsLoading(false);
    });
    getOrdersByStatus(token, page, restaurantId, "READY_TO_FULFILL").then(
      (res) => {
        setFullFillCount(res.data.count);
        setIsLoading(false);
      }
    );
    getOrdersByStatus(token, page, restaurantId, "READY_TO_PICK").then(
      (res) => {
        setPickupCount(res.data.count);
        setIsLoading(false);
      }
    );
    getOrdersByStatus(token, page, restaurantId, "DISPATCHED").then((res) => {
      setDispatchCount(res.data.count);
      setIsLoading(false);
    });
    getOrdersByStatus(token, page, restaurantId, "Completed").then((res) => {
      setCompCount(res.data.count);
      setIsLoading(false);
    });
  };

  // useEffect(() => {

  //   // Set the active tab based on the current location pathname
  //   const path = location.pathname;
  //   setActiveTab(getTabFromPath(path));
  //   fetchOrders();
  //   // eslint-disable-next-line

  // }, [location.pathname]);

  useEffect(() => {
    window.scrollTo(0, 1);
    // Set the active tab based on the current location pathname
    const path = location.pathname;
    setActiveTab(getTabFromPath(path));

    // Fetch orders
    fetchOrders();

    // eslint-disable-next-line
  }, [location.pathname]);

  const getTabFromPath = (path) => {
    if (path.includes("/orderlist/placed")) return "placed";
    if (path.includes("/orderlist/fullfill")) return "fullfill";
    if (path.includes("/orderlist/pickup")) return "pickup";
    if (path.includes("/orderlist/dispatched")) return "dispatched";
    if (path.includes("/orderlist/completed")) return "completed";
    return "";
  };

  // useEffect(() => {
  // }, []);

  return (
    <div>
      {isLoading ? ( // Conditional rendering of loader
        <Loader />
      ) : (
        <div>
          {/* <!-- START PAGE CONTENT--> */}

          <div className="page-content ms-3 fade-in-up">
            <div className="ibox">
              <div className="ibox-head">
                <Link
                  to="/orderlist/placed"
                  // onClick={() => setActiveTab("placed")}
                >
                  <div
                    className={`ibox-title me-5 ${
                      activeTab === "placed" ? "active" : ""
                    }`}
                  >
                    ORDER PLACED
                    <span className="translate-middle badge rounded-pill badge-notification">
                      {placedCount > 99 ? "99+" : placedCount}
                      <span className="visually-hidden">unread messages</span>
                    </span>
                  </div>
                </Link>
                <Link
                  to="/orderlist/fullfill"
                  // onClick={() => setActiveTab("fullfill")}
                >
                  <div
                    className={`ibox-title me-5 ${
                      activeTab === "fullfill" ? "active" : ""
                    }`}
                  >
                    READY TO FULL FILL
                    <span className="translate-middle badge rounded-pill badge-notification">
                      {fullFillCount > 99 ? "99+" : fullFillCount}
                      <span className="visually-hidden">unread messages</span>
                    </span>
                  </div>
                </Link>
                <Link
                  to="/orderlist/pickup"
                  // onClick={() => setActiveTab("pickup")}
                >
                  <div
                    className={`ibox-title me-5 ${
                      activeTab === "pickup" ? "active" : ""
                    }`}
                  >
                    READY FOR PICKUP
                    <span className="translate-middle badge rounded-pill badge-notification">
                      {pickupCount > 99 ? "99+" : pickupCount}
                      <span className="visually-hidden">unread messages</span>
                    </span>
                  </div>
                </Link>
                <Link
                  to="/orderlist/dispatched"
                  // onClick={() => setActiveTab("dispatched")}
                >
                  <div
                    className={`ibox-title me-5 ${
                      activeTab === "dispatched" ? "active" : ""
                    }`}
                  >
                    DISPATCHED
                    <span className="translate-middle badge rounded-pill badge-notification">
                      {dispatchCount > 99 ? "99+" : dispatchCount}
                      <span className="visually-hidden">unread messages</span>
                    </span>
                  </div>
                </Link>
                <Link
                  to="/orderlist/completed"
                  //  onClick={() => setActiveTab("completed")}
                >
                  <div
                    className={`ibox-title me-5 ${
                      activeTab === "completed" ? "active" : ""
                    }`}
                  >
                    COMPLETED
                    <span className="translate-middle badge rounded-pill badge-notification">
                      {compCount > 99 ? "99+" : compCount}
                      <span className="visually-hidden">unread messages</span>
                    </span>
                  </div>
                </Link>
              </div>

              <Outlet />
            </div>
          </div>

          {/* <!-- BEGIN PAGA BACKDROPS--> */}
          <div className="sidenav-backdrop backdrop"></div>
          {/* <div className="preloader-backdrop">
          <div className="page-preloader">Loading</div>
        </div> */}
          {/* <!-- END PAGA BACKDROPS--> */}
        </div>
      )}
    </div>
  );
}
