import React, { useState, useEffect } from "react";
import Loader from "../../layout/Loader";
import { ToastContainer, toast } from "react-toastify";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { addBusinessDetails } from "../../../service/superAdmin.service";
import { useNavigate } from "react-router-dom";
import "react-phone-number-input/style.css";
import PhoneInput, {
  formatPhoneNumber,
  formatPhoneNumberIntl,
  isPossiblePhoneNumber,
  isValidPhoneNumber,
} from "react-phone-number-input";

export default function AddBusiness() {
  const navigate = useNavigate();
  const token = useSelector((state) => state.status.token.token);
  const [isLoading, setIsLoading] = useState(false); // Add loading state
  const [businessLogo, setBusinessLogo] = useState(null);
  const [currency, setCurrency] = useState("");
  const [currencySymbol, setCurrencySymbol] = useState("");
  const [availablePlans, setAvailablePlans] = useState([
    { value: "1", label: "$200.00" },
    { value: "2", label: "$300.00" },
  ]);
  const [phoneValue, setPhoneValue] = useState("");
  const [country, setCountry] = useState("IN");

  useEffect(() => {
    window.scrollTo(0, 1);
  }, []);

  const {
    register,
    setValue,
    setError,
    reset,
    handleSubmit: handleSubmitAdd,
    formState: { errors },
  } = useForm({
    defaultValues: {
      currency: "",
      currencySymbol: "",
    },
  });

  const addData = (item) => {
    const sanitizedFileName = businessLogo.name
      .replace(/ /g, "_")
      .replace(/&/g, "");
    const updatedFile = new File([businessLogo], sanitizedFileName, {
      type: businessLogo.type,
    });
    const data = {
      name: item.businessName,
      email: item.email,
      phone: item.phone,
      address: item.address,
      country: item.country,
      state: item.state,
      city: item.city,
      zip: item.zip,
      currency: item.currency,
      currency_symbol: item.currencySymbol,
      logo: updatedFile,
      plan: item.plan,
      desc: item.aboutBusiness,
      working_hours: item.workingHour,
      template_name: "Template_1.png",
      nearby_zip: "NULL",
      restaurant_url:
        process.env.REACT_APP_IMAGE_STORAGE_PATH + updatedFile.name,
      image: updatedFile,
      status: "ACTIVE",
    };
    addBusinessDetails(token, data).then((res) => {
      toast.success("Business Added...!", {
        autoClose: 1500,
        style: {
          backgroundColor: "black",
          color: "white",
        },
      });

      reset();
    });
    setCurrency("");
    setCurrencySymbol("");
    reset({ uploadImage: true });
    setBusinessLogo(null);
    setTimeout(() => {
      navigate("/business");
    }, 3000);
  };

  const addImage = (event) => {
    const input = event.target;
    if (input.files && input.files[0]) {
      const originalFile = input.files[0];

      const timestamp = Date.now();
      const uniqueFileName = `${timestamp}_${originalFile.name
        .replace(/ /g, "_")
        .replace(/&/g, "")}`;

      const uniqueFile = new File([originalFile], uniqueFileName, {
        type: originalFile.type,
      });
      setBusinessLogo(uniqueFile);

      const reader = new FileReader();
      reader.onload = function (e) {
        document.getElementById("displayedImageAdd").src = e.target.result;

        setError("businessLogo", { type: "manual", message: "" });
      };

      reader.readAsDataURL(uniqueFile);
    }
  };

  const handleAddImageClick = () => {
    // Trigger the click event on the file input when the displayed image is clicked
    document.getElementById("imageInputAdd").click();
  };

  // currency change handler
  const handleCurrencyChange = (event) => {
    const selectedCurrency = event.target.value;
    setCurrency(selectedCurrency);
    setValue("currency", selectedCurrency);
  };

  // currency symbol change handler
  const handleCurrencySymbolChange = (event) => {
    const selectedCurrencySymbol = event.target.value;
    setCurrencySymbol(selectedCurrencySymbol);
    setValue("currencySymbol", selectedCurrencySymbol);
  };

  // Function to handle the country selection
  const handleCountryChange = (e) => {
    const country = e.target.value;

    switch (country) {
      case "US":
        setCurrency("USD");
        setCurrencySymbol("$");
        setValue("currency", "USD");
        setValue("currencySymbol", "$");
        setAvailablePlans([
          { value: 1, label: "$200.00" },
          { value: 2, label: "$300.00" },
        ]);
        break;
      case "UK":
        setCurrency("GBP");
        setCurrencySymbol("£");
        setValue("currency", "GBP");
        setValue("currencySymbol", "£");
        setAvailablePlans([
          { value: 1, label: "$200.00" },
          { value: 2, label: "$300.00" },
        ]);
        break;
      case "IN":
        setCurrency("INR");
        setCurrencySymbol("₹");
        setValue("currency", "INR");
        setValue("currencySymbol", "₹");
        setAvailablePlans([
          { value: 21, label: "₹3500.00" },
          { value: 22, label: "₹5000.00" },
          { value: 24, label: "₹6000.00" },
        ]);
        break;
      default:
        setCurrency("");
        setCurrencySymbol("");
        setValue("currency", "");
        setValue("currencySymbol", "");
        setAvailablePlans([
          { value: 1, label: "$200.00" },
          { value: 2, label: "$300.00" },
        ]);
    }
  };

  const handlePhoneChange = (value) => {
    setPhoneValue(value);
    setValue("phone", value);
  };

  const getMaxLengthForCountry = () => {
    switch (country) {
      case "US":
        return 14; // US phone numbers usually have 14 characters (including country code)
      case "IN":
        return 13; // India phone numbers usually have 13 characters (including country code)
      case "UK":
        return 14; // UK phone numbers usually have 14 characters (including country code)
      default:
        return 15;
    }
  };

  return (
    <div>
      {isLoading ? (
        // Conditional rendering of loader
        <Loader />
      ) : (
        <div className="page-content ms-3 fade-in-up">
          <ToastContainer position="top-center" className="mt-4" limit={1} />

          <div className="">
            <div className="ms-2 me-3">
              <form
                id="editForm"
                data-form-label="update"
                onSubmit={handleSubmitAdd(addData)}
                // encType="multipart/form-data"
              >
                <div className="modal-content business-modal">
                  <div className="modal-header">
                    <h4 className="modal-title fw-bold fs-5">ADD BUSINESS</h4>
                    <button
                      type="button"
                      className="close"
                      onClick={() => window.history.back()}
                    >
                      &times;
                    </button>
                  </div>
                  <div className="modal-body business-data">
                    {/* <p>Some text in the modal.</p> */}
                    <table width={"100%"}>
                      <tbody>
                        <tr>
                          <td>
                            <label>
                              Business Name <i className="fa fa-asterisk"></i>
                            </label>

                            <input
                              {...register("businessName", {
                                required: "Enter business name",
                              })}
                              placeholder="Enter your business name"
                              className="mb-2"
                            />
                            {errors.businessName && (
                              <p className="text-danger">
                                {errors.businessName.message}
                              </p>
                            )}
                          </td>
                          <td>
                            <label>
                              Email <i className="fa fa-asterisk"></i>
                            </label>
                            <input
                              {...register("email", {
                                required: "Enter email address",
                                pattern: {
                                  value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/, // Regex for email validation
                                  message: "Enter a valid email address", // Error message for invalid email
                                },
                              })}
                              placeholder="Enter your email"
                            />
                            {errors.email && (
                              <p className="text-danger">
                                {errors.email.message}
                              </p>
                            )}
                          </td>
                          <td>
                            <label>
                              Phone <i className="fa fa-asterisk"></i>
                            </label>
                            <PhoneInput
                              international
                              country={country}
                              value={phoneValue}
                              onChange={handlePhoneChange}
                              inputClass="business-modal-phone-input"
                              containerClass="business-modal-phone-container"
                              placeholder="Enter phone number"
                              inputProps={{
                                maxLength: getMaxLengthForCountry(),
                              }}
                            />
                            {/* Phone validation error */}
                            {!phoneValue || !isValidPhoneNumber(phoneValue) ? (
                              <p className="text-danger">
                                Invalid phone number
                              </p>
                            ) : null}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <label>
                              Address <i className="fa fa-asterisk"></i>
                            </label>

                            <input
                              {...register("address", {
                                required: "Enter address",
                              })}
                              placeholder="Enter your address"
                            />
                            {errors.address && (
                              <p className="text-danger">
                                {errors.address.message}
                              </p>
                            )}
                          </td>
                          <td>
                            <label>
                              Country <i className="fa fa-asterisk"></i>
                            </label>

                            <select
                              {...register("country", {
                                required: "Select a country",
                              })}
                              defaultValue=""
                              className="form-select"
                              onChange={handleCountryChange}
                            >
                              <option value="" disabled>
                                Select your country
                              </option>
                              <option value="US">
                                United States of America
                              </option>
                              <option value="UK">United Kingdom</option>
                              <option value="IN">India</option>
                            </select>
                            {errors.country && (
                              <p className="text-danger">
                                {errors.country.message}
                              </p>
                            )}
                          </td>
                          <td>
                            <label>
                              State <i className="fa fa-asterisk"></i>
                            </label>

                            <input
                              {...register("state", {
                                required: "Enter state",
                              })}
                              placeholder="Enter your state"
                              className="mb-2"
                            />
                            {errors.state && (
                              <p className="text-danger">
                                {errors.state.message}
                              </p>
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <label>
                              City <i className="fa fa-asterisk"></i>
                            </label>

                            <input
                              {...register("city", {
                                required: "Enter city",
                              })}
                              placeholder="Enter your city"
                              className="mb-2"
                            />
                            {errors.city && (
                              <p className="text-danger">
                                {errors.city.message}
                              </p>
                            )}
                          </td>
                          <td>
                            <label>
                              Zip <i className="fa fa-asterisk"></i>
                            </label>

                            <input
                              {...register("zip", {
                                required: "Enter zip",
                                pattern: {
                                  value: /^\d{6}$|^\d{5}(-\d{4})?$/, // India (6 digits) or US (5 digits or 9 digits with hyphen)
                                  message: "Enter a valid zip code",
                                },
                              })}
                              placeholder="Enter your zip"
                              className="mb-2"
                            />
                            {errors.zip && (
                              <p className="text-danger">
                                {errors.zip.message}
                              </p>
                            )}
                          </td>
                          <td>
                            <label>
                              Currency <i className="fa fa-asterisk"></i>
                            </label>

                            <select
                              value={currency}
                              onChange={(e) => handleCurrencyChange(e)} // Ensure the state is updated on change
                              {...register("currency", {
                                required: "Select a currency",
                              })}
                              className="form-select"
                            >
                              <option value="" disabled>
                                Select your currency
                              </option>
                              <option value="USD">USD</option>
                              <option value="GBP">GBP</option>
                              <option value="INR">INR</option>
                            </select>
                            {errors.currency && currency === "" && (
                              <p className="text-danger">
                                {errors.currency.message}
                              </p>
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <label>
                              Currency Symbol <i className="fa fa-asterisk"></i>
                            </label>

                            <select
                              value={currencySymbol} // Use value for controlled component
                              onChange={(e) => handleCurrencySymbolChange(e)} // Ensure the state is updated on change
                              {...register("currencySymbol", {
                                required: "Select a currency symbol", // Validation message
                              })}
                              className="form-select"
                            >
                              <option value="" disabled>
                                Select your currency symbol
                              </option>
                              <option value="$">$</option>
                              <option value="£">£</option>
                              <option value="₹">₹</option>
                            </select>
                            {errors.currencySymbol && currencySymbol === "" && (
                              <p className="text-danger">
                                {errors.currencySymbol.message}
                              </p>
                            )}
                          </td>
                          <td>
                            <label>
                              Select Plan <i className="fa fa-asterisk"></i>
                            </label>
                            <div className="radio-buttons-container">
                              {availablePlans.map((plan) => (
                                <label key={plan.value} className="radio-label">
                                  <input
                                    type="radio"
                                    value={plan.value}
                                    {...register("plan", {
                                      required: "Please select a plan", // Validation message
                                    })}
                                    className="mt-3"
                                  />{" "}
                                  {plan.label}
                                </label>
                              ))}
                            </div>
                            {errors.plan && (
                              <p className="text-danger">
                                {errors.plan.message}
                              </p>
                            )}
                          </td>
                          <td>
                            <label>
                              Working Hours <i className="fa fa-asterisk"></i>
                            </label>

                            <input
                              {...register("workingHour", {
                                required: "Enter working hours",
                              })}
                              placeholder="Enter your working hours"
                              className="mb-2"
                            />
                            {errors.workingHour && (
                              <p className="text-danger">
                                {errors.workingHour.message}
                              </p>
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <label>
                              Business Logo <i className="fa fa-asterisk"></i>
                            </label>

                            {/* Hidden file input */}
                            <input
                              type="file"
                              id="imageInputAdd"
                              accept="image/*"
                              defaultValue={businessLogo}
                              {...register("businessLogo", {
                                required: "Please upload business logo",
                              })}
                              style={{ display: "none", marginLeft: 0 }}
                              onChange={addImage}
                            />

                            {/* Displayed image */}
                            <div>
                              <img
                                src={
                                  businessLogo ||
                                  "/assets/img/custom/uploadImage.png"
                                }
                                id="displayedImageAdd"
                                alt="Upload"
                                onClick={() => handleAddImageClick()}
                                style={{ marginLeft: 0 }}
                              />
                            </div>

                            {errors.businessLogo && (
                              <p className="text-danger position-absolute img-error">
                                {errors.businessLogo.message}
                              </p>
                            )}
                          </td>
                          <td>
                            <label>
                              About Business <i className="fa fa-asterisk"></i>
                            </label>
                            <textarea
                              {...register("aboutBusiness", {
                                required: "Enter your business about us",
                              })}
                              cols={30}
                              rows={5}
                            />
                            {errors.aboutBusiness && (
                              <p className="text-danger position-absolute textarea">
                                {errors.aboutBusiness.message}
                              </p>
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="modal-footer justify-content-center">
                    <button type="submit" className="btn btn-default w-50">
                      ADD BUSINESS
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
