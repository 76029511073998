import React, { useState, useEffect } from "react";
import Loader from "../../layout/Loader";
import { ToastContainer, toast } from "react-toastify";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import {
  getBusinessName,
  updateAdminDetails,
  updateBusinessDetails,
} from "../../../service/superAdmin.service";
import { useLocation, useNavigate } from "react-router-dom";
import "react-phone-number-input/style.css";
import PhoneInput, {
  formatPhoneNumber,
  formatPhoneNumberIntl,
  isPossiblePhoneNumber,
  isValidPhoneNumber,
} from "react-phone-number-input";

export default function UpdateAdmin() {
  const navigate = useNavigate();
  let location = useLocation();
  const token = useSelector((state) => state.status.token.token);
  const [businessDetails, setBusinessDetails] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [updateBusinessAdmin, setUpdateBusinessAdmin] = useState();
  const [restid, setRestid] = useState();
  const [adminId, setAdminId] = useState();
  const [restName, setRestName] = useState();
  const [phoneValue, setPhoneValue] = useState("");
  const [country, setCountry] = useState("IN");

  const getRestBusinessName = async (id) => {
    try {
      setIsLoading(true);
      const res = await getBusinessName(token, id);

      const BusinessrestName = res.data.name;

      if (res && res.data) {
        setRestName(BusinessrestName);
        setValue("businessName", BusinessrestName);
      } else {
        toast.error("Failed to fetch business details");
      }
    } catch (error) {
      toast.error("Error fetching business details");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 1);
    if (location?.state?.businessAdminDetails) {
      const initialUpdateAdmin = { ...location.state.businessAdminDetails };
      setAdminId(initialUpdateAdmin.id);
      setRestid(initialUpdateAdmin.restaurant);
      setBusinessDetails(initialUpdateAdmin);
      setUpdateBusinessAdmin(initialUpdateAdmin);
      setValue("username", initialUpdateAdmin.username);
      setValue("email", initialUpdateAdmin.email);
      setValue("phone", initialUpdateAdmin.phone_number);
      setPhoneValue(initialUpdateAdmin.phone_number);


      getRestBusinessName(initialUpdateAdmin.restaurant);
    }
  }, [location.state.businessDetails]);

  const {
    register,
    setValue,
    reset,
    handleSubmit: handleSubmitUpdate,
    formState: { errors },
  } = useForm({
    defaultValues: {
      username: businessDetails?.userName || "",
      email: businessDetails?.email || "",
      phone: businessDetails?.phone || "",
      businessName: restName || "",
    },
  });

  const updateData = (item) => {
    let data1 = {
      id: adminId,
      username: item.username,
      email: item.email,
      phone_number: item.phone,
      restaurant: restid,
      status: "ACTIVE",
    };
    updateAdminDetails(token, data1).then((res) => {
      toast.success("Business Admin Updated...!", {
        autoClose: 1500,
        style: {
          backgroundColor: "black",
          color: "white",
        },
      });

      reset();
    });

    setTimeout(() => {
      navigate("/admin");
    }, 3000);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUpdateBusinessAdmin({
      ...updateBusinessAdmin,
      [name]: value,
    });
    setValue(name, value);
  };

  const handlePhoneChange = (value) => {
    setPhoneValue(value);
    setValue("phone", value);
  };

  const getMaxLengthForCountry = () => {
    switch (country) {
      case "US":
        return 14; // US phone numbers usually have 14 characters (including country code)
      case "IN":
        return 13; // India phone numbers usually have 13 characters (including country code)
      case "UK":
        return 14; // UK phone numbers usually have 14 characters (including country code)
      default:
        return 15;
    }
  };

  return (
    <div>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="page-content ms-3 fade-in-up">
          <ToastContainer position="top-center" className="mt-4" limit={1} />

          <div className="">
            <div className="ms-2 me-3">
              <form
                id="editForm"
                data-form-label="update"
                onSubmit={handleSubmitUpdate(updateData)}
              >
                <div className="modal-content business-modal">
                  <div className="modal-header">
                    <h4 className="modal-title fw-bold fs-5">
                      UPDATE BUSINESS ADMIN
                    </h4>
                    <button
                      type="button"
                      className="close"
                      onClick={() => window.history.back()}
                    >
                      &times;
                    </button>
                  </div>
                  <div className="modal-body business-data">
                    <table width={"100%"}>
                      <tbody>
                        <tr>
                          <td>
                            <label>
                              User Name <i className="fa fa-asterisk"></i>
                            </label>

                            <input
                              {...register("username", {
                                required: "Enter user name",
                              })}
                              placeholder="Enter user name"
                              className="mb-2"
                              onChange={handleInputChange}
                            />
                            {errors.username && (
                              <p className="text-danger">
                                {errors.username.message}
                              </p>
                            )}
                          </td>
                          <td>
                            <label>
                              Business Name <i className="fa fa-asterisk"></i>
                            </label>

                            <input
                              {...register("businessName", {
                                required: "Enter business name",
                              })}
                              placeholder="Enter your business name"
                              className="mb-2"
                              onChange={handleInputChange}
                              style={{ backgroundColor: "#f0f0f0 !important" }}
                              disabled
                            />
                            {errors.businessName && (
                              <p className="text-danger">
                                {errors.businessName.message}
                              </p>
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <label>
                              Email <i className="fa fa-asterisk"></i>
                            </label>
                            <input
                              {...register("email", {
                                required: "Enter email address",
                                pattern: {
                                  value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                                  message: "Enter a valid email address",
                                },
                              })}
                              placeholder="Enter your email"
                              onChange={handleInputChange}
                            />
                            {errors.email && (
                              <p className="text-danger">
                                {errors.email.message}
                              </p>
                            )}
                          </td>
                          <td>
                            <label>
                              Phone <i className="fa fa-asterisk"></i>
                            </label>

                            <PhoneInput
                              international
                              country={country}
                              value={phoneValue}
                              onChange={handlePhoneChange}
                              inputClass="business-modal-phone-input"
                              containerClass="business-modal-phone-container"
                              placeholder="Enter phone number"
                              inputProps={{
                                maxLength: getMaxLengthForCountry(),
                              }}
                            />
                            {/* Phone validation error */}
                            {!phoneValue || !isValidPhoneNumber(phoneValue) ? (
                              <p className="text-danger">
                                Invalid phone number
                              </p>
                            ) : null}

                            {/* <input
                                                            {...register("phone", {
                                                                required: "Enter phone number",
                                                                pattern: {
                                                                    value: /^[0-9]{10}$/,
                                                                    message:
                                                                        "Enter a valid 10-digit phone number",
                                                                },
                                                            })}
                                                            placeholder="Enter your phone number"
                                                            className="mb-2"
                                                            onChange={handleInputChange}
                                                        />
                                                        {errors.phone && (
                                                            <p className="text-danger">
                                                                {errors.phone.message}
                                                            </p>
                                                        )} */}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="modal-footer justify-content-center">
                    <button type="submit" className="btn btn-default w-50">
                      UPDATE ADMIN
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
