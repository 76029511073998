import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import { addMainCategory } from "../../../service/restAdmin.service";
import { useNavigate } from "react-router-dom";

const AddMainCategory = () => {
  const navigate = useNavigate();
  const restaurantId = useSelector((state) => state?.admin?.admin?.restaurant);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const token = useSelector((state) => state.status.token.token);

  // Function to handle form submission
  const onSubmit = async (data) => {
    setIsLoading(true);

    // Prepare the category data
    const categoryData = {
      restaurant_id: restaurantId,
      name: data.categoryName,
      status: data.catStatus,
    };

    // Prepare the open hours data dynamically from form inputs
    const openHoursData = {
      Monday: `${data.startTimeMonday}@${data.endTimeMonday}@${data.statusMonday}`,
      Tuesday: `${data.startTimeTuesday}@${data.endTimeTuesday}@${data.statusTuesday}`,
      Wednesday: `${data.startTimeWednesday}@${data.endTimeWednesday}@${data.statusWednesday}`,
      Thursday: `${data.startTimeThursday}@${data.endTimeThursday}@${data.statusThursday}`,
      Friday: `${data.startTimeFriday}@${data.endTimeFriday}@${data.statusFriday}`,
      Saturday: `${data.startTimeSaturday}@${data.endTimeSaturday}@${data.statusSaturday}`,
      Sunday: `${data.startTimeSunday}@${data.endTimeSunday}@${data.statusSunday}`,
    };

    // Call the service function with both categoryData and openHoursData
    const result = await addMainCategory(token, categoryData, openHoursData);
    if (result.success) {
      toast.success(result.message);
      reset();
      toast.success(`Main Category Added Successfully...!`, {
        autoClose: 1500,
        style: {
          backgroundColor: "black",
          color: "white",
        },
      });
      setTimeout(() => {
        navigate("/main-category");
      }, 1500);
    } else {
      toast.error(result.message);
    }

    setIsLoading(false);
  };

  return (
    <div className="container mt-4">
      <ToastContainer position="top-center" limit={1} />
      <div className="ibox">
        <div className="product-modal0 p-0">
          <form onSubmit={handleSubmit(onSubmit)} id="editForm">
            <div className="form-card">
              <div className="modal-header">
                <h3 className="form-title">Add Main Category</h3>
                <button
                  type="button"
                  className="close"
                  onClick={() => window.history.back()}
                >
                  &times;
                </button>
              </div>

              <div className="form-body p-3 setup-table">
                <div className="row">
                  {/* Category Name */}
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>
                        Category Name <span className="text-danger">*</span>
                      </label>
                      <input
                        {...register("categoryName", {
                          required: "Category name is required",
                          pattern: {
                            value: /\S(.*\S)?/,
                            message: "Category name cannot be empty or spaces",
                          },
                        })}
                        className="form-control"
                        placeholder="Enter Category Name"
                      />
                      {errors.categoryName && (
                        <p className="text-danger mt-2">
                          {errors.categoryName.message}
                        </p>
                      )}
                    </div>
                  </div>

                  {/* Category Status */}
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>
                        Status <span className="text-danger">*</span>
                      </label>
                      <select
                        {...register("catStatus", {
                          required: "Status is required",
                        })}
                        className="form-control"
                      >
                        <option value="True">True</option>
                        <option value="False">False</option>
                      </select>
                      {errors.catStatus && (
                        <p className="text-danger mt-2">
                          {errors.catStatus.message}
                        </p>
                      )}
                    </div>
                  </div>
                </div>

                {/* Weekdays with Start Time, End Time, Status */}
                {["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"].map(
                  (day, index) => (
                    <div key={index} className="row">
                      <div className="col-md-3">
                        <div className="form-group">
                          <label>{day}</label>
                          <input
                            className="form-control"
                            value={day}
                            readOnly
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label>Start Time</label>
                          <input
                            {...register(`startTime${day}`, {
                              required: "Start time is required",
                            })}
                            type="time"
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label>End Time</label>
                          <input
                            {...register(`endTime${day}`, {
                              required: "End time is required",
                            })}
                            type="time"
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label>Status</label>
                          <select
                            {...register(`status${day}`, {
                              required: "Status is required",
                            })}
                            className="form-control"
                          >
                            <option value="True">True</option>
                            <option value="False">False</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  )
                )}
              </div>

              <div className="form-footer text-center mb-5">
                <button
                  type="submit"
                  className="btn btn-default col-4"
                  disabled={isLoading}
                >
                  {isLoading ? "Adding..." : "Add Main Category"}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddMainCategory;
