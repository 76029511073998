import React, { useEffect, useState } from "react";
import Loader from "../../layout/Loader";
import { ToastContainer, toast } from "react-toastify";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import ReactPaginate from "react-paginate";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { addAddonData, deleteAddonData, getAddons, updateAddonData } from "../../../service/restAdmin.service";
import { useForm } from "react-hook-form";

export default function Addons() {
  const navigate = useNavigate();
  const token = useSelector((state) => state?.status?.token?.token);
  const restaurantId = useSelector((state) => state?.admin?.admin?.restaurant);

  const [isLoading, setIsLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [addonData, setAddonData] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [count, setCount] = useState(0);
  const [isButtonActive, setIsButtonActive] = useState(false);
  const [selectionMethod, setSelectionMethod] = useState('Any One');
  const [editedItem, setEditedItem] = useState(null);
  const [addonTitle, setAddonTitle] = useState(null);
  const [addonName, setAddonName] = useState(null);
  const [deletedItem, setDeletedItem] = useState(null);

  const fetchAddons = () => {
    setIsLoading(true);
    getAddons(token).then((res) => {

      const filterData = res?.data?.filter((item) => item.restaurant == restaurantId).sort((a,b) => b.addon_id - a.addon_id);

      setAddonData(filterData);
      setCount(filterData?.length)
      setIsLoading(false);
    });
  };

  useEffect(() => {
    window.scrollTo(0, 1);
    fetchAddons();
  }, []);

  // Filter categories based on search query with safe checks
  const filteredAddons = addonData?.filter((item) => {
    const addon_title = item?.addon_title?.toLowerCase() || "";
    const addon_name = item?.addon_name?.toLowerCase() || "";
    const selection_method = item?.selection_method?.toLowerCase() || "";
    const query = searchQuery.trim().toLowerCase();
  
    return addon_title.includes(query) || addon_name.includes(query) || selection_method.includes(query);
  }) || [];

  // Pagination logic
  const startIndex = currentPage * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const paginatedData = filteredAddons?.slice(startIndex, endIndex);

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  const toggleButton = () => {
    reset1({ addon_title: "", addon_name: "", selection_method: "" });
    setIsButtonActive(!isButtonActive);
  };

  const {
    register: register1,
    handleSubmit: handleSubmitAdd,
    reset: reset1,
    setError: setError1,
    formState: { errors: errors1 },
  } = useForm();

  const addData = (item) => {
    const data = {
      restaurant: restaurantId,
      addon_title: item.addon_title,
      addon_name: item.addon_name,
      selection_method: selectionMethod
    };
    addAddonData(token, data).then((res) => {
      toast.success("Addon Added...!", {
        autoClose: 1500,
        style: {
          backgroundColor: "black",
          color: "white",
        },
      });
      document.getElementById("closeAddModal").click();
      fetchAddons();
    });
    reset1({ addon_title: "", addon_name: "", selection_method: "" });
  };

  const {
    register,
    handleSubmit: handleSubmitUpdate,
    formState: { errors },
    clearErrors,
  } = useForm();

  // Function to handle editing
 const handleEdit = (item) => {
   setEditedItem(item);
   setAddonTitle(item.addon_title);
   setAddonName(item.addon_name);
   setSelectionMethod(item.selection_method);
   // clearErrors();
 };

  const handleAddonName = (e) => {
    setAddonName(e.target.value);
  }

  const handleAddonTitle = (e) => {
    setAddonTitle(e.target.value);
  }

  const updateData = (item) => {
    const data = {
      restaurant: restaurantId,
      addon_title: addonTitle,
      addon_name: addonName,
      selection_method: selectionMethod
    };
    updateAddonData(token, data, editedItem.addon_id).then(
      (res) => {
        toast.success("Addon Updated...!", {
          autoClose: 1500,
          style: {
            backgroundColor: "black",
            color: "white",
          },
        });
        document.getElementById("closeUpdateModal").click();
        fetchAddons();
      }
    );
    fetchAddons();
  };

  const handleDelete = (item) => {
    setDeletedItem(item);
  };

  // delete form
  const {
    register: register2,
    handleSubmit: handleSubmitDelete,
    watch,
    reset: reset2,
    formState: { errors: errors2 },
  } = useForm();

  const deleteData = () => {
    deleteAddonData(token, deletedItem.addon_id).then((res) => {
      toast.error("Addon Deleted...!", {
        autoClose: 1500,
        style: {
          backgroundColor: "black",
          color: "white",
        },
      });
      document.getElementById("closeDeleteModal").click();
      fetchAddons();
    });
  };

  return (
    <div>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="page-content ms-3 fade-in-up">
          <ToastContainer position="top-center" className="mt-4" limit={1} />
          <div className="ibox">
            <div className="ibox-body m-3">
              <div
                className={`sticky-button ${isButtonActive ? "active" : ""}`}
                onClick={() => toggleButton()}
                data-toggle="modal"
                data-target="#addAddonModal"
              >
                <i className="fa fa-plus"></i>
              </div>
              <div className="d-flex justify-content-between my-3">
                <div>
                  Show{" "}
                  <select
                    value={itemsPerPage}
                    onChange={(e) =>
                      setItemsPerPage(parseInt(e.target.value, 10))
                    }
                  >
                    {[10, 20, 30, 40, 50, 100].map((num) => (
                      <option key={num} value={num}>
                        {num}
                      </option>
                    ))}
                  </select>{" "}
                  Results
                </div>
                <div>
                  Search:{" "}
                  <input
                    type="text"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    className="btn-outline-secondary"
                  />
                </div>
              </div>

              <DragDropContext
              //  onDragEnd={handleDragEnd}
              >
                <Droppable droppableId="categories">
                  {(provided) => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                      <table className="table table-striped table-bordered table-hover">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Addon Title</th>
                            <th>Addon Name</th>
                            <th>Selection Method</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {paginatedData?.map((item, index) => (
                            <Draggable
                              key={
                                item.addon_id || `draggable-${index}`
                              }
                              draggableId={
                                item.addon_id
                                  ? item.addon_id.toString()
                                  : `draggable-${index}`
                              }
                              index={index}
                            >
                              {(provided) => (
                                <TableRow
                                  provided={provided}
                                  index={startIndex + index}
                                  item={item}
                                  handleEdit={handleEdit}
                                  handleDelete={handleDelete}
                                />
                              )}
                            </Draggable>
                          ))}
                        </tbody>
                      </table>
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
              {count > itemsPerPage && (
                <div className="d-flex justify-content-between my-3">
                  <div className="fw-light">
                    Showing{" "}
                    {filteredAddons.length > 0
                      ? startIndex + 1
                      : startIndex}{" "}
                    to {Math.min(endIndex, filteredAddons.length)} of{" "}
                    {filteredAddons.length} entries
                  </div>
                  <div>
                    {filteredAddons.length > itemsPerPage && (
                      <ReactPaginate
                        previousLabel={
                          <button
                            type="button"
                            className="btn btn-outline-secondary w-100"
                          >
                            Previous
                          </button>
                        }
                        nextLabel={
                          <button
                            type="button"
                            className="btn btn-outline-secondary w-100"
                          >
                            Next
                          </button>
                        }
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={Math.ceil(
                          filteredAddons.length / itemsPerPage
                        )}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={handlePageChange}
                        containerClassName={"pagination"}
                        subContainerClassName={"pages pagination"}
                        activeClassName={
                          "active-class btn btn-outline-secondary w-100 fw-bold"
                        }
                        pageClassName={
                          "page-class btn btn-outline-secondary w-100"
                        }
                      />
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      {/* <!-- Add Modal --> */}
      <div className="container ">
        <div className="modal fade" id="addAddonModal" role="dialog">
          <div className="modal-dialog">
            {/* <!-- Add Modal content--> */}
            <form
              id="addForm"
              data-form-label="add"
              onSubmit={handleSubmitAdd(addData)}
            >
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title fw-bold">ADD ADDON</h4>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    id="closeAddModal"
                  >
                    &times;
                  </button>
                </div>
                <div className="modal-body">
                  {/* <p>Some text in the modal.</p> */}

                  <div>
                    <div>
                      <label className="mt-3">
                        Addon Title <i className="fa fa-asterisk"></i>
                      </label>

                      <input
                        {...register1("addon_title", {
                          required: "Please Enter Addon Title",
                        })}
                      />
                      {errors1.addon_title && (
                        <p className="text-danger position-absolute">
                          {errors1.addon_title.message}
                        </p>
                      )}
                    </div>
                    <div>
                      <label className="mt-3">
                        Addon Name <i className="fa fa-asterisk"></i>
                      </label>

                      <input
                        {...register1("addon_name", {
                          required: "Please Enter Addon Name",
                        })}
                      />
                      {errors1.addon_name && (
                        <p className="text-danger position-absolute">
                          {errors1.addon_name.message}
                        </p>
                      )}
                    </div>
                    <div>
                      <label className="mt-3">
                        Selection Method <i className="fa fa-asterisk"></i>
                      </label>
                      <div className="form-check mb-5 me-4">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="selection_method"
                          value="Any One"
                          checked={selectionMethod === "Any One"}
                          onChange={() => setSelectionMethod("Any One")}
                        />
                        <label className="form-check-label me-5">Any One</label>
                        <input
                          className="form-check-input"
                          type="radio"
                          name="selection_method"
                          value="Multiple"
                          checked={selectionMethod === "Multiple"}
                          onChange={() =>
                            setSelectionMethod("Multiple")
                          }
                        />
                        <label className="form-check-label">
                        Multiple
                        </label>
                      </div>
                      {errors1.selection_method && (
                        <p className="text-danger position-absolute">
                          {errors1.selection_method.message}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button type="submit" className="btn btn-default">
                    ADD
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      {/* <!-- Edit Modal --> */}

      <div className="container ">
        <div className="modal fade" id="editAddonModal" role="dialog">
          <div className={`modal-dialog`} id="addonModal">
            {/* <!-- Edit Modal content--> */}
            <form
              id="editForm"
              data-form-label="update"
              onSubmit={handleSubmitUpdate(updateData)}
            >
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title fw-bold">UPDATE ADDON</h4>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    id="closeUpdateModal"
                  >
                    &times;
                  </button>
                </div>
                <div className="modal-body">
                  {/* <p>Some text in the modal.</p> */}
                  {editedItem && (
                    <div>
                    <div>
                      <label className="mt-3">
                        Addon Title <i className="fa fa-asterisk"></i>
                      </label>

                      <input
                        {...register("addon_title", {
                          required: "Please Enter Addon Title",
                        })}
                        value={addonTitle}
                        onChange={(e) => handleAddonTitle(e)}
                      />
                      {errors1.addon_title && (
                        <p className="text-danger position-absolute">
                          {errors1.addon_title.message}
                        </p>
                      )}
                    </div>
                    <div>
                      <label className="mt-3">
                        Addon Name <i className="fa fa-asterisk"></i>
                      </label>

                      <input
                        {...register("addon_name", {
                          required: "Please Enter Addon Name",
                        })}
                        value={addonName}
                        onChange={(e) => handleAddonName(e)}
                      />
                      {errors1.addon_name && (
                        <p className="text-danger position-absolute">
                          {errors1.addon_name.message}
                        </p>
                      )}
                    </div>
                    <div>
                      <label className="mt-3">
                        Selection Method <i className="fa fa-asterisk"></i>
                      </label>
                      <div className="form-check mb-5 me-4">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="selection_method"
                          value="Any One"
                          checked={selectionMethod === "Any One"}
                          onChange={() => setSelectionMethod("Any One")}
                        />
                        <label className="form-check-label me-5">Any One</label>
                        <input
                          className="form-check-input"
                          type="radio"
                          name="selection_method"
                          value="Multiple"
                          checked={selectionMethod === "Multiple"}
                          onChange={() =>
                            setSelectionMethod("Multiple")
                          }
                        />
                        <label className="form-check-label">
                        Multiple
                        </label>
                      </div>
                      {errors1.selection_method && (
                        <p className="text-danger position-absolute">
                          {errors1.selection_method.message}
                        </p>
                      )}
                    </div>
                  </div>
                  )}
                </div>
                <div className="modal-footer">
                  <button
                    type="submit"
                    className="btn btn-default"
                    // data-dismiss="modal"
                    // onClick={updateData}
                  >
                    UPDATE
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      {/* <!-- Delete Modal --> */}
      <div className="container ">
        <div className="modal fade" id="deleteAddonModal" role="dialog">
          <div className="modal-dialog">
            {/* <!-- Delete Modal content--> */}
            <form
              id="deleteForm"
              data-form-label="delete"
              onSubmit={handleSubmitDelete(deleteData)}
            >
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title fw-bold">WARNING</h4>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    id="closeDeleteModal"
                  >
                    &times;
                  </button>
                </div>
                <div className="modal-body">
                  <p className="fs-5 fw-bold">
                    Do you want to delete this 'Addon'!
                  </p>
                </div>
                <div className="modal-footer">
                  <button type="submit" className="btn btn-default">
                    DELETE
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

    </div>
  );
}

// TableRow Component
const TableRow = ({ provided, item, index, handleEdit, handleDelete }) => (
  <tr
    ref={provided.innerRef}
    {...provided.draggableProps}
    {...provided.dragHandleProps}
  >
    <td>{index + 1}</td>
    <td>{item.addon_title || "N/A"}</td>
    <td>{item.addon_name || "N/A"}</td>
    <td>{(item.selection_method).toUpperCase() || "N/A"}</td>
    <td>
      <button
        type="button"
        className="btn btn-link p-0"
        onClick={() => handleEdit(item)}
        data-toggle="modal"
        data-target="#editAddonModal"
      >
        <i className="fa fa-pencil popup me-4 pencil-small"></i>
      </button>
      <button
        type="button"
        className="btn btn-link p-0"
        onClick={() => handleDelete(item)}
        data-toggle="modal"
        data-target="#deleteAddonModal"
      >
        <i className="fa fa-trash popup me-4 trash-small"></i>
      </button>
    </td>
  </tr>
);
