import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  getRestaurantDetails,
  updateBusinessDetails,
} from "../../service/ecommerce.service";
import { useSelector } from "react-redux";
import Loader from "../layout/Loader";


export default function RestSetup() {
  const token = useSelector((state) => state?.status?.token?.token);
  const restaurantId = useSelector((state) => state?.admin?.admin?.restaurant);

  const [restaurantDetails, setRestaurantDetails] = useState([]);
  const [editedImage, setEditedImage] = useState();
  const [updateRestaurantDetails, setUpdateRestaurantDetails] = useState();
  const [isLoading, setIsLoading] = useState(true); // Add loading state

  const fetchRestaurantDetails = () => {
    // setIsLoading(true);
    getRestaurantDetails(token, restaurantId).then((res) => {
      setRestaurantDetails(res.data);
      setUpdateRestaurantDetails(res.data);
      setEditedImage(res.data.restaurant_url);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    window.scrollTo(0, 1);
    fetchRestaurantDetails();
  }, []);


  const {
    register,
    setError,
    clearErrors,
    handleSubmit: handleSubmitUpdate,
    formState: { errors },
  } = useForm();

  const errorMessages = {
    name: {
      message: "Enter Shop Name",
    },
    currency: {
      message: "Enter Currency Type",
    },
    phone: {
      message: "Phone number is required.",
      pattern: {
        value: /^(?:\+91)?[0-9-]{10,14}$/,
        message: "Please enter a valid phone number",
      },
      validate: (value) => {
        const strippedValue = value.replace(/-/g, ""); // Remove dashes
        const dashCount = (value.match(/-/g) || []).length; // Count dashes

        if (dashCount > 2) {
          return "Phone number can contain at most 2 dashes.";
        }

        if (dashCount === 0) {
          return (
            strippedValue.length === 10 || "This is not a valid phone number!"
          );
        } else if (dashCount === 1) {
          return (
            strippedValue.length === 12 || "This is not a valid phone number!"
          );
        } else if (dashCount === 2) {
          return (
            strippedValue.length === 11 || "This is not a valid phone number!"
          );
        }
      },
    },

    email: {
      message: "Email is Required.",
      pattern: {
        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
        message: "Invalid Email Address",
      },
      validate: (value) => value.length > 0 || "This is not valid Email!",
    },
    state: {
      message: "Enter State",
    },
    city: {
      message: "Enter City",
    },
    zip: {
      message: "Enter Zip Code",
      pattern: {
        value: /^(\d{6}(-\d{4})?)?$/,
        message: "Please enter a valid ZIP code",
      },
      validate: (value) => value.length > 0 || "This is not valid Zip Code!",
    },
    country: {
      message: "Enter Country",
    },
    printer_mac: {
      message: "",
    },
    address: {
      message: "Enter Address",
    },
    desc: {
      message: "Enter Description",
    },
    status: {
      message: "Enter Shop Status",
    },
    gst_number: {
      message: "",
    },
    support_email: {
      message: "Enter Support Email",
    },
    support_phone_number: {
      message: "Support Phone Number is required.",
      pattern: {
        value: /^(?:\+91)?[0-9-]{10,14}$/,
        message: "Please enter a valid phone number",
      },
      validate: (value) => {
        const strippedValue = value.replace(/-/g, ""); // Remove dashes
        const dashCount = (value.match(/-/g) || []).length; // Count dashes

        if (dashCount > 2) {
          return "Phone number can contain at most 2 dashes.";
        }

        if (dashCount === 0) {
          return (
            strippedValue.length === 10 || "This is not a valid phone number!"
          );
        } else if (dashCount === 1) {
          return (
            strippedValue.length === 12 || "This is not a valid phone number!"
          );
        } else if (dashCount === 2) {
          return (
            strippedValue.length === 11 || "This is not a valid phone number!"
          );
        }
      },
    },
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    // Check if the value is empty
    if (!value && name !== "printer_mac" && name !== "gst_number") {
      setError(name, { type: "manual", message: errorMessages[name].message });
    } else if (errorMessages[name].pattern) {
      const pattern = errorMessages[name].pattern.value;
      if (!pattern.test(value)) {
        setError(name, {
          type: "manual",
          message: errorMessages[name].pattern.message,
        });
      } else if (errorMessages[name].validate) {
        const validate = errorMessages[name].validate;
        const validationError = validate(value);
        if (validationError) {
          setError(name, { type: "manual", message: validationError });
        } else {
          clearErrors(name);
        }
      }
    } else if (name === "printer_mac" | name === "gst_number") {
      clearErrors(name);
    } else {
      clearErrors(name);
    }

    setUpdateRestaurantDetails({
      ...updateRestaurantDetails,
      [name]: value,
    });
  };


  const updateImage = (event) => {
    const input = event.target;
    if (input.files && input.files[0]) {
      const reader = new FileReader();

      setEditedImage(input.files[0]);

      reader.onload = function (e) {
        // Update the src attribute of the displayed image
        document.getElementById("displayedImage").src = e.target.result;
        // Set the editedImage state with the new image data
      };

      // Read the chosen file as a data URL
      reader.readAsDataURL(input.files[0]);
    }
  };

  const handleUpdateImageClick = () => {
    // Trigger the click event on the file input when the displayed image is clicked
    document.getElementById("imageInputUpdate").click();
  };

  const updateData = () => {
    let data;
    if (typeof editedImage === "string") {
      data = updateRestaurantDetails;
    } else {
      updateRestaurantDetails.image = editedImage;
      data = updateRestaurantDetails;
    }

    updateBusinessDetails(token, data, restaurantId).then((res) => {
      toast.success("Business Details Updated...!", {
        autoClose: 1500,
        style: {
          backgroundColor: "black",
          color: "white",
        },
      });
      fetchRestaurantDetails();
    });
  };

  return (
    <div>
      {isLoading ? (
        // Conditional rendering of loader
        <Loader />
      ) : (
        <div className="page-content ms-3 fade-in-up">
          <ToastContainer position="top-center" className="mt-4" limit={1} />

          <div className="">
            <div className="ms-2 me-3">
              <form
                id="editForm"
                data-form-label="update"
                onSubmit={handleSubmitUpdate(updateData)}
              >
                <div className="modal-content product-modal margin-bottom-20">
                  <div className="modal-header d-flex justify-content-between">
                    <h4 className="modal-title fw-bold fs-5">
                      BUSINESS DETAILS
                    </h4>
                    <button
                      hidden={true}
                      type="button"
                      className={`btn-secondary-post-code btn-post-code`}
                      data-toggle="modal"
                      data-target="#addProductDetailsModal"
                      // onClick={handleConfirm}
                    >
                      ADD POST CODE
                    </button>
                  </div>
                  <div className="modal-body product-data">
                    {/* <p>Some text in the modal.</p> */}
                    {updateRestaurantDetails && (
                      <table className="setup-table">
                        <tbody>
                          <tr>
                            <td>
                              <label>
                                Business Name <i className="fa fa-asterisk"></i>
                              </label>

                              <input
                                value={updateRestaurantDetails.name}
                                {...register("name", {
                                  required: "Enter Shop Name",
                                })}
                                onChange={handleInputChange}
                              />
                              {errors.name && (
                                <p className="text-danger">
                                  {errors.name.message}
                                </p>
                              )}
                            </td>
                            <td>
                              <label>
                                Currency (
                                {updateRestaurantDetails.currency_symbol})
                                <i className="fa fa-asterisk"></i>
                              </label>

                              <input
                                value={updateRestaurantDetails.currency
                                  .toString()
                                  .toUpperCase()}
                                {...register("currency", {
                                  required: "Enter Currency Type",
                                })}
                                onChange={handleInputChange}
                              />
                              {errors.currency && (
                                <p className="text-danger">
                                  {errors.currency.message}
                                </p>
                              )}
                            </td>
                            <td>
                              <label>
                                Phone No.<i className="fa fa-asterisk"></i>
                              </label>

                              <input
                                // type="number"
                                value={updateRestaurantDetails.phone}
                                {...register("phone", {
                                  required: "Enter State",
                                })}
                                onChange={handleInputChange}
                              />
                              {errors.phone && (
                                <p className="text-danger position-absolute">
                                  {errors.phone.message}
                                </p>
                              )}
                            </td>
                            <td>
                              <label>
                                Email <i className="fa fa-asterisk"></i>
                              </label>

                              <input
                                value={updateRestaurantDetails.email}
                                {...register("email", {
                                  required: "Enter Email",
                                })}
                                onChange={handleInputChange}
                              />
                              {errors.email && (
                                <p className="text-danger position-absolute">
                                  {errors.email.message}
                                </p>
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <label>
                                City <i className="fa fa-asterisk"></i>
                              </label>

                              <input
                                value={updateRestaurantDetails.city}
                                {...register("city", {
                                  required: "Enter City",
                                })}
                                onChange={handleInputChange}
                              />
                              {errors.city && (
                                <p className="text-danger position-absolute">
                                  {errors.city.message}
                                </p>
                              )}
                            </td>
                            <td>
                              <label>
                                State <i className="fa fa-asterisk"></i>
                              </label>

                              <input
                                value={updateRestaurantDetails.state}
                                {...register("state", {
                                  required: "Enter State",
                                })}
                                onChange={handleInputChange}
                              />
                              {errors.state && (
                                <p className="text-danger position-absolute">
                                  {errors.state.message}
                                </p>
                              )}
                            </td>
                            <td>
                              <label>
                                Zip <i className="fa fa-asterisk"></i>
                              </label>

                              <input
                                value={updateRestaurantDetails.zip}
                                {...register("zip", {
                                  required: "Enter Zip Code",
                                })}
                                onChange={handleInputChange}
                              />
                              {errors.zip && (
                                <p className="text-danger position-absolute">
                                  {errors.zip.message}
                                </p>
                              )}
                            </td>
                            <td>
                              <label>
                                Country <i className="fa fa-asterisk"></i>
                              </label>

                              <input
                                value={updateRestaurantDetails.country}
                                {...register("country", {
                                  required: "Enter Zip Code",
                                })}
                                onChange={handleInputChange}
                              />
                              {errors.country && (
                                <p className="text-danger position-absolute">
                                  {errors.country.message}
                                </p>
                              )}
                            </td>
                          </tr>

                          <tr>
                            <td>
                              <label>
                                Status <i className="fa fa-asterisk"></i>
                              </label>

                              <select
                                value={updateRestaurantDetails.status}
                                {...register("status", {
                                  required: "Enter Shop Status",
                                })}
                                onChange={handleInputChange}
                              >
                                <option value="ACTIVE">ACTIVE</option>
                                <option value="INACTIVE">INACTIVE</option>
                              </select>
                              {errors.status && (
                                <p className="text-danger">
                                  {errors.status.message}
                                </p>
                              )}
                            </td>

                            <td>
                              <label>
                                GST Number 
                                {/* <i className="fa fa-asterisk"></i> */}
                              </label>

                              <input
                                value={updateRestaurantDetails.gst_number}
                                {...register("gst_number"
                                //   , {
                                //   required: "Enter GST Number",
                                // }
                              )}
                                onChange={handleInputChange}
                              />
                              {errors.gst_number && (
                                <p className="text-danger">
                                  {errors.gst_number.message}
                                </p>
                              )}
                            </td>
                            <td>
                              <label>
                                Support Phone No. <i className="fa fa-asterisk"></i>
                              </label>

                              <input
                                // type="number"
                                value={updateRestaurantDetails.support_phone_number}
                                {...register("support_phone_number", 
                                //   {
                                //   required: "Enter Support Phone Number",
                                // }
                              )}
                                onChange={handleInputChange}
                              />
                              {errors.support_phone_number && (
                                <p className="text-danger position-absolute">
                                  {errors.support_phone_number.message}
                                </p>
                              )}
                            </td>
                            <td>
                              <label>
                              Support Email <i className="fa fa-asterisk"></i>
                              </label>

                              <input
                                value={updateRestaurantDetails.support_email}
                                {...register("support_email", 
                                //   {
                                //   required: "Enter Support Email",
                                // }
                              )}
                                onChange={handleInputChange}
                              />
                              {errors.support_email && (
                                <p className="text-danger position-absolute">
                                  {errors.support_email.message}
                                </p>
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <label>
                                Shop Icon <i className="fa fa-asterisk"></i>
                              </label>

                              {/* Hidden file input */}
                              <input
                                type="file"
                                id="imageInputUpdate"
                                accept="image/*"
                                style={{ display: "none" }}
                                onChange={updateImage}
                              />
                              {/* Displayed image */}
                              <div>
                                <img
                                  src={editedImage}
                                  id="displayedImage"
                                  alt="product_url"
                                  onClick={() => handleUpdateImageClick()}
                                />
                              </div>
                            </td>
                            <td>
                              <label>
                                Mac Printer
                                {/* <i className="fa fa-asterisk"></i> */}
                              </label>

                              <input
                                value={updateRestaurantDetails.printer_mac}
                                {...register(
                                  "printer_mac"
                                  //   , {
                                  //   required: "Enter Printer Mac Address",
                                  // }
                                )}
                                onChange={handleInputChange}
                              />
                              {errors.printer_mac && (
                                <p className="text-danger position-absolute">
                                  {errors.printer_mac.message}
                                </p>
                              )}
                            </td>
                            <td>
                              <label>
                                Address <i className="fa fa-asterisk"></i>
                              </label>

                              <textarea
                                value={updateRestaurantDetails.address}
                                {...register("address", {
                                  required: "Enter Address",
                                })}
                                onChange={handleInputChange}
                              />
                              {errors.address && (
                                <p className="text-danger position-absolute textarea">
                                  {errors.address.message}
                                </p>
                              )}
                            </td>
                            <td>
                              <label>
                                About Us <i className="fa fa-asterisk"></i>
                              </label>

                              <textarea
                                value={updateRestaurantDetails.desc}
                                {...register("desc", {
                                  required: "Write down Description",
                                })}
                                onChange={handleInputChange}
                              />
                              {errors.desc && (
                                <p className="text-danger position-absolute textarea">
                                  {errors.desc.message}
                                </p>
                              )}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    )}
                  </div>
                  <div className="modal-footer">
                    <button
                      type="submit"
                      className="btn btn-default"
                      // data-dismiss="modal"
                      // onClick={updateData}
                    >
                      UPDATE DETAILS
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
