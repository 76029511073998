import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { getOrdersByStatus } from "../../../service/ecommerce.service";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Loader from "../../layout/Loader";

export default function DeliveredOrders() {
  let navigate = useNavigate();
  const [deliveredOrdersData, setDeliveredOrdersData] = useState([]);
  const [count, setCount] = useState(null);
  const token = useSelector((state) => state.status.token.token);
  const restaurantId = useSelector((state) => state.admin.admin.restaurant);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true); // Add loading state
  

  const fetchDeliveredOrders = () => {
    setIsLoading(true);
    getOrdersByStatus(token, page, restaurantId, "DELIVERED").then((res) => {
      setDeliveredOrdersData(res.data.results);
      setCount(res.data.count);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    window.scrollTo(0, 1);
    fetchDeliveredOrders();
    // eslint-disable-next-line
  }, []);

  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 10;

  const startIndex = currentPage * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const handleOrderDetails = (item) => {
    // console.log(item.order.order_id);
    navigate("/order-details", {
      state: {
        orderDetails: item,
        hideAll: true,
        hidden: true,
        disabled: true,
      },
    });
  };

  const handleTrackOrder = (item) => {
    console.log(item.order.order_id);
    navigate("/track-order", {
      state: {
        orderDetails: item,
      },
    });
  };

  return (
    <div>
      <div className="ibox-body m-3">
        {isLoading ? ( // Conditional rendering of loader
          <Loader />
        ) : (
          <table
            className="table table-striped table-bordered table-hover"
            // id="example-table"
            cellSpacing="0"
            width="100%"
          >
            <thead>
              <tr>
                <th>Order No.</th>
                <th>Name</th>
                <th>Date</th>
                <th>Address</th>
                <th>Amount</th>
                <th>Status</th>
              </tr>
            </thead>

            <tbody>
              {deliveredOrdersData &&
                deliveredOrdersData.map((item, i) => (
                  <tr key={i} className="tr">
                    <td
                      className="orderid"
                      onClick={() => handleOrderDetails(item)}
                    >
                      {item.order.order_id}
                    </td>
                    <td>{item.order.customer.customer.first_name ? item.order.customer.customer.first_name : "Guest"}{" "}{item.order.customer.customer.last_name}</td>
                    <td>
                      {new Date(item.order.created_at)
                        .toLocaleDateString("en-US", {
                          day: "numeric",
                          month: "short",
                          year: "numeric",
                        })
                        .replace(/(\d+) (\w+), (\d+)/, "$1 $2 $3")}
                    </td>
                    <td className="text-start">
                      {item.order.billing_address_text}
                    </td>
                    <td>
                      <i className="fa fa-inr"></i>
                      {/* {item.order.total} */}
                      {(item.order.subtotal - item.order.discount).toLocaleString("en-IN", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                    </td>
                    <td
                      className="orderid"
                      onClick={() => handleTrackOrder(item)}
                    >
                      DELIVERED
                    </td>                  </tr>
                ))}
            </tbody>
          </table>
        )}

        {count > itemsPerPage ? (
          <div className="d-flex justify-content-between my-3">
            <div className="fw-light">
              Showing{" "}
              {deliveredOrdersData.length > 0 ? startIndex + 1 : startIndex} to{" "}
              {Math.min(endIndex, count)} of {count} entries
            </div>
            <div>
              <ReactPaginate
                previousLabel={
                  <button
                    type="button"
                    className="btn btn-outline-secondary w-100"
                  >
                    Previous
                  </button>
                }
                nextLabel={
                  <button
                    type="button"
                    className="btn btn-outline-secondary w-100"
                  >
                    Next
                  </button>
                }
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={Math.ceil(count / itemsPerPage)}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={({ selected }) => {
                  setPage(selected + 1);
                  setCurrentPage(selected);
                  setIsLoading(true);
                  getOrdersByStatus(
                    token,
                    selected + 1,
                    restaurantId,
                    "DELIVERED"
                  ).then((res) => {
                    setDeliveredOrdersData(res.data.results);
                    setIsLoading(false);
                  });
                }}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={
                  "active-class btn btn-outline-secondary w-100 fw-bold"
                }
                pageClassName={"page-class btn btn-outline-secondary w-100"}
              />
            </div>
          </div>
        ) : (
          <div></div>
        )}
      </div>
    </div>
  );
}
