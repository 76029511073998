import axios from "axios";

export const getMainCategoryList = async (token, id) => {
  const url = process.env.REACT_APP_API_URL + `get-master-category/`+id+'/';
  console.log('main-url',url)

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `token ${token}`,
    },
  };

  try {
    const response = await axios.get(url, config);
    return response;
  } catch (error) {
    if (
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500
    ) {
      console.error("Unauthorized Access.", error);
    } else {
      console.error(error);
      throw error;
    }
  }
};


export const addMainCategory = async (token, categoryData, openHoursData) => {
  // API URL for adding the main category
  const categoryUrl = `${process.env.REACT_APP_API_URL}/get-master-category/`;

  try {
    // 1. API call to add the main category
    const categoryResponse = await axios.post(categoryUrl, categoryData, {
      headers: {
        Authorization: `Token ${token}`,
        "Content-Type": "application/json",
      },
    });

    // Check if category was added successfully
    if (categoryResponse.data.master_category_id) {
      const categoryId = categoryResponse.data.master_category_id;

      // 2. Prepare the open hours data
      const openHoursUrl = `${process.env.REACT_APP_API_URL}/open-hours/`;

      const openHoursRequestData = {
        open_hours: JSON.stringify(openHoursData), // Send the openHoursData as a JSON string
        status: categoryData.status, // Status of the category
        master_category: categoryId, // Link the open hours to the master category ID
      };

      // 3. API call to set the operation hours
      const openHoursResponse = await axios.post(openHoursUrl, openHoursRequestData, {
        headers: {
          Authorization: `Token ${token}`,
          "Content-Type": "application/json",
        },
      });
      // Check if the open hours were set successfully
      if (openHoursResponse.status === 200) {
        return { success: true, message: "Main Category and hours added successfully" };
      } else {
        throw new Error("Failed to set operation hours");
      }
    } else {
      throw new Error("Failed to add main category");
    }
  } catch (error) {
    console.error("Error adding main category or hours:", error);
    return { success: false, message: error.message || "An error occurred" };
  }
};

  export const updateMainCat = async (token, categoryData, openHoursData, categoryId) => {
    const categoryUrl = `${process.env.REACT_APP_API_URL}/get-master-category/${categoryId}/`;
  
    try {
      // Step 1: Update Main Category
      const categoryResponse = await axios.put(categoryUrl, categoryData, {
        headers: {
          Authorization: `Token ${token}`,
          "Content-Type": "application/json",
        },
      });
  
      if (categoryResponse.data?.payload?.master_category_id) {
        const updatedCategoryId = categoryResponse.data.payload.master_category_id;
        const openHoursUrl = `${process.env.REACT_APP_API_URL}open-hours/${updatedCategoryId}/`;
  
        const openHoursRequestData = {
          open_hours: JSON.stringify(openHoursData), // Send as an object, not a string
          status: categoryData.status,
          master_category: updatedCategoryId,
        };
  
        const openHoursResponse = await axios.patch(openHoursUrl, openHoursRequestData, {
          headers: {
            Authorization: `Token ${token}`,
            "Content-Type": "application/json",
          },
        });
  
        // Check if the open hours were updated successfully
        if (openHoursResponse.data?.message === "data updated successfully") {
          return { success: true, message: "Main Category and hours updated successfully" };
        } else {
          throw new Error("Failed to update operation hours");
        }
      } else {
        throw new Error("Failed to update main category");
      }
    } catch (error) {
      console.error("Error updating main category or hours:", error);
      return { success: false, message: error.message || "An error occurred" };
    }
  };
  

export const getMainCat = async (catId, token) => {
  try {
    const url = `${process.env.REACT_APP_API_URL}/get-master-category/?master_category_id=${catId}`;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Token ${token}`,
    };

    const response = await axios.get(url, { headers });

    return response.data;  // Return the actual data
  } catch (error) {

    return null;  // Return null if error occurs
  }
};

export const getCategorySizes = async(token) => {
  const url = `${process.env.REACT_APP_API_URL}/categories-size-x/`;
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `token ${token}`,
    },
  };

  try {
    const response = await axios.get(url, config);
    return response;
  } catch (error) {
    if (
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500
    ) {
      console.log("Unauthorized Access.", error);
    } else {
      console.error(error);
      throw error;
    }
  }
}

export const addCategorySizes = async(token, data) => {
  const url = process.env.REACT_APP_API_URL + `category-size-x/`;

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `token ${token}`,
      
    },
  };

  try {
    const response = await axios.post(url, data, config);
    return response;
  } catch (error) {
    if (
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500
    ) {
      console.error("Unauthorized Access.", error);
    } else {
      console.error(error);
      throw error;
    }
  }
}

export const updateCategorySizeData = async(token, data, category_size_id) => {
  const url = process.env.REACT_APP_API_URL + `category-size-x/${category_size_id}/`;

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `token ${token}`,
      
    },
  };

  try {
    const response = await axios.put(url, data, config);
    return response;
  } catch (error) {
    if (
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500
    ) {
      console.error("Unauthorized Access.", error);
    } else {
      console.error(error);
      throw error;
    }
  }
}

export const deleteCategorySizeData = async (token, category_size_id) => {
  const url = `${process.env.REACT_APP_API_URL}category-size-x/${category_size_id}/`;
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `token ${token}`,
    },
  };

  try {
    const response = await axios.delete(url, config);
    return response;
  } catch (error) {
    if (
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500
    ) {
      console.log("Unauthorized Access.", error);
    } else {
      console.error(error);
      throw error;
    }
  }
};

export const getAddons = async(token) => {
  const url = `${process.env.REACT_APP_API_URL}addon-x/`;
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `token ${token}`,
    },
  };

  try {
    const response = await axios.get(url, config);
    return response;
  } catch (error) {
    if (
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500
    ) {
      console.log("Unauthorized Access.", error);
    } else {
      console.error(error);
      throw error;
    }
  }
}

export const addAddonData = async(token, data) => {
  const url = process.env.REACT_APP_API_URL + `addon-x/`;

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `token ${token}`,
    },
  };

  try {
    const response = await axios.post(url, data, config);
    return response;
  } catch (error) {
    if (
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500
    ) {
      console.error("Unauthorized Access.", error);
    } else {
      console.error(error);
      throw error;
    }
  }
}

export const updateAddonData = async(token, data, addon_id) => {
  const url = process.env.REACT_APP_API_URL + `addon-x/${addon_id}/`;

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `token ${token}`,
      
    },
  };

  try {
    const response = await axios.put(url, data, config);
    return response;
  } catch (error) {
    if (
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500
    ) {
      console.error("Unauthorized Access.", error);
    } else {
      console.error(error);
      throw error;
    }
  }
}

export const deleteAddonData = async (token, addon_id) => {
  const url = `${process.env.REACT_APP_API_URL}addon-x/${addon_id}/`;
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `token ${token}`,
    },
  };

  try {
    const response = await axios.delete(url, config);
    return response;
  } catch (error) {
    if (
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500
    ) {
      console.log("Unauthorized Access.", error);
    } else {
      console.error(error);
      throw error;
    }
  }
};

export const getWorkingHours = async(token, restaurentId, page) => {
  const url = `${process.env.REACT_APP_API_URL}nonhour/?display=true&restaurant_id=${restaurentId}&page=${page}`;
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `token ${token}`,
    },
  };

  try {
    const response = await axios.get(url, config);
    return response;
  } catch (error) {
    if (
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500
    ) {
      console.log("Unauthorized Access.", error);
    } else {
      console.error(error);
      throw error;
    }
  }
}

export const addWorkingHours = async(token, data) => {
  const url = process.env.REACT_APP_API_URL + `nonhour/`;

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `token ${token}`,
    },
  };

  try {
    const response = await axios.post(url, data, config);
    return response;
  } catch (error) {
    if (
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500
    ) {
      console.error("Unauthorized Access.", error);
    } else {
      console.error(error);
      throw error;
    }
  }
}

export const deleteWorkingHoursData = async (token, hour_id) => {
  const url = `${process.env.REACT_APP_API_URL}nonhour/${hour_id}/`;
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `token ${token}`,
    },
  };

  try {
    const response = await axios.delete(url, config);
    return response;
  } catch (error) {
    if (
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500
    ) {
      console.log("Unauthorized Access.", error);
    } else {
      console.error(error);
      throw error;
    }
  }
};

export const updateWorkingHours = async(token, data, hour_id) => {
  const url = process.env.REACT_APP_API_URL + `nonhour/${hour_id}/`;

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `token ${token}`,
      
    },
  };

  try {
    const response = await axios.put(url, data, config);
    return response;
  } catch (error) {
    if (
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500
    ) {
      console.error("Unauthorized Access.", error);
    } else {
      console.error(error);
      throw error;
    }
  }
}