import React, { useState, useEffect } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
import { getOrdersByStatus } from "../../service/ecommerce.service";
import { useSelector } from "react-redux";
import Loader from "../layout/Loader";

export default function Orders() {
  const token = useSelector((state) => state.status.token.token);
  const restaurantId = useSelector((state) => state.admin.admin.restaurant);
  const [isLoading, setIsLoading] = useState(true); // Add loading state

  const location = useLocation();
  const [activeTab, setActiveTab] = useState("newOrders");
  const page = 1;

  const fetchOrders = () => {
    getOrdersByStatus(token, page, restaurantId, "ORDER_PLACED").then((res) => {
      setNewCount(res.data.count);
    });
    getOrdersByStatus(token, page, restaurantId, "DELIVERED").then((res) => {
      setDeliveredCount(res.data.count);
    });
    getOrdersByStatus(token, page, restaurantId, "Canceled").then((res) => {
      setCancelCount(res.data.count);
    });
    getOrdersByStatus(token, page, restaurantId, "CONFIRMED").then((res) => {
      setReadyCount(res.data.count);
      setIsLoading(false);
    });
  };

  // useEffect(() => {

  //   // Set the active tab based on the current location pathname
  //   const path = location.pathname;
  //   setActiveTab(getTabFromPath(path));
  //   fetchOrders();
  //   // eslint-disable-next-line

  // }, [location.pathname]);

  useEffect(() => {
    window.scrollTo(0, 1);
    // Set the active tab based on the current location pathname
    const path = location.pathname;
    setActiveTab(getTabFromPath(path));

    // Fetch orders
    fetchOrders();

    // eslint-disable-next-line
  }, [location.pathname]);

  const getTabFromPath = (path) => {
    if (path.includes("/orders/new")) return "newOrders";
    if (path.includes("/orders/ready")) return "acceptedOrders";
    if (path.includes("/orders/delivered")) return "deliveredOrders";
    if (path.includes("/orders/canceled")) return "cancelledOrders";
    return "";
  };

  const [newCount, setNewCount] = useState(null);
  const [readyCount, setReadyCount] = useState(null);
  const [deliveredCount, setDeliveredCount] = useState(null);
  const [cancelCount, setCancelCount] = useState(null);

  // useEffect(() => {
  // }, []);

  return (
    <div>
      {isLoading ? ( // Conditional rendering of loader
        <Loader />
      ) : (
        <div>
          {/* <!-- START PAGE CONTENT--> */}

          <div className="page-content ms-3 fade-in-up">
            <div className="ibox">
              <div className="ibox-head">
                <Link
                  to="/orders/new"
                  // onClick={() => setActiveTab("newOrders")}
                >
                  <div
                    className={`ibox-title me-5 ${
                      activeTab === "newOrders" ? "active" : ""
                    }`}
                  >
                    NEW
                    <span className="translate-middle badge rounded-pill badge-notification">
                      {newCount > 99 ? "99+" : newCount}
                      <span className="visually-hidden">unread messages</span>
                    </span>
                  </div>
                </Link>
                <Link
                  to="/orders/ready"
                  // onClick={() => setActiveTab("acceptedOrders")}
                >
                  <div
                    className={`ibox-title me-5 ${
                      activeTab === "acceptedOrders" ? "active" : ""
                    }`}
                  >
                    READY
                    <span className="translate-middle badge rounded-pill badge-notification">
                      {readyCount > 99 ? "99+" : readyCount}
                      <span className="visually-hidden">unread messages</span>
                    </span>
                  </div>
                </Link>
                <Link
                  to="/orders/delivered"
                  // onClick={() => setActiveTab("deliveredOrders")}
                >
                  <div
                    className={`ibox-title me-5 ${
                      activeTab === "deliveredOrders" ? "active" : ""
                    }`}
                  >
                    DELIVERED
                    <span className="translate-middle badge rounded-pill badge-notification">
                      {deliveredCount > 99 ? "99+" : deliveredCount}
                      <span className="visually-hidden">unread messages</span>
                    </span>
                  </div>
                </Link>
                <Link
                  to="/orders/canceled"
                  //  onClick={() => setActiveTab("cancelledOrders")}
                >
                  <div
                    className={`ibox-title me-5 ${
                      activeTab === "cancelledOrders" ? "active" : ""
                    }`}
                  >
                    CANCELED
                    <span className="translate-middle badge rounded-pill badge-notification">
                      {cancelCount > 99 ? "99+" : cancelCount}
                      <span className="visually-hidden">unread messages</span>
                    </span>
                  </div>
                </Link>
              </div>

              <Outlet />
            </div>
          </div>

          {/* <!-- BEGIN PAGA BACKDROPS--> */}
          <div className="sidenav-backdrop backdrop"></div>
          {/* <div className="preloader-backdrop">
          <div className="page-preloader">Loading</div>
        </div> */}
          {/* <!-- END PAGA BACKDROPS--> */}
        </div>
      )}
    </div>
  );
}
