import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { getMainCat, updateMainCat } from "../../../service/restAdmin.service";

const UpdateMainCategory = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const restaurantId = useSelector((state) => state?.admin?.admin?.restaurant);
  const token = useSelector((state) => state.status.token.token);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const [isLoading, setIsLoading] = useState(false);
  const [CategoryId,setCategoryId] = useState();

  const getmaincategorydata = async (id) => {
    try {
      setIsLoading(true);
      const res = await getMainCat(id, token);
      if (res && res.master_category_id) {
        
        reset({
          categoryName: res.name,
          catStatus: res.status ? 'True' : 'False', 
          startTimeMonday: res.Master_Category_Open_hours.find(item => item.day === 'Monday')?.start_time || '',
          endTimeMonday: res.Master_Category_Open_hours.find(item => item.day === 'Monday')?.end_time || '',
          statusMonday: res.Master_Category_Open_hours.find(item => item.day === 'Monday')?.status ? 'True' : 'False',
          
          startTimeTuesday: res.Master_Category_Open_hours.find(item => item.day === 'Tuesday')?.start_time || '',
          endTimeTuesday: res.Master_Category_Open_hours.find(item => item.day === 'Tuesday')?.end_time || '',
          statusTuesday: res.Master_Category_Open_hours.find(item => item.day === 'Tuesday')?.status ? 'True' : 'False',
  
          startTimeWednesday: res.Master_Category_Open_hours.find(item => item.day === 'Wednesday')?.start_time || '',
          endTimeWednesday: res.Master_Category_Open_hours.find(item => item.day === 'Wednesday')?.end_time || '',
          statusWednesday: res.Master_Category_Open_hours.find(item => item.day === 'Wednesday')?.status ? 'True' : 'False',
  
          startTimeThursday: res.Master_Category_Open_hours.find(item => item.day === 'Thursday')?.start_time || '',
          endTimeThursday: res.Master_Category_Open_hours.find(item => item.day === 'Thursday')?.end_time || '',
          statusThursday: res.Master_Category_Open_hours.find(item => item.day === 'Thursday')?.status ? 'True' : 'False',
  
          startTimeFriday: res.Master_Category_Open_hours.find(item => item.day === 'Friday')?.start_time || '',
          endTimeFriday: res.Master_Category_Open_hours.find(item => item.day === 'Friday')?.end_time || '',
          statusFriday: res.Master_Category_Open_hours.find(item => item.day === 'Friday')?.status ? 'True' : 'False',
  
          startTimeSaturday: res.Master_Category_Open_hours.find(item => item.day === 'Saturday')?.start_time || '',
          endTimeSaturday: res.Master_Category_Open_hours.find(item => item.day === 'Saturday')?.end_time || '',
          statusSaturday: res.Master_Category_Open_hours.find(item => item.day === 'Saturday')?.status ? 'True' : 'False',
  
          startTimeSunday: res.Master_Category_Open_hours.find(item => item.day === 'Sunday')?.start_time || '',
          endTimeSunday: res.Master_Category_Open_hours.find(item => item.day === 'Sunday')?.end_time || '',
          statusSunday: res.Master_Category_Open_hours.find(item => item.day === 'Sunday')?.status ? 'True' : 'False',
        });
      } else {
        toast.error("Failed to fetch category data");
      }
    } catch (error) {
      toast.error("Error fetching category data");
    } finally {
      setIsLoading(false);
    }
  };
  

  useEffect(() => {
    const initialMainCategory = { ...location.state.businessAdminDetails };
    getmaincategorydata(initialMainCategory.master_category_id);
    setCategoryId(initialMainCategory.master_category_id)

  }, [location.state]);

  const onSubmit = async (data) => {
    setIsLoading(true);
  
    const categoryData = {
      restaurant_id: restaurantId,
      name: data.categoryName,
      status: data.catStatus,
    };
  
    // Prepare open hours data
    const openHoursData = {
      Monday: `${data.startTimeMonday}@${data.endTimeMonday}@${data.statusMonday}`,
      Tuesday: `${data.startTimeTuesday}@${data.endTimeTuesday}@${data.statusTuesday}`,
      Wednesday: `${data.startTimeWednesday}@${data.endTimeWednesday}@${data.statusWednesday}`,
      Thursday: `${data.startTimeThursday}@${data.endTimeThursday}@${data.statusThursday}`,
      Friday: `${data.startTimeFriday}@${data.endTimeFriday}@${data.statusFriday}`,
      Satday: `${data.startTimeSaturday}@${data.endTimeSaturday}@${data.statusSaturday}`,
      Sunday: `${data.startTimeSunday}@${data.endTimeSunday}@${data.statusSunday}`,
    };
    
  
    try {
      const result = await updateMainCat(token, categoryData, openHoursData, CategoryId);
  
      if (result.success) {
        reset();
        toast.success(`Main Category Updated Successfully...!`, {
          autoClose: 1500,
          style: {
            backgroundColor: "black",
            color: "white",
          },
        });
        setTimeout(() => {
          navigate("/main-category");
        }, 1500);
      } else {
        toast.error(result.message);
      }
    } catch (error) {
      toast.error("Something went wrong.");
    } finally {
      setIsLoading(false);
    }
  };
  

  return (
    <div className="container mt-4">
      <ToastContainer position="top-center" limit={1} />
      <div className="ibox">
        <div className="product-modal p-0">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-card">
          <div className="modal-header">
            <h4 className="form-title">Update Main Category</h4>
            <button
              type="button"
              className="close"
              onClick={() => window.history.back()}
            >
              &times;
            </button>
          </div>

          <div className="form-body p-3">
            <div className="row">
              {/* Category Name */}
              <div className="col-md-6">
                <div className="form-group">
                  <label>Category Name <span className="text-danger">*</span></label>
                  <input
                    {...register("categoryName", {
                      required: "Category name is required",
                      pattern: {
                        value: /\S(.*\S)?/,
                        message: "Category name cannot be empty or spaces",
                      },
                    })}
                    type="text"
                    className="form-control"
                    placeholder="Enter Category Name"
                  />
                  {errors.categoryName && (
                    <p className="text-danger">{errors.categoryName.message}</p>
                  )}
                </div>
              </div>

              {/* Category Status */}
              <div className="col-md-6">
                <div className="form-group">
                  <label>Status <span className="text-danger">*</span></label>
                  <select
                    {...register("catStatus", { required: "Status is required" })}
                    className="form-control"
                  >
                    <option value="True">True</option>
                    <option value="False">False</option>
                  </select>
                  {errors.catStatus && <p className="text-danger">{errors.catStatus.message}</p>}
                </div>
              </div>
            </div>

            {/* Weekdays with Start Time, End Time, Status */}
            {["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"].map((day, index) => (
              <div key={index} className="row">
                <div className="col-md-3">
                  <div className="form-group">
                    <label>{day}</label>
                    <input
                      type="text"
                      className="form-control"
                      value={day}
                      readOnly
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label>Start Time</label>
                    <input
                      {...register(`startTime${day}`, { required: "Start time is required" })}
                      type="time"
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label>End Time</label>
                    <input
                      {...register(`endTime${day}`, { required: "End time is required" })}
                      type="time"
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label>Status</label>
                    <select
                      {...register(`status${day}`, { required: "Status is required" })}
                      className="form-control"
                    >
                      <option value="True">True</option>
                      <option value="False">False</option>
                    </select>
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div className="form-footer text-center mb-5">
            <button type="submit" className="btn btn-default col-4 " disabled={isLoading}>
              {isLoading ? "Updating..." : "Update Main Category"}
            </button>
          </div>
        </div>
      </form>
      </div>
      </div>
    </div>
  );
};

export default UpdateMainCategory;
