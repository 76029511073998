import React from "react";

const Loader = () => {
  return (
    // <div className="loader-container">
    //   <img src="/assets/img/favicon.png" alt="Loading..." className="loader-image" />
    // </div>
    // Inside your JSX
    <div className="loader-wrapper">
      <div className="loader-container">
        <div className="loader-overlay">
          <div className="loader-spinner"></div>
        </div>
        <img
          src="/assets/img/favicon.png"
          alt="Loading..."
          className="loader-image"
        />
      </div>
    </div>
  );
};

export default Loader;
