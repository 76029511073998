import React, { useEffect, useState } from "react";
import Loader from "../../layout/Loader";
import { ToastContainer, toast } from "react-toastify";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import ReactPaginate from "react-paginate";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { getSubCategory } from "../../../service/ecommerce.service";
import {
  addCategorySizes,
  deleteCategorySizeData,
  getCategorySizes,
  updateCategorySizeData,
} from "../../../service/restAdmin.service";
import { useForm } from "react-hook-form";

export default function CategorySizes() {
  const navigate = useNavigate();
  const token = useSelector((state) => state?.status?.token?.token);
  const restaurantId = useSelector((state) => state?.admin?.admin?.restaurant);

  const [isLoading, setIsLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [subCatData, setSubCatData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [count, setCount] = useState(0);
  const [isButtonActive, setIsButtonActive] = useState(false);
  const [deletedItem, setDeletedItem] = useState(null);
  const [subCategoryData, setSubCategoryData] = useState(null);
  const [catSizeData, setCatSizeData] = useState(null);
  const [editedItem, setEditedItem] = useState(null);
  const [subcategoryModalShow, setSubcategoryModalShow] = useState(false);
  const [categoryId, setCategoryId] = useState(null);
  const [size, setSize] = useState(null);
  // Fetch the list Data - category list & category sizes list
  const fetchCategorySize = () => {
    setIsLoading(true);
    getSubCategory(token, restaurantId).then((res) => {
      setSubCategoryData(res.data);
      setIsLoading(false);
    });
    getCategorySizes(token).then((res) => {
      // const filterSizeData = res?.data?.find(
      //   (item) => item?.category?.restaurant_id === restaurantId
      // );

      const filterSizeData = res?.data?.filter(
        (item) => item?.category?.restaurant_id === restaurantId
      );

      const sortData = filterSizeData.sort((a,b) => b.category_size_id - a.category_size_id);
      setCatSizeData(sortData);
      setCount(sortData?.length);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    window.scrollTo(0, 1);
    fetchCategorySize();
  }, []);

  // Handle drag-and-drop reordering
  const handleDragEnd = (result) => {
    if (!result.destination) return;
    const updatedData = [...catSizeData];
    const [reorderedItem] = updatedData.splice(result.source.index, 1);
    updatedData.splice(result.destination.index, 0, reorderedItem);
    setCatSizeData(updatedData);
  };

  const toggleButton = () => {
    reset1({ category: "", size: "" });
    setIsButtonActive(!isButtonActive);
  };

  // Filter categories based on search query with safe checks
  const filteredCategorySize = catSizeData?.filter((item) => {
    const categoryName = item?.category?.category?.toLowerCase() || "";
    const size = item?.size?.toLowerCase() || "";
    const query = searchQuery.trim().toLowerCase();
  
    return categoryName.includes(query) || size.includes(query);
  }) || [];

  // Pagination logic
  const startIndex = currentPage * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const paginatedData = filteredCategorySize?.slice(startIndex, endIndex);
  // For Add
  const {
    register: register1,
    handleSubmit: handleSubmitAdd,
    reset: reset1,
    setError: setError1,
    formState: { errors: errors1 },
  } = useForm();

  const addData = (item) => {
    const data = {
      category: item.category,
      size: item.size,
    };
    addCategorySizes(token, data).then((res) => {
      toast.success("Category Size Added...!", {
        autoClose: 1500,
        style: {
          backgroundColor: "black",
          color: "white",
        },
      });
      document.getElementById("closeAddModal").click();
      fetchCategorySize();
    });
    reset1({ category: "", size: "" });
  };

  // Function to handle editing
  const handleEdit = (item) => {
    setEditedItem(item);
    setSize(item.size);
    setCategoryId(item.category.category_id);
    clearErrors();
  };

  const handleDelete = (item) => {
    setDeletedItem(item);
  };

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  const {
    register,
    handleSubmit: handleSubmitUpdate,
    formState: { errors },
    clearErrors,
  } = useForm();

  const updateData = (item) => {
    const data = {
      category: categoryId,
      size: item.size,
    };
    updateCategorySizeData(token, data, editedItem.category_size_id).then(
      (res) => {
        toast.success("Category Size Updated...!", {
          autoClose: 1500,
          style: {
            backgroundColor: "black",
            color: "white",
          },
        });
        document.getElementById("closeUpdateModal").click();
        fetchCategorySize();
      }
    );
    fetchCategorySize();
  };

  const handleCategoryChange = (event) => {
    const catId = event.target.value;
    setCategoryId(catId);
  };

  const handleSizeChange = (e) => {
    setSize(e.target.value)
  }

  // delete form
  const {
    register: register2,
    handleSubmit: handleSubmitDelete,
    watch,
    reset: reset2,
    formState: { errors: errors2 },
  } = useForm();

  const deleteData = () => {
    deleteCategorySizeData(token, deletedItem.category_size_id).then((res) => {
      toast.error("Category Size Deleted...!", {
        autoClose: 1500,
        style: {
          backgroundColor: "black",
          color: "white",
        },
      });
      document.getElementById("closeDeleteModal").click();
      fetchCategorySize();
    });
  };

  return (
    <div>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="page-content ms-3 fade-in-up">
          <ToastContainer position="top-center" className="mt-4" limit={1} />
          <div className="ibox">
            <div className="ibox-body m-3">
              <div
                className={`sticky-button ${isButtonActive ? "active" : ""}`}
                onClick={() => toggleButton()}
                data-toggle="modal"
                data-target="#addSubCategoryModal"
              >
                <i className="fa fa-plus"></i>
              </div>
              <div className="d-flex justify-content-between my-3">
                <div>
                  Show{" "}
                  <select
                    value={itemsPerPage}
                    onChange={(e) =>
                      setItemsPerPage(parseInt(e.target.value, 10))
                    }
                  >
                    {[10, 20, 30, 40, 50, 100].map((num) => (
                      <option key={num} value={num}>
                        {num}
                      </option>
                    ))}
                  </select>{" "}
                  Results
                </div>
                <div>
                  Search:{" "}
                  <input
                    type="text"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    className="btn-outline-secondary"
                  />
                </div>
              </div>

              <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId="categories">
                  {(provided) => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                      <table className="table table-striped table-bordered table-hover">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Category Name</th>
                            <th>Size</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {paginatedData?.map((item, index) => (
                            <Draggable
                              key={
                                item.category_size_id || `draggable-${index}`
                              }
                              draggableId={
                                item.category_size_id
                                  ? item.category_size_id.toString()
                                  : `draggable-${index}`
                              }
                              index={index}
                            >
                              {(provided) => (
                                <TableRow
                                  provided={provided}
                                  index={startIndex + index}
                                  item={item}
                                  handleEdit={handleEdit}
                                  handleDelete={handleDelete}
                                />
                              )}
                            </Draggable>
                          ))}
                        </tbody>
                      </table>
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
              {count > itemsPerPage && (
                <div className="d-flex justify-content-between my-3">
                  <div className="fw-light">
                    Showing{" "}
                    {filteredCategorySize.length > 0
                      ? startIndex + 1
                      : startIndex}{" "}
                    to {Math.min(endIndex, filteredCategorySize.length)} of{" "}
                    {filteredCategorySize.length} entries
                  </div>
                  <div>
                    {filteredCategorySize.length > itemsPerPage && (
                      <ReactPaginate
                        previousLabel={
                          <button
                            type="button"
                            className="btn btn-outline-secondary w-100"
                          >
                            Previous
                          </button>
                        }
                        nextLabel={
                          <button
                            type="button"
                            className="btn btn-outline-secondary w-100"
                          >
                            Next
                          </button>
                        }
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={Math.ceil(
                          filteredCategorySize.length / itemsPerPage
                        )}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={handlePageChange}
                        containerClassName={"pagination"}
                        subContainerClassName={"pages pagination"}
                        activeClassName={
                          "active-class btn btn-outline-secondary w-100 fw-bold"
                        }
                        pageClassName={
                          "page-class btn btn-outline-secondary w-100"
                        }
                      />
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      {/* <!-- Add Modal --> */}

      <div className="container ">
        <div className="modal fade" id="addSubCategoryModal" role="dialog">
          <div className="modal-dialog">
            {/* <!-- Add Modal content--> */}
            <form
              id="addForm"
              data-form-label="add"
              onSubmit={handleSubmitAdd(addData)}
            >
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title fw-bold">ADD CATEGORY SIZE</h4>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    id="closeAddModal"
                  >
                    &times;
                  </button>
                </div>
                <div className="modal-body">
                  {/* <p>Some text in the modal.</p> */}

                  <div>
                    <div>
                      <label>
                        Category <i className="fa fa-asterisk"></i>
                      </label>

                      <select
                        className="form-select"
                        defaultValue="Select Category"
                        {...register1("category", {
                          required: "Please Select Category",
                        })}
                      >
                        <option
                          disabled
                          value=""
                          defaultValue={""}
                          // selected
                        >
                          Select Category
                        </option>
                        {subCategoryData &&
                          subCategoryData.map((item, i) => (
                            <option key={i} value={item.category_id}>
                              {item.category}
                            </option>
                          ))}
                      </select>
                      {errors1.category && (
                        <p className="text-danger position-absolute">
                          {errors1.category.message}
                        </p>
                      )}
                    </div>

                    <div>
                      <label className="mt-3">
                        Size <i className="fa fa-asterisk"></i>
                      </label>

                      <input
                        {...register1("size", {
                          required: "Please Enter category size",
                        })}
                      />
                      {errors1.size && (
                        <p className="text-danger position-absolute">
                          {errors1.size.message}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button type="submit" className="btn btn-default">
                    ADD
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      {/* <!-- Edit Modal --> */}

      <div className="container ">
        <div className="modal fade" id="editCategorySizeModal" role="dialog">
          <div className={`modal-dialog`} id="subcategoryModal">
            {/* <!-- Edit Modal content--> */}
            <form
              id="editForm"
              data-form-label="update"
              onSubmit={handleSubmitUpdate(updateData)}
            >
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title fw-bold">UPDATE CATEGORY SIZE</h4>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    id="closeUpdateModal"
                  >
                    &times;
                  </button>
                </div>
                <div className="modal-body">
                  {/* <p>Some text in the modal.</p> */}
                  {editedItem && (
                    <div>
                      <div>
                        <label>
                          Category <i className="fa fa-asterisk"></i>
                        </label>

                        <select
                          {...register1("categoryEdit", {
                            required: "Please Select Category",
                          })}
                          className="form-select"
                          value={categoryId}
                          onChange={handleCategoryChange} // Call function when user selects an option
                        >
                          <option disabled value="">
                            Select Category
                          </option>
                          {subCategoryData &&
                            subCategoryData.map((item, i) => (
                              <option key={i} value={item.category_id}>
                                {item.category}
                              </option>
                            ))}
                        </select>
                        {errors1.categoryEdit && (
                          <p className="text-danger position-absolute">
                            {errors1.categoryEdit.message}
                          </p>
                        )}
                      </div>

                      <div>
                        <label>
                          Size <i className="fa fa-asterisk"></i>
                        </label>

                        <input
                          value={size}
                          {...register("size", {
                            required: "Please Enter Category Size",
                          })}
                          onChange={(e) => handleSizeChange(e)}
                        />
                        {errors.size && (
                          <p className="text-danger position-absolute">
                            {errors.size.message}
                          </p>
                        )}
                      </div>
                    </div>
                  )}
                </div>
                <div className="modal-footer">
                  <button
                    type="submit"
                    className="btn btn-default"
                    // data-dismiss="modal"
                    // onClick={updateData}
                  >
                    UPDATE
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      {/* <!-- Delete Modal --> */}
      <div className="container ">
        <div className="modal fade" id="deleteCategorySizeModal" role="dialog">
          <div className="modal-dialog">
            {/* <!-- Delete Modal content--> */}
            <form
              id="deleteForm"
              data-form-label="delete"
              onSubmit={handleSubmitDelete(deleteData)}
            >
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title fw-bold">WARNING</h4>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    id="closeDeleteModal"
                  >
                    &times;
                  </button>
                </div>
                <div className="modal-body">
                  <p className="fs-5 fw-bold">
                    Do you want to delete this 'Category Size'!
                  </p>
                </div>
                <div className="modal-footer">
                  <button type="submit" className="btn btn-default">
                    DELETE
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>


    </div>
  );
}

// TableRow Component
const TableRow = ({ provided, item, index, handleEdit, handleDelete }) => (
  <tr
    ref={provided.innerRef}
    {...provided.draggableProps}
    {...provided.dragHandleProps}
  >
    <td>{index + 1}</td>
    <td>{item.category.category || "N/A"}</td>
    <td>{item.size || "N/A"}</td>
    <td>
      <button
        type="button"
        className="btn btn-link p-0"
        onClick={() => handleEdit(item)}
        data-toggle="modal"
        data-target="#editCategorySizeModal"
      >
        <i className="fa fa-pencil popup me-4 pencil-small"></i>
      </button>
      <button
        type="button"
        className="btn btn-link p-0"
        onClick={() => handleDelete(item)}
        data-toggle="modal"
        data-target="#deleteCategorySizeModal"
      >
        <i className="fa fa-trash popup me-4 trash-small"></i>
      </button>
    </td>
  </tr>
);
