import React, { useState, useEffect } from "react";
import Loader from "../../layout/Loader";
import { ToastContainer, toast } from "react-toastify";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import "react-phone-number-input/style.css";
import {
  addSubCategoryData,
  getMasterCategory,
  updateSubCategoryData,
} from "../../../service/ecommerce.service";

export default function UpdateRestCategory() {
  const navigate = useNavigate();
  const location = useLocation();
  const token = useSelector((state) => state.status.token.token);
  const restaurantId = useSelector((state) => state?.admin?.admin?.restaurant);

  const [isLoading, setIsLoading] = useState(false);
  const [catImage, setCatImage] = useState(null);
  const [categoryType, setCategoryType] = useState("All Time");
  const [masterCatData, setMasterCatData] = useState(null);
  const [mainCatId, setMainCatId] = useState(null);
  const [editedImage, setEditedImage] = useState(null);
  const [categoryId, setCategoryId] = useState(null);

  // Fetch subcategory data
  const fetchMainCategory = async () => {
    if (!token || !restaurantId) return;
    setIsLoading(true);
    try {
      const res = await getMasterCategory(token, restaurantId);
      const masterCats = res?.data?.sort((a, b) => {
        return b.master_category_id - a.master_category_id;
      });

      setMasterCatData(masterCats || []);
    } catch (error) {
      toast.error("Failed to fetch data.");
    }
    setIsLoading(false);
  };

  useEffect(() => {
    window.scrollTo(0, 1);
    fetchMainCategory();

    if (location?.state?.categoryDetails) {
      const initialUpdateBusiness = { ...location.state.categoryDetails };
      setCategoryId(initialUpdateBusiness.category_id);
      setEditedImage(initialUpdateBusiness.category_url);
      // Set values for react-hook-form
      setValue("mainCat", initialUpdateBusiness.master_category_id);
      setValue("categoryName", initialUpdateBusiness.category);
      setValue("catSeq", initialUpdateBusiness.sequence);

      if (
        initialUpdateBusiness.available_time &&
        initialUpdateBusiness.available_time !== "All Time"
      ) {
        setValue("customTime", initialUpdateBusiness.available_time);
        setCategoryType("Custom");
      } else {
        // setValue("categoryAvailTime", initialUpdateBusiness.available_time);
        setCategoryType("All Time");
      }
    }
  }, [location.state.categoryDetails]);

  const {
    register,
    setValue,
    setError,
    clearErrors,
    reset,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const addData = (myData) => {
    const mainCatId = Number(watch("mainCat")); // Ensure it's a number

    const mainData = masterCatData?.find(
      (item) => item.master_category_id === mainCatId
    );

    const data = {
      category_id: categoryId,
      category: myData.categoryName,
      restaurant_id: restaurantId,
      // category_url: process.env.REACT_APP_IMAGE_STORAGE_PATH + updatedFile.name,
      available_time: myData.customTime ? myData.customTime : categoryType,
      sequence: myData.catSeq,
      category_for: mainData ? mainData.master_category_name : "",
      master_category: myData.mainCat,
      category_url: "",
    };

    if (typeof editedImage === "string") {
      data.category_url = editedImage;
    } else {
      const sanitizedFileName = editedImage.name
        .replace(/ /g, "_")
        .replace(/&/g, "");
      const updatedFile = new File([editedImage], sanitizedFileName, {
        type: editedImage.type,
      });
      data.image = updatedFile;
      data.category_url =
        process.env.REACT_APP_IMAGE_STORAGE_PATH + updatedFile.name;
    }

    updateSubCategoryData(token, data).then((res) => {
      toast.success("Category Updated...!", {
        autoClose: 1500,
        style: {
          backgroundColor: "black",
          color: "white",
        },
      });

      reset();
    });
    reset({ uploadImage: true });
    setCatImage(null);
    setTimeout(() => {
      navigate("/rest-category");
    }, 3000);
  };

  const updateImage = (event) => {
    const input = event.target;
    if (input.files && input.files[0]) {
      const originalFile = input.files[0];

      const timestamp = Date.now();
      const uniqueFileName = `${timestamp}_${originalFile.name
        .replace(/ /g, "_")
        .replace(/&/g, "")}`;

      const uniqueFile = new File([originalFile], uniqueFileName, {
        type: originalFile.type,
      });
      //   setEditedImage("");
      setEditedImage(uniqueFile);
      //   setBusinessLogo(uniqueFile);

      const reader = new FileReader();
      reader.onload = function (e) {
        document.getElementById("displayedImage").src = e.target.result;
        // setError("displayedImage", { type: "manual", message: "" });
      };

      reader.readAsDataURL(uniqueFile);
    }
  };

  const handleUpdateImageClick = () => {
    // Trigger the click event on the file input when the displayed image is clicked
    document.getElementById("imageInputUpdate").click();
  };

  return (
    <div>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="page-content ms-3 fade-in-up">
          <ToastContainer position="top-center" className="mt-4" limit={1} />

          <div className="">
            <div className="ms-2 me-3">
              <form id="editForm" onSubmit={handleSubmit(addData)}>
                <div className="modal-content business-modal">
                  <div className="modal-header">
                    <h4 className="modal-title fw-bold fs-5">
                      UPDATE CATEGORY
                    </h4>
                    <button
                      type="button"
                      className="close"
                      onClick={() => window.history.back()}
                    >
                      &times;
                    </button>
                  </div>
                  <div className="modal-body business-data">
                    <table width={"100%"}>
                      <tbody>
                        <tr>
                          <td>
                            <label>
                              Main Category <i className="fa fa-asterisk"></i>
                            </label>
                            <select
                              {...register("mainCat", {
                                required: "Select a main category",
                              })}
                              className="form-select"
                              onChange={(e) => setMainCatId(e.target.value)}
                            >
                              <option value="" disabled>
                                Select main category
                              </option>
                              {masterCatData &&
                                masterCatData.map((item, index) => (
                                  <option value={item.master_category_id}>
                                    {item.master_category_name}
                                  </option>
                                ))}
                            </select>
                            {errors.mainCat && (
                              <p className="text-danger">
                                {errors.mainCat.message}
                              </p>
                            )}
                          </td>
                          <td>
                            <label>
                              Category Name <i className="fa fa-asterisk"></i>
                            </label>
                            <input
                              {...register("categoryName", {
                                required: "Enter category name",
                              })}
                              placeholder="Enter category name"
                            />
                            {errors.categoryName && (
                              <p className="text-danger">
                                {errors.categoryName.message}
                              </p>
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <label>
                              Category Sequence{" "}
                              <i className="fa fa-asterisk"></i>
                            </label>
                            <input
                              {...register("catSeq", {
                                required: "Enter category sequence",
                              })}
                              placeholder="Enter category sequence"
                            />
                            {errors.catSeq && (
                              <p className="text-danger">
                                {errors.catSeq.message}
                              </p>
                            )}
                          </td>
                          <td>
                            <label>
                              Category Availability Time{" "}
                              <i className="fa fa-asterisk"></i>
                            </label>
                            <div className="d-flex radio-button mt-1">
                              <div className="form-check me-4">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="categoryAvailTime"
                                  value="All Time"
                                  checked={categoryType === "All Time"}
                                  onChange={() => {
                                    setCategoryType("All Time");
                                    setValue("customTime", "");
                                  }}
                                />
                                <label className="form-check-label">
                                  All Time
                                </label>
                              </div>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="categoryAvailTime"
                                  value="Custom"
                                  checked={categoryType === "Custom"}
                                  onChange={() => setCategoryType("Custom")}
                                />
                                <label className="form-check-label">
                                  Custom Time
                                </label>
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <label>
                              Category Iamge <i className="fa fa-asterisk"></i>
                            </label>

                            {/* Hidden file input */}
                            <input
                              type="file"
                              id="imageInputUpdate"
                              accept="image/*"
                              style={{ display: "none" }}
                              onChange={updateImage}
                            />
                            {/* Displayed image */}
                            <div className="ms-3">
                              <img
                                src={editedImage}
                                id="displayedImage"
                                alt={`Image`}
                                onClick={() => handleUpdateImageClick()}
                                className="ml-0"
                                // {...register("editedImage", {
                                //   required: "Add Image",
                                // })}
                                // data-bs-dismiss="#editSubCategoryModal"
                                // data-bs-target="#addImageModal"
                                // data-bs-toggle="modal"
                                // onClick={hideSubcategoryModal}
                              />
                            </div>
                            {/* {errors.editedImage && (
                  <p className="text-danger">
                    {errors.editedImage.message}
                  </p>
                )} */}
                          </td>

                          {categoryType === "Custom" && (
                            <td>
                              <label>
                                Custom Time <i className="fa fa-asterisk"></i>
                              </label>
                              <input
                                type="time"
                                {...register("customTime", {
                                  required: "Select time",
                                })}
                                placeholder="Select Time"
                              />
                              {errors.customTime && (
                                <p className="text-danger">
                                  {errors.customTime.message}
                                </p>
                              )}
                            </td>
                          )}
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="modal-footer justify-content-center">
                    <button type="submit" className="btn btn-default w-50">
                      UPDATE CATEGORY
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
