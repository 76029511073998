import React, { useEffect, useState } from "react";
import Loader from "../../layout/Loader";
import { ToastContainer, toast } from "react-toastify";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import ReactPaginate from "react-paginate";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { deleteSubCategoryData, getSubCategory } from "../../../service/ecommerce.service";
import { useForm } from "react-hook-form";

export default function RestCategory() {
  const navigate = useNavigate();
  const token = useSelector((state) => state?.status?.token?.token);
  const restaurantId = useSelector((state) => state?.admin?.admin?.restaurant);

  const [isLoading, setIsLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [subCatData, setSubCatData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [count, setCount] = useState(0);
  const [isButtonActive, setIsButtonActive] = useState(false);
  const [deletedItem, setDeletedItem] = useState(null);

  // Fetch subcategory data
  const fetchSubCategoryData = async () => {
    if (!token || !restaurantId) return;
    setIsLoading(true);
    try {
      const res = await getSubCategory(token, restaurantId);
      const subCatData = res?.data?.sort((a,b) => {
        return b.category_id - a.category_id})
      setSubCatData(subCatData || []);
      setCount(res?.data?.length);
    } catch (error) {
      toast.error("Failed to fetch data.");
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchSubCategoryData();
  }, [token, restaurantId]); // Only fetch data when token or restaurantId changes

  // Filter categories based on search query with safe checks
  const filteredCategory = subCatData?.filter((item) =>
    ["category_for", "sequence", "category", "image", "available_time"].some((key) =>
      item[key] ? item[key].toString().toLowerCase().includes(searchQuery.trim().toLowerCase()) : false
    )
  ) || [];

  // Pagination logic
  const startIndex = currentPage * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const paginatedData = filteredCategory.slice(startIndex, endIndex);

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  // Handle drag-and-drop reordering
  const handleDragEnd = (result) => {
    if (!result.destination) return;
    const updatedData = [...subCatData];
    const [reorderedItem] = updatedData.splice(result.source.index, 1);
    updatedData.splice(result.destination.index, 0, reorderedItem);
    setSubCatData(updatedData);
  };

  const toggleButton = () => {
    setIsButtonActive(!isButtonActive);
  };

  // Function to handle editing
  const handleEdit = (item) => {
    navigate("/update-restCategory", {
      state: {
        categoryDetails: item,
      },
    });
  };

  // Function to handle editing
  const handleDelete = (item) => {
    reset2({ confirmSubcategory: "" });
    setDeletedItem(item);
  };

  const {
    register: register2,
    handleSubmit: handleSubmitDelete,
    watch,
    reset: reset2,
    formState: { errors: errors2 },
  } = useForm();

  const deleteData = () => {
    deleteSubCategoryData(token, deletedItem.category_id).then((res) => {
      toast.error("Category Deleted...!", {
        autoClose: 1500,
        style: {
          backgroundColor: "black",
          color: "white",
        },
      });
      reset2({ confirmSubcategory: "" });
      document.getElementById("closeDeleteModal").click();
      fetchSubCategoryData();
    });
  };

  return (
    <div>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="page-content ms-3 fade-in-up">
          <ToastContainer position="top-center" className="mt-4" limit={1} />
          <div className="ibox">
            <div className="ibox-body m-3">
            <Link to="/add-restCategory">
                <div
                  className={`sticky-button ${isButtonActive ? "active" : ""}`}
                  onClick={() => toggleButton()}
                >
                  <i className="fa fa-plus"></i>
                </div>
              </Link>
              <div className="d-flex justify-content-between my-3">
                <div>
                  Show{" "}
                  <select
                    value={itemsPerPage}
                    onChange={(e) => setItemsPerPage(parseInt(e.target.value, 10))}
                  >
                    {[10, 20, 30, 40, 50, 100].map((num) => (
                      <option key={num} value={num}>
                        {num}
                      </option>
                    ))}
                  </select>{" "}
                  Results
                </div>
                <div>
                  Search:{" "}
                  <input
                    type="text"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    className="btn-outline-secondary"
                  />
                </div>
              </div>

              <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId="categories">
                  {(provided) => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                      <table className="table table-striped table-bordered table-hover">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Main Category Name</th>
                            <th>Sequence</th>
                            <th>Category Name</th>
                            <th>Image</th>
                            <th>Availability</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {paginatedData.map((item, index) => (
                            <Draggable
                              key={item.id || `draggable-${index}`}
                              draggableId={item.id ? item.id.toString() : `draggable-${index}`}
                              index={index}
                            >
                              {(provided) => (
                                <TableRow
                                  provided={provided}
                                  index={startIndex + index}
                                  item={item}
                                  handleEdit={handleEdit}
                                  handleDelete={handleDelete}
                                />
                              )}
                            </Draggable>
                          ))}
                        </tbody>
                      </table>
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
              {count > itemsPerPage && (
                <div className="d-flex justify-content-between my-3">
                  <div className="fw-light">
                    Showing{" "}
                    {filteredCategory.length > 0
                      ? startIndex + 1
                      : startIndex}{" "}
                    to {Math.min(endIndex, filteredCategory.length)} of{" "}
                    {filteredCategory.length} entries
                  </div>
                  <div>
                    {filteredCategory.length > itemsPerPage && (
                      <ReactPaginate
                        previousLabel={
                          <button
                            type="button"
                            className="btn btn-outline-secondary w-100"
                          >
                            Previous
                          </button>
                        }
                        nextLabel={
                          <button
                            type="button"
                            className="btn btn-outline-secondary w-100"
                          >
                            Next
                          </button>
                        }
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={Math.ceil(
                          filteredCategory.length / itemsPerPage
                        )}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={handlePageChange}
                        containerClassName={"pagination"}
                        subContainerClassName={"pages pagination"}
                        activeClassName={
                          "active-class btn btn-outline-secondary w-100 fw-bold"
                        }
                        pageClassName={
                          "page-class btn btn-outline-secondary w-100"
                        }
                      />
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      {/* <!-- Delete Modal --> */}
      <div className="container ">
                <div
                  className="modal fade"
                  id="deleteSubCategoryModal"
                  role="dialog"
                >
                  <div className="modal-dialog">
                    {/* <!-- Delete Modal content--> */}
                    <form
                      id="deleteForm"
                      data-form-label="delete"
                      onSubmit={handleSubmitDelete(deleteData)}
                    >
                      <div className="modal-content">
                        <div className="modal-header">
                          <h4 className="modal-title fw-bold">WARNING</h4>
                          <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            id="closeDeleteModal"
                          >
                            &times;
                          </button>
                        </div>
                        <div className="modal-body">
                          <p className="fs-5">
                            After delete this 'Category', All data related to
                            this 'Category' will be deleted.
                          </p>
                          <p className="fs-5 fw-bold">
                            You still want to delete this 'Category'!
                          </p>
                          <p className="fw-light">
                            Confirm your selected 'Category' to Delete
                          </p>
                          {deletedItem && (
                            <div>
                              <input
                                hidden
                                value={deletedItem.category}
                                {...register2("subcategoryDelete")}
                              />
                              <input
                                {...register2("confirmSubcategory", {
                                  required: "Please Enter Category",
                                  validate: (value) => {
                                    if (watch("subcategoryDelete") !== value) {
                                      return "Sorry! Your Selected 'Category' do not Match";
                                    }
                                  },
                                })}
                              />
                              {errors2.confirmSubcategory && (
                                <p className="text-danger position-absolute">
                                  {errors2.confirmSubcategory.message}
                                </p>
                              )}
                            </div>
                          )}
                        </div>
                        <div className="modal-footer">
                          <button
                            type="submit"
                            className="btn btn-default"
                            // data-dismiss="modal"
                            // onClick={updateData}
                          >
                            DELETE
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
    </div>
  );
}

// TableRow Component
const TableRow = ({ provided, item, index, handleEdit, handleDelete }) => (
  <tr ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
    <td>{index + 1}</td>
    <td>{item.category_for || "N/A"}</td>
    <td>{item.sequence || "N/A"}</td>
    <td>{item.category || "N/A"}</td>
    <td>
      {item.category_url ? (
        <img src={item.category_url} alt="Category" style={{ width: "50px", height: "50px" }} />
      ) : (
        "No Image"
      )}
    </td>
    <td>{item.available_time || "N/A"}</td>
    <td>
      <button type="button" className="btn btn-link p-0"
      onClick={() => handleEdit(item)}>
        <i className="fa fa-pencil popup me-4 pencil-small"></i>
      </button>
      <button type="button" className="btn btn-link p-0" onClick={() => handleDelete(item)}
        data-toggle="modal"
        data-target="#deleteSubCategoryModal">
        <i className="fa fa-trash popup me-4 trash-small"></i>
      </button>
    </td>
  </tr>
);
