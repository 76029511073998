import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Orders from "./components/orders/Orders";
import Login from "./components/layout/Login";
import Ecommerce from "./components/Ecommerce";
import Category from "./components/category/Category";
import SubCategory from "./components/subCategory/SubCategory";
import ProductDetails from "./components/orders/pages/OrderDetails";
import NewOrders from "./components/orders/pages/NewOrders";
import AcceptedOrders from "./components/orders/pages/AcceptedOrders";
import DeliveredOrders from "./components/orders/pages/DeliveredOrders";
import CancelledOrders from "./components/orders/pages/CancelledOrders";
import TrackOrder from "./components/orders/pages/TrackOrder";
import Products from "./components/products/Products";
import UpdateProduct from "./components/products/pages/UpdateProduct";
import AddProduct from "./components/products/pages/AddProduct";
import AddAddonProduct from "./components/products/pages/AddAddonProduct";
import AddonProduct from "./components/products/pages/AddonProduct";
import Specification from "./components/products/pages/Specification";
import Coupons from "./components/coupons/Coupons";
import Setup from "./components/setup/Setup";
import Subscription from "./components/subscription/Subscription";
import Support from "./components/support/Support";
import OrderDetails from "./components/orders/pages/OrderDetails";
import Onboarding from "./components/super/onboarding/Onboarding";
import Business from "./components/super/business/Business";
import AddBusiness from "./components/super/business/AddBusiness";
import UpdateBusiness from "./components/super/business/UpdateBusiness";
import BusinessAdmin from "./components/super/business-admin/BusinessAdmin";
import AddBusinessAdmin from "./components/super/business-admin/AddBusinessAdmin";
import UpdateAdmin from "./components/super/business-admin/UpdateAdmin";
import RestSetup from "./components/restaurantAdmin/RestSetup";
import OrdersList from './components/restaurantAdmin/orderList/OrdersList';
import MainCategory from './components/restaurantAdmin/mainCategory/MainCategory';
import RestCategory from './components/restaurantAdmin/restcategory/RestCategory';
import CategorySize from './components/restaurantAdmin/categorySize/CategorySizes';
import Addon from './components/restaurantAdmin/addons/Addons';
import Menu from './components/restaurantAdmin/menu/Menu';
import AddonContent from "./components/restaurantAdmin/addonContent/AddonContent";
import RestSubscription from "./components/restaurantAdmin/subscriptions/RestSubscription";
import AddMainCategory from "./components/restaurantAdmin/mainCategory/AddMainCategory";
import UpdateMainCategory from "./components/restaurantAdmin/mainCategory/UpdateMainCategory";
import FullFillOrders from "./components/restaurantAdmin/orderList/pages/FullFillOrders";
import PickUpOrders from "./components/restaurantAdmin/orderList/pages/PickUpOrders";
import DispatchedOrders from "./components/restaurantAdmin/orderList/pages/DispatchedOrders";
import CompletedOrders from "./components/restaurantAdmin/orderList/pages/CompletedOrders";
import PlacedOrders from "./components/restaurantAdmin/orderList/pages/PlacedOrders";
import RestOrderDetails from "./components/restaurantAdmin/orderList/pages/RestOrderDetails";
import AddRestCategory from "./components/restaurantAdmin/restcategory/AddRestCategory";
import UpdateRestCategory from "./components/restaurantAdmin/restcategory/UpdateRestCategory";
import Success from "./components/restaurantAdmin/subscriptions/Success";
import Cancel from "./components/restaurantAdmin/subscriptions/Cancel";
import AddMenu from "./components/restaurantAdmin/menu/pages/AddMenu";
import UpdateMenu from "./components/restaurantAdmin/menu/pages/UpdateMenu";
import WorkingHours from "./components/restaurantAdmin/WorkingHours/WorkingHours";

export default function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />} />

        <Route path="/" element={<Ecommerce />}>
          <Route path="/orders" element={<Orders />}>
            <Route path="/orders/new" element={<NewOrders />} />
            <Route path="/orders/ready" element={<AcceptedOrders />} />
            <Route path="/orders/delivered" element={<DeliveredOrders />} />
            <Route path="/orders/canceled" element={<CancelledOrders />} />
          </Route>
          <Route path="/order-details" element={<OrderDetails />} />
          <Route path="/track-order" element={<TrackOrder />} />
          <Route path="/category" element={<Category />} />
          <Route path="/sub-category" element={<SubCategory />} />
          <Route path="/products" element={<Products />} />
          <Route path="/update-product" element={<UpdateProduct />} />
          <Route path="/add-product" element={<AddProduct />} />
          <Route path="/addon-product" element={<AddonProduct />} />
          <Route path="/add-addon-product" element={<AddAddonProduct />} />
          <Route path="/specification" element={<Specification />} />
          <Route path="/coupons" element={<Coupons />} />
          <Route path="/setup" element={<Setup />} />
          <Route path="/subscription" element={<Subscription />} />
          <Route path="/support" element={<Support />} />

          {/* Super Admin Routes */}
          {/* <Route path="/business" element={<Business />}> */}
            <Route path="/business" element={<Business />} />
            <Route path="/add-business" element={<AddBusiness  />} />
            <Route path="/update-business" element={<UpdateBusiness  />} />
            <Route path="/admin" element={<BusinessAdmin />} />
            <Route path="/add-admin" element={<AddBusinessAdmin />} />
            <Route path="/update-admin" element={<UpdateAdmin />} />

            <Route path="/onboarding" element={<Onboarding />} />
          {/* /. Super Admin Routes */}

          {/* Restaurant Admin Routes */}
            <Route path="/restsetup" element={<RestSetup />} />
            <Route path="/working-hours" element={<WorkingHours />} />
            {/* <Route path="/orderlist" element={<OrdersList />} /> */}
            <Route path="/orderlist" element={<OrdersList />}>
              <Route path="/orderlist/placed" element={<PlacedOrders />} />
              <Route path="/orderlist/fullfill" element={<FullFillOrders />} />
              <Route path="/orderlist/pickup" element={<PickUpOrders />} />
              <Route path="/orderlist/dispatched" element={<DispatchedOrders />} />
              <Route path="/orderlist/completed" element={<CompletedOrders />} />
            </Route>
            <Route path="/rest-order-details" element={<RestOrderDetails />} />
            
            <Route path="/main-category" element={<MainCategory />} />
            <Route path="/Addmain-category" element={<AddMainCategory />} />
            <Route path="/Update-main-category" element={<UpdateMainCategory />} />

            <Route path="/rest-category" element={<RestCategory />} />
            <Route path="/add-restCategory" element={<AddRestCategory />} />
            <Route path="/update-restCategory" element={<UpdateRestCategory />} />

            <Route path="/size" element={<CategorySize />} />
            <Route path="/addon" element={<Addon />} />
            <Route path="/addon-content" element={<AddonContent />} />

            <Route path="/menu" element={<Menu />} />
            <Route path="/add-menu" element={<AddMenu />} />
            <Route path="/update-menu" element={<UpdateMenu />} />

            <Route path="/rest-subscription" element={<RestSubscription />} />
            <Route path="/success" element={<Success />} />
            <Route path="/cancel" element={<Cancel />} />
          {/* /. Restaurant Admin Routes */}
        </Route>

      </Routes>
    </BrowserRouter>
  );
}
