import { RESTAURANT_ADMIN, LOGOUT_SESSION } from "../constants/Constants";

let initialState = {
  admin: [],
};

const restaurantAdminReducer = (state = initialState, action) => {
  switch (action.type) {
    case RESTAURANT_ADMIN:
      return {
        admin: action.payload,
      };

      case LOGOUT_SESSION:
        return { ...initialState };

    default:
      break;
  }
  return state;
};

export default restaurantAdminReducer;
