import React, { useState, useEffect } from "react";
import { Outlet, Link, useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { clearToken, logoutSession } from "../redux/actions/Actions";
import { useSelector } from "react-redux";

export default function Ecommerce() {
  let dispatch = useDispatch();
  const isSuperUser = useSelector(
    (state) => state?.restaurant?.restaurant?.is_superuser
  );
  const username = useSelector(
    (state) => state?.restaurant?.restaurant?.name
  );
  const restaurantId = useSelector((state) => state.admin.admin.restaurant);

  // const [active,setActive] = useState(true);
  const [isSidebarMinimized, setIsSidebarMinimized] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState("");

  useEffect(() => {
    window.scrollTo(0, 1);
    // Set the active tab based on the current location pathname
    const path = location.pathname;
    setActiveTab(getTabFromPath(path));
  }, [location.pathname]);

  const getTabFromPath = (path) => {
    if (path.includes("/orders")) return "orders";
    if (path.includes("/category")) return "category";
    if (path.includes("/sub-category")) return "subCategory";
    if (path.includes("/products")) return "products";
    if (path.includes("/coupons")) return "coupons";
    if (path.includes("/setup")) return "setup";
    if (path.includes("/subscription")) return "subscription";
    if (path.includes("/support")) return "support";
    if (path.includes("/order-details")) return "orders";
    if (path.includes("/track-order")) return "orders";
    if (path.includes("/update-product")) return "products";
    if (path.includes("/add-product")) return "products";
    if (path.includes("/addon-product")) return "products";
    if (path.includes("/add-addon-product")) return "products";
    if (path.includes("/specification")) return "products";
    if (path.includes("/business")) return "business";
    if (path.includes("/add-business")) return "business";
    if (path.includes("/update-business")) return "business";
    if (path.includes("/admin")) return "businessAdmin";
    if (path.includes("/add-admin")) return "businessAdmin";
    if (path.includes("/update-admin")) return "businessAdmin";
    if (path.includes("/onboarding")) return "onboarding";
    if (path.includes("/restsetup")) return "restsetup";
    if (path.includes("/working-hours")) return "working-hours";
    if (path.includes("/orderlist") || path.includes("/rest-order-details")) return "orderlist";
    if (path.includes("/main-category") || path.includes("/Addmain-category") || path.includes("/Update-main-category")) return "main-category";
    if (path.includes("/rest-category") || path.includes("/add-restCategory") || path.includes("/update-restCategory")) return "rest-category";
    if (path.includes("/size")) return "size";
    if (path.includes("/addon")) return "addon";
    if (path.includes("/addon-content")) return "addon-content";
    if (path.includes("/menu") || path.includes("/add-menu") || path.includes("/update-menu")) return "menu";
    if (path.includes("/rest-subscription")) return "rest-subscription";

    return "";
  };

  // const handleTabClick = () => {
  //   setActive(false);
  // };

  const Logout = () => {
    dispatch(clearToken());
    dispatch(logoutSession());
    setTimeout(() => {
      navigate("/");
    }, 10);
  };

  const toggleSidebar = () => {
    setIsSidebarMinimized((prev) => !prev);
  };

  console.log('cat size.....', activeTab);
  
  return (
    <div>
      {/* <div id="body" className="fixed-navbar"> */}
      <div
        id="body"
        className={`fixed-navbar ${
          isSidebarMinimized ? "sidebar-minimized" : ""
        }`}
      >
        <div className="page-wrapper" id="page">
          {/* <!-- START HEADER--> */}
          <header className="header">
            <div className="page-brand">
              <Link className="link mt-4 ms-5 p-2" to="/orders/new">
                Dashboard{" "}
                <i
                  className="fa fa-bars ms-3"
                  onClick={() => toggleSidebar()}
                ></i>
                <span className="brand">
                  {/* InstaApp Admin */}

                  {/* <img src="/assets/img/logo.png" /> */}
                </span>
              </Link>
            </div>
            <div className="flexbox flex-1">
              {/* <!-- START TOP-LEFT TOOLBAR--> */}
              <ul className="nav navbar-toolbar"></ul>
              {/* <!-- END TOP-LEFT TOOLBAR--> */}
              {/* <!-- START TOP-RIGHT TOOLBAR--> */}

              <ul className="nav navbar-toolbar">
                <div className="row">
                  <div className="col-9"></div>
                  {/* col-2 */}
                  <div className="px-4 w-25 h-25 col-3 logo">
                    <img
                      className="px-3 float-left" // ms-5
                      src="/assets/img/logo.png"
                      alt="logo"
                    />
                  </div>
                  <div className="w-25 h-25 col-1 mt-1 logo-2" hidden>
                    <Link to="/support">
                      <img
                        className="w-50 h-50 ms-3"
                        src="/assets/img/support.png"
                        alt="logo"
                      />
                    </Link>
                  </div>
                </div>

                {/* <li className="dropdown dropdown-user">
                  <a
                    className="nav-link dropdown-toggle link"
                    data-toggle="dropdown"
                  >
                    <img src="/assets/img/admin-avatar.png" />

                    <span></span>Admin<i className="fa fa-angle-down m-l-5"></i>
                  </a>
                  <ul className="dropdown-menu dropdown-menu-right">
                    <a className="dropdown-item" href="profile.html">
                      <i className="fa fa-user"></i>Profile
                    </a>
                    <a className="dropdown-item" href="profile.html">
                      <i className="fa fa-cog"></i>Settings
                    </a>
                    <a className="dropdown-item" href="#">
                      <i className="fa fa-support"></i>Support
                    </a>
                    <li className="dropdown-divider"></li>
                    <a className="dropdown-item" href="login.html">
                      <i className="fa fa-power-off"></i>Logout
                    </a>
                  </ul>
                </li> */}
              </ul>
              {/* <!-- END TOP-RIGHT TOOLBAR--> */}
            </div>
          </header>
          {/* <!-- END HEADER--> */}
          {/* <!-- START SIDEBAR--> */}

          <nav className="page-sidebar" id="sidebar">
            <div id="sidebar-collapse">
              <div className="admin-block d-flex"></div>
              {isSuperUser === true ? (
                <ul className="side-menu metismenu ms-4 fs-5">
                  <li
                    // onClick={() => setActiveTab("orders")}
                    className={`${activeTab === "business" ? "active" : ""}`}
                  >
                    <Link to="/business">
                      <i className="sidebar-item-icon fa fa-th me-2 mt-1"></i>
                      <span className="nav-label">Business</span>
                    </Link>
                  </li>
                  <li
                    // onClick={() => setActiveTab("orders")}
                    className={`${
                      activeTab === "businessAdmin" ? "active" : ""
                    }`}
                  >
                    <Link to="/admin">
                      <i className="sidebar-item-icon fa fa-th me-2 mt-1"></i>
                      <span className="nav-label">Business Admin</span>
                    </Link>
                  </li>
                  <li
                    // onClick={() => setActiveTab("orders")}
                    className={`${activeTab === "onboarding" ? "active" : ""}`}
                  >
                    <Link to="/onboarding">
                      <i className="sidebar-item-icon fa fa-th-list me-2 mt-1"></i>
                      <span className="nav-label">Onboarding</span>
                    </Link>
                  </li>
                  <li className="mb-3 mt-3" onClick={() => Logout()}>
                    <Link to="/">
                      <i className="sidebar-item-icon fa fa-sign-out me-2 mt-1"></i>
                      <span className="nav-label">Logout</span>
                    </Link>
                  </li>
                </ul>
              ) : username !== "BambooSA" ? (
                <ul className="side-menu metismenu ms-4 fs-5">
                 
                  <li
                    // onClick={() => setActiveTab("orders")}
                    className={`mb-1 mt-1 ${activeTab === "orderlist" ? "active" : ""}`}
                  >
                    { restaurantId === 280 || restaurantId == 225 
                    ? 
                    <Link to="/orderlist/fullfill">
                      <i className="sidebar-item-icon fa fa-th-list me-2"></i>
                      <span className="nav-label">Orders</span>
                    </Link>
                    :
                    <Link to="/orderlist/placed">
                      <i className="sidebar-item-icon fa fa-th-list me-2"></i>
                      <span className="nav-label">Orders</span>
                    </Link>
                  }
                  </li>
                  <li
                    // onClick={() => setActiveTab("orders")}
                    className={`mb-1 mt-1 ${activeTab === "main-category" ? "active" : ""}`}
                  >
                    <Link to="/main-category">
                      <i className="sidebar-item-icon fa fa-th-large me-2"></i>
                      <span className="nav-label">Main Category</span>
                    </Link>
                  </li>
                  <li
                    // onClick={() => setActiveTab("orders")}
                    className={`mb-1 mt-1 ${activeTab === "rest-category" ? "active" : ""}`}
                  >
                    <Link to="/rest-category">
                      <i className="sidebar-item-icon fa fa-th me-2"></i>
                      <span className="nav-label">Category</span>
                    </Link>
                  </li>
                  <li
                    // onClick={() => setActiveTab("orders")}
                    className={`mb-1 mt-1 ${activeTab === "size" ? "active" : ""}`}
                  >
                    <Link to="/size">
                      <i className="sidebar-item-icon fa fa-th-large me-2"></i>
                      <span className="nav-label">Category Size</span>
                    </Link>
                  </li>
                  <li
                    // onClick={() => setActiveTab("orders")}
                    className={`mb-1 mt-1 ${activeTab === "addon" ? "active" : ""}`}
                  >
                    <Link to="/addon">
                      <i className="sidebar-item-icon fa fa-plus me-2 mt-1"></i>
                      <span className="nav-label">Addon</span>
                    </Link>
                  </li>
                  <li
                    // onClick={() => setActiveTab("orders")}
                    className={`mb-1 mt-1 ${activeTab === "addon-content" ? "active" : ""}`}
                  >
                    <Link to="/addon-content">
                      <i className="sidebar-item-icon fa fa-plus-circle me-2 mt-1"></i>
                      <span className="nav-label">Addon Content</span>
                    </Link>
                  </li>
                  <li
                    // onClick={() => setActiveTab("orders")}
                    className={`mb-1 mt-1 ${activeTab === "menu" ? "active" : ""}`}
                  >
                    <Link to="/menu">
                      <i className="sidebar-item-icon fa fa-th me-2 mt-1"></i>
                      <span className="nav-label">Menu</span>
                    </Link>
                  </li>
                  <li
                    // onClick={() => setActiveTab("coupons")}
                    className={`mb-1 mt-1 ${
                      activeTab === "coupons" ? "active" : ""
                    }`}
                  >
                    <Link to="/coupons">
                      <i className="sidebar-item-icon fa fa-tags me-2"></i>
                      <span className="nav-label">Coupons</span>
                    </Link>
                  </li>
                  <li
                    // onClick={() => setActiveTab("orders")}
                    className={`mb-1 mt-1 ${activeTab === "restsetup" ? "active" : ""}`}
                  >
                    <Link to="/restsetup">
                      <i className="sidebar-item-icon fa fa-edit me-2"></i>
                      <span className="nav-label">Setup</span>
                    </Link>
                  </li>
                  <li
                    // onClick={() => setActiveTab("orders")}
                    className={`mb-1 mt-1 ${activeTab === "working-hours" ? "active" : ""}`}
                  >
                    <Link to="/working-hours">
                      <i className="sidebar-item-icon fa fa-clock-o me-2"></i>
                      <span className="nav-label">Working Hours</span>
                    </Link>
                  </li>
                  <li
                    // onClick={() => setActiveTab("orders")}
                    className={`mb-1 mt-1 ${activeTab === "rest-subscription" ? "active" : ""}`}
                  >
                    <Link to="/rest-subscription">
                      <i className="sidebar-item-icon fa fa-credit-card-alt me-2"></i>
                      <span className="nav-label">Subscription</span>
                    </Link>
                  </li>
                  <li className="mb-1 mt-1" onClick={() => Logout()}>
                    <Link to="/">
                      <i className="sidebar-item-icon fa fa-sign-out me-2"></i>
                      <span className="nav-label">Logout</span>
                    </Link>
                  </li>
                </ul>
              ) : (
                <ul className="side-menu metismenu ms-4 fs-5">
                  <li
                    // onClick={() => setActiveTab("orders")}
                    className={`${activeTab === "orders" ? "active" : ""}`}
                  >
                    <Link to="/orders/new">
                      <i className="sidebar-item-icon fa fa-th-list me-2"></i>
                      <span className="nav-label">Orders</span>
                    </Link>
                  </li>
                  <li
                    // onClick={() => setActiveTab("category")}
                    className={`mb-3 mt-3 ${
                      activeTab === "category" ? "active" : ""
                    }`}
                  >
                    <Link to="/category">
                      <i className="sidebar-item-icon fa fa-th-large me-2"></i>
                      <span className="nav-label">Category</span>
                    </Link>
                  </li>
                  <li
                    // onClick={() => setActiveTab("subCategory")}
                    className={`mb-3 mt-3 ${
                      activeTab === "subCategory" ? "active" : ""
                    }`}
                  >
                    <Link to="/sub-category">
                      <i className="sidebar-item-icon fa fa-th me-2"></i>
                      <span className="nav-label">Sub Category</span>
                    </Link>
                  </li>
                  <li
                    // onClick={() => setActiveTab("products")}
                    className={`mb-3 mt-3 ${
                      activeTab === "products" ? "active" : ""
                    }`}
                  >
                    <Link to="/products">
                      <i className="sidebar-item-icon fa fa-product-hunt me-2"></i>
                      <span className="nav-label">Products</span>
                    </Link>
                  </li>
                  <li
                    // onClick={() => setActiveTab("coupons")}
                    className={`mb-3 mt-3 ${
                      activeTab === "coupons" ? "active" : ""
                    }`}
                  >
                    <Link to="/coupons">
                      <i className="sidebar-item-icon fa fa-tags me-2"></i>
                      <span className="nav-label">Coupons</span>
                    </Link>
                  </li>

                  <li
                    // onClick={() => setActiveTab("setup")}
                    className={`mb-3 mt-3 ${
                      activeTab === "setup" ? "active" : ""
                    }`}
                  >
                    <Link to="/setup">
                      <i className="sidebar-item-icon fa fa-edit me-2"></i>
                      <span className="nav-label">Setup</span>
                    </Link>
                  </li>
                  <li
                    // onClick={() => setActiveTab("subscription")}
                    className={`mb-3 mt-3 ${
                      activeTab === "subscription" ? "active" : ""
                    }`}
                  >
                    <Link to="/subscription">
                      <i className="sidebar-item-icon fa fa-credit-card-alt me-2"></i>
                      <span className="nav-label">Subscription</span>
                    </Link>
                  </li>

                  <li
                    // onClick={() => setActiveTab("support")}
                    className={`mb-3 mt-3 ${
                      activeTab === "support" ? "active" : ""
                    }`}
                    hidden
                  >
                    <Link to="/support">
                      <i className="sidebar-item-icon fa fa-user-circle me-2"></i>
                      <span className="nav-label">Support</span>
                    </Link>
                  </li>

                  <li className="mb-3 mt-3" onClick={() => Logout()}>
                    <Link to="/">
                      <i className="sidebar-item-icon fa fa-sign-out me-2"></i>
                      <span className="nav-label">Logout</span>
                    </Link>
                  </li>
                </ul>
              )}
            </div>
          </nav>
          {/* <!-- END SIDEBAR--> */}
          <div className="content-wrapper">
            {/* <!-- START PAGE CONTENT--> */}
            <Outlet />
            {/* <!-- END PAGE CONTENT--> */}
          </div>
        </div>
        {/* <!-- BEGIN PAGA BACKDROPS--> */}
        <div className="sidenav-backdrop backdrop"></div>
        {/* <div className="preloader-backdrop">
          <div className="page-preloader">Loading</div>
        </div> */}
        {/* <!-- END PAGA BACKDROPS--> */}
      </div>
    </div>
  );
}
