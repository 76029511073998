import React, {
  // eslint-disable-next-line
  useEffect,
} from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import {
  generateTokenAction,
  restaurantAdminAction,
  restaurantDetailsAction,
} from "../../redux/actions/Actions";
import { adminLogin, generateToken } from "../../service/adminLogin.service";
import { getRestaurantDetails, restaurantAdmins } from "../../service/ecommerce.service";

export default function Login() {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  // eslint-disable-next-line
  // const token = useSelector((state) => state.status.token.token);
  const business = useSelector((state) => state?.admin?.admin?.username);

  const loginSession = () => {
    // if (business) {
    //   navigate("/orders/new");
    // } else {
    //   navigate("/");
    // }
    navigate("/");
  };

  useEffect(() => {
    window.scrollTo(0, 1);
    loginSession();
    // eslint-disable-next-line
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onLogin = (data) => {
    adminLogin(data).then((res) => {
      console.log('res....', res)
      if (res && res.data.message === "success") {
        if(res.data.is_superuser === true) {
            dispatch(restaurantDetailsAction(res.data))
            toast.success("Login Successful...!", {
              autoClose: 1500,
              style: {
                backgroundColor: "black",
                color: "white",
              },
              containerId: "loginToast",
            });
            generateToken().then((res) => {
              const token = res?.data?.token;
              dispatch(generateTokenAction(res.data));

              setTimeout(() => {
                navigate("/business");
              }, 3000);
            });
        } else {
          generateToken().then((res) => {
            const token = res?.data?.token;
            dispatch(generateTokenAction(res.data));
              let restaurant = '';
              let username = data?.username;
              restaurantAdmins(res.data.token, data.username, "ACTIVE").then(
                (res) => {
                  dispatch(restaurantAdminAction(res.data[0]));
                  restaurant = res.data[0].restaurant;
                  getRestaurantDetails(token, restaurant).then((res) => {
                    dispatch(restaurantDetailsAction(res.data))
                  })
                }
              );
              toast.success("Login Successful...!", {
                autoClose: 1500,
                style: {
                  backgroundColor: "black",
                  color: "white",
                },
                containerId: "loginToast",
              });
              console.log("username....", username);
              if(username == 'bamboosa') {
                setTimeout(() => {
                  navigate("/orders/new");
                }, 3000);
              } else {
                console.log("restaurant......", restaurant)
                if(restaurant == 280 || restaurant == 225) {
                  setTimeout(() => {
                    navigate("/orderlist/fullfill");
                  }, 3000);
                } else {
                  setTimeout(() => {
                    navigate("/orderlist/placed");
                  }, 3000);
                }
              }
              
          });
        }
      } else {
        toast.error(
          <React.Fragment>
            Unauthorized Access...! <br />
            Please Enter Valid Credentials.
          </React.Fragment>,
          {
            autoClose: 1500,
            style: {
              backgroundColor: "black",
              color: "white",
            },
          }
        );
      }
    });
  };

  return (
    <div>
      <div>
        <ToastContainer
          position="top-center"
          containerId="loginToast"
          className="mt-4"
          limit={1}
        />
      </div>
      <div className="container">
        <div className="row">
          <div className="col-lg-3 col-md-2"></div>
          <div className="col-lg-6 col-md-8 login-box">
            <div className="col-lg-12 login-key w-50 ms-5">
              {/* <i className="fa fa-key" aria-hidden="true"></i> */}
              <img src="/assets/img/logo.png" />
            </div>
            <div className="m-4"></div>
            <div className="col-lg-12 login-title">ADMIN PANEL</div>
            <div className="col-lg-12 login-form">
              <div className="col-lg-12 login-form">
                <form onSubmit={handleSubmit(onLogin)} method="post">
                  <div className="form-group">
                    <label className="form-control-label">USERNAME</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter your Username"
                      {...register("username", {
                        required: "Please enter your Username",
                      })}
                    />
                    {errors.username && (
                      <p className="text-danger">{errors.username.message}</p>
                    )}
                  </div>
                  <div className="form-group">
                    <label className="form-control-label">PASSWORD</label>
                    <input
                      type="password"
                      className="form-control"
                      placeholder="Enter your Password"
                      {...register("password", {
                        required: "Please enter Password",
                      })}
                    />
                    {errors.password && (
                      <p className="text-danger">{errors.password.message}</p>
                    )}
                  </div>

                  <div className="col-12 loginbttm">
                    <div className="col-8 login-btm login-text">
                      {/* <!-- Error Message --> */}
                    </div>
                    <div className="col-4 login-btm login-button">
                      <button
                        type="submit"
                        className="btn btn-outline fw-bold py-3 px-4"
                      >
                        LOGIN
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-lg-3 col-md-2"></div>
          </div>
        </div>
      </div>
    </div>
  );
}
