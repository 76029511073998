import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  getSubCategory,
  updateProductData,
} from "../../../../service/ecommerce.service";
import { useSelector } from "react-redux";
import Loader from "../../../layout/Loader";

export default function UpdateMenu() {
  let location = useLocation();
  let navigate = useNavigate();
  const token = useSelector((state) => state.status.token.token);
  const restaurantId = useSelector((state) => state.admin.admin.restaurant);
  const currencySymbol = useSelector(
    (state) => state?.restaurant?.restaurant?.currency_symbol
  );
  const [selectedCategory, setSelectedCategory] = useState("");
  const [categoryType, setCategoryType] = useState("All Time");
  const [deliveryType, setDeliveryType] = useState("Both");
  const [categoryData, setCategoryData] = useState();
  const [editedImage, setEditedImage] = useState();
  const [menuDetails, setMenuDetails] = useState();
  const [updateMenu, setUpdateMenu] = useState();
  const [isLoading, setIsLoading] = useState(true); // Add loading state

  const fetchCategory = () => {
    // setIsLoading(true);
    getSubCategory(token, restaurantId).then((res) => {
      setCategoryData(res.data);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    window.scrollTo(0, 1);
    if (location?.state?.menuDetails) {
        const initialUpdateProduct = { ...location.state.menuDetails };
        setMenuDetails(initialUpdateProduct);
        setUpdateMenu(initialUpdateProduct);
        setEditedImage(initialUpdateProduct.product_url);
        setSelectedCategory(initialUpdateProduct.category);
        setDeliveryType(initialUpdateProduct.delivery_type);
        if(initialUpdateProduct.available_time === 'All Time') {
            setCategoryType(initialUpdateProduct.available_time)
        } else {
            setCategoryType('Custom');
        }
        console.log(location?.state?.menuDetails)
      }
    fetchCategory();
  }, [location.state.menuDetails]);

  const {
    register,
    setError,
    clearErrors,
    handleSubmit: handleSubmitUpdate,
    formState: { errors },
  } = useForm();

  const updateImage = (event) => {
    const input = event.target;
    if (input.files && input.files[0]) {
      const reader = new FileReader();

      setEditedImage(input.files[0]);

      reader.onload = function (e) {
        // Update the src attribute of the displayed image
        document.getElementById("displayedImage").src = e.target.result;
        // Set the editedImage state with the new image data
      };

      // Read the chosen file as a data URL
      reader.readAsDataURL(input.files[0]);

      input.value = "";
    }
  };

  const handleUpdateImageClick = () => {
    // Trigger the click event on the file input when the displayed image is clicked
    document.getElementById("imageInputUpdate").click();
  };

  const errorMessages = {
    sequence: {
      message: "Enter Sequence",
      pattern: {
        value: /^\d*$/,
        message: "Sequence must contain only digits",
      },
      validate: (value) =>
        parseInt(value) > 0 || "Sequence must be greater than 0",
    },
    product_name: {
      message: "Enter Product Name",
    },
    MRP: {
      message: "Enter MRP",
      pattern: {
        value: /^\d*\.?\d*$/,
        message: "Please Enter Valid MRP",
      },
      validate: (value) =>
        parseFloat(value) > 0 || "Value must be greater than 0",
    },
    price: {
      message: "Enter Price",
      pattern: {
        value: /^\d*\.?\d*$/,
        message: "Please Enter Valid Price",
      },
      validate: (value) =>
        parseFloat(value) > 0 || "Value must be greater than 0",
    },
    extra: {
      message: "Write down Description",
    },
    tax_exempt: {
      message: "Select TAX",
    },
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // Check if the value is empty
    if (!value) {
      setError(name, { type: "manual", message: errorMessages[name].message });
    } else if (errorMessages[name].pattern) {
      const pattern = errorMessages[name].pattern.value;
      if (!pattern.test(value)) {
        setError(name, {
          type: "manual",
          message: errorMessages[name].pattern.message,
        });
      } else if (errorMessages[name].validate) {
        const validate = errorMessages[name].validate;
        const validationError = validate(value);
        if (validationError) {
          setError(name, { type: "manual", message: validationError });
        } else {
          clearErrors(name);
        }
      }
    } else {
      clearErrors(name);
    }

    setUpdateMenu({
      ...updateMenu,
      [name]: value,
    });
  };

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
    setError("category_name", { type: "manual", message: "" });
  };

  const updateData = () => {
    let data;
    if (typeof editedImage === "string") {
      // updateProduct.status = "INACTIVE";
      data = updateMenu;
    } else {
      updateMenu.image = editedImage;
      // updateMenu.status = "INACTIVE";
      data = updateMenu;
    }

    data.delivery_type = deliveryType;

    // console.log(data);
    updateProductData(token, data).then((res) => {
      console.log(res);
      toast.success("Menu Updated...!", {
        autoClose: 1500,
        style: {
          backgroundColor: "black",
          color: "white",
        },
      });
    });

    setTimeout(() => {
      navigate("/menu");
    }, 3000);
  };

  return (
    <div>
      {isLoading ? (
        // Conditional rendering of loader
        <Loader />
      ) : (
        <div className="page-content ms-3 fade-in-up">
          <ToastContainer position="top-center" className="mt-4" limit={1} />

          <div className="">
            <div className="ms-2 me-3">
              <form
                id="editForm"
                data-form-label="update"
                onSubmit={handleSubmitUpdate(updateData)}
              >
                <div className="modal-content product-modal">
                  <div className="modal-header">
                    <h4 className="modal-title fw-bold fs-5">UPDATE MENU</h4>
                    <button
                      type="button"
                      className="close"
                      onClick={() => window.history.back()}
                    >
                      &times;
                    </button>
                  </div>
                  <div className="modal-body product-data">
                    {/* <p>Some text in the modal.</p> */}
                    <table>
                      <tbody>
                        <tr>
                          <td>
                            <label>
                              Sequence <i className="fa fa-asterisk"></i>
                            </label>

                            <input
                                value={updateMenu.sequence}
                              {...register("sequence", {
                                required: "Enter Sequence",
                              })}
                              onChange={handleInputChange}
                            />
                            {errors.sequence && (
                              <p className="text-danger">
                                {errors.sequence.message}
                              </p>
                            )}
                          </td>
                          <td>
                            <label>
                              Menu Name <i className="fa fa-asterisk"></i>
                            </label>

                            <input
                                value={updateMenu.product_name}
                              {...register("product_name", {
                                required: "Enter Menu Name",
                              })}
                              onChange={handleInputChange}
                            />
                            {errors.product_name && (
                              <p className="text-danger">
                                {errors.product_name.message}
                              </p>
                            )}
                          </td>
                          <td>
                            <label>
                              Menu Price (MRP) ( {currencySymbol}{" "}
                              <i className="fa fa-asterisk"></i>)
                            </label>

                            <input
                                value={updateMenu.MRP}
                              {...register("MRP", {
                                required: "Enter Menu Price (MRP) ",
                                pattern: {
                                  value: /^\d*\.?\d*$/,
                                  message: "Please Enter Valid MRP",
                                },
                                validate: (value) =>
                                  parseFloat(value) > 0 ||
                                  "Value must be greater than 0",
                              })}
                              onChange={handleInputChange}
                            />
                            {errors.MRP && (
                              <p className="text-danger position-absolute">
                                {errors.MRP.message}
                              </p>
                            )}
                          </td>
                          <td>
                            <label>
                              Selling Price ( {currencySymbol}{" "}
                              <i className="fa fa-asterisk"></i>)
                            </label>

                            <input
                            value={updateMenu.price}
                              {...register("price", {
                                required: "Enter Selling Price",
                                pattern: {
                                  value: /^\d*\.?\d*$/,
                                  message: "Please Enter Valid Price",
                                },
                                validate: (value) =>
                                  parseFloat(value) > 0 ||
                                  "Value must be greater than 0",
                              })}
                              onChange={handleInputChange}
                            />
                            {errors.price && (
                              <p className="text-danger position-absolute">
                                {errors.price.message}
                              </p>
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <label>
                              Category <i className="fa fa-asterisk"></i>
                            </label>
                            <select
                              id="category"
                              value={selectedCategory}
                              className="form-select"
                              {...register("category_name", {
                                required: "Enter Sub Category",
                              })}
                              onChange={handleCategoryChange}
                            >
                              <option value="" disabled>
                                Select Sub Category
                              </option>
                              {categoryData &&
                                categoryData.map((category) => (
                                  <option
                                    key={category.category_id}
                                    value={category.category_id}
                                  >
                                    {category.category}
                                  </option>
                                ))}
                            </select>

                            {errors.category_name && (
                              <p className="text-danger position-absolute">
                                {errors.category_name.message}
                              </p>
                            )}
                          </td>
                          <td>
                            <label>
                              TAX <i className="fa fa-asterisk"></i>
                            </label>

                            <select
                             value={updateMenu.tax_exempt}
                              className="form-select"
                              defaultValue=""
                              {...register("tax_exempt", {
                                required: "Select TAX",
                              })}
                              onChange={handleInputChange}
                            >
                              <option disabled value="">
                                Select TAX
                              </option>
                              <option value="true">Yes</option>
                              <option value="false">No</option>
                            </select>
                            {errors.tax_exempt && (
                              <p className="text-danger position-absolute">
                                {errors.tax_exempt.message}
                              </p>
                            )}
                          </td>
                          <td colSpan={2}>
                            <label>
                              Delivery Type <i className="fa fa-asterisk"></i>
                            </label>
                            <div className="d-flex radio-button mt-1">
                              <div className="form-check mb-5 me-4">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="deliveryType"
                                  value="Both"
                                  checked={deliveryType === "Both"}
                                  onChange={() => setDeliveryType("Both")}
                                />
                                <label className="form-check-label">Both</label>
                              </div>
                              <div className="form-check mb-5 me-4">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="deliveryType"
                                  value="Delivery Only"
                                  checked={deliveryType === "Delivery Only"}
                                  onChange={() =>
                                    setDeliveryType("Delivery Only")
                                  }
                                />
                                <label className="form-check-label">
                                  Delivery Only
                                </label>
                              </div>
                              <div className="form-check mb-5">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="deliveryType"
                                  value="Pickup Only"
                                  checked={deliveryType === "Pickup Only"}
                                  onChange={() =>
                                    setDeliveryType("Pickup Only")
                                  }
                                />
                                <label className="form-check-label">
                                  Pickup Only
                                </label>
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={2}>
                            <label>
                              Category Availability Time{" "}
                              <i className="fa fa-asterisk"></i>
                            </label>
                            <div className="d-flex radio-button mt-1 mb-5">
                              <div className="form-check me-4">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="categoryAvailTime"
                                  value="All Time"
                                  checked={categoryType === "All Time"}
                                  onChange={() => setCategoryType("All Time")}
                                />
                                <label className="form-check-label">
                                  All Time
                                </label>
                              </div>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="categoryAvailTime"
                                  value="Custom"
                                  checked={categoryType === "Custom"}
                                  onChange={() => setCategoryType("Custom")}
                                />
                                <label className="form-check-label">
                                  Custom Time
                                </label>
                              </div>
                            </div>
                          </td>
                          {categoryType === "Custom" && (
                            <td>
                              <label>
                                Custom Time <i className="fa fa-asterisk"></i>
                              </label>
                              <input
                                value={updateMenu.available_time}
                                type="time"
                                {...register("customTime", {
                                  required: "Select time",
                                })}
                                placeholder="Select Time"
                              />
                              {errors.customTime && (
                                <p className="text-danger">
                                  {errors.customTime.message}
                                </p>
                              )}
                            </td>
                          )}
                        </tr>
                        <tr>
                          <td>
                            <label>
                              Product Image <i className="fa fa-asterisk"></i>
                            </label>

                            <input
                                type="file"
                                id="imageInputUpdate"
                                accept="image/*"
                                style={{ display: "none" }}
                                onChange={updateImage}
                              />
                              {/* Displayed image */}
                              <div className="ms-3">
                                <img
                                  src={editedImage}
                                  id="displayedImage"
                                  alt={updateMenu.product_url}
                                  onClick={() => handleUpdateImageClick()}
                                  // {...register("editedImage", {
                                  //   required: "Add Image",
                                  // })}
                                  // data-bs-dismiss="#editSubCategoryModal"
                                  // data-bs-target="#addImageModal"
                                  // data-bs-toggle="modal"
                                  // onClick={hideSubcategoryModal}
                                />
                              </div>

                            {errors.imageInputUpdate && (
                              <p className="text-danger position-absolute img-error">
                                {errors.imageInputUpdate.message}
                              </p>
                            )}
                          </td>
                          <td>
                            <label>
                              Product Description{" "}
                              <i className="fa fa-asterisk"></i>
                            </label>

                            <textarea
                            value={updateMenu.extra}
                              {...register("extra", {
                                required: "Write down Description",
                              })}
                              onChange={handleInputChange}
                            />
                            {errors.extra && (
                              <p className="text-danger position-absolute textarea">
                                {errors.extra.message}
                              </p>
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="submit"
                      className="btn btn-default"
                    >
                      UPDATE MENU
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
