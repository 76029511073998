import axios from "axios";

// const base_url = "https://restaurant60-be-dev-xtpocjmkpa-uw.a.run.app"; // GitHub dev

// const base_url = "https://instaapp-dev-xtpocjmkpa-uw.a.run.app" // Bit Bucket dev
// const storage_url = "https://storage.googleapis.com/restaurant60-dev-media" // image store url dev

const storage_url = "https://storage.googleapis.com/restaurant60-prod-media"; // image store url production
const base_url = "https://instaapp-prod-xtpocjmkpa-uw.a.run.app"; // Bit Bucket production

// ------------------------------ Restaurant Admin -----------------------------

export const restaurantAdmins = async (token, username, status) => {
  console.log(token, username, status);

  const url = `${base_url}/restaurantadmin/?username=${username}&status=${status}`;
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `token ${token}`,
    },
  };

  try {
    const response = await axios.get(url, config);
    return response;
  } catch (error) {
    if (
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500
    ) {
      console.log("Unauthorized Access.", error);
    } else {
      console.error(error);
      throw error;
    }
  }
};

export const getRestaurantDetails = async (token, restaurant_id) => {
  const url = `${base_url}/restaurant/${restaurant_id}/`;
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `token ${token}`,
    },
  };

  try {
    const response = await axios.get(url, config);
    return response;
  } catch (error) {
    if (
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500
    ) {
      console.log("Unauthorized Access.", error);
    } else {
      console.error(error);
      throw error;
    }
  }
};

export const updateBusinessDetails = async (token, data, restaurant_id) => {
  const url = `${base_url}/restaurant/${restaurant_id}/`;

  const formData = new FormData();

  Object.keys(data).forEach((key) => {
    if (key === "image" && data[key] instanceof File) {
      formData.append(key, data[key], data[key].name);
      formData.append("restaurant_url", `${storage_url}/${data[key].name}`);
    } else if (data[key] === null) {
      // Don't Append other data
    } else {
      // Append other data
      formData.append(key, data[key]);
    }
  });

  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `token ${token}`,
    },
  };

  try {
    const response = await axios.put(url, formData, config);
    return response;
  } catch (error) {
    if (
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500
    ) {
      console.log("Unauthorized Access.", error);
    } else {
      console.error(error);
      throw error;
    }
  }
};

export const getPlanDetails = async (token, restaurant_id) => {
  const url = `${base_url}/restaurants/${restaurant_id}/`;
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `token ${token}`,
    },
  };

  try {
    const response = await axios.get(url, config);
    return response;
  } catch (error) {
    if (
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500
    ) {
      console.log("Unauthorized Access.", error);
    } else {
      console.error(error);
      throw error;
    }
  }
};

export const getSubscriptionDetails = async (token, restaurant_id, plan_id) => {
  const url = `${base_url}/subscriptions/?restaurant_id=${restaurant_id}&plan_id=${plan_id}`;
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `token ${token}`,
    },
  };

  try {
    const response = await axios.get(url, config);
    return response;
  } catch (error) {
    if (
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500
    ) {
      console.log("Unauthorized Access.", error);
    } else {
      console.error(error);
      throw error;
    }
  }
};

// ------------------------------- Orders Tab ------------------------------------

export const getOrdersByStatus = async (token, page, restaurantId, status) => {
  const url = `${base_url}/order-payments/?page=${page}&status=${status}&restaurant_id=${restaurantId}`;
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `token ${token}`,
    },
  };

  try {
    const response = await axios.get(url, config);
    return response;
  } catch (error) {
    if (
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500
    ) {
      console.log("Unauthorized Access.", error);
    } else {
      console.error(error);
      throw error;
    }
  }
};

export const getProductDetails = async (token, order_id) => {
  const url = `${base_url}/order-items-x/?order_id=${order_id}`;
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `token ${token}`,
    },
  };

  try {
    const response = await axios.get(url, config);
    return response;
  } catch (error) {
    if (
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500
    ) {
      console.log("Unauthorized Access.", error);
    } else {
      console.error(error);
      throw error;
    }
  }
};

export const getPaymentDetails = async (token, order_id) => {
  const url = `${base_url}/order/${order_id}/`;
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `token ${token}`,
    },
  };

  try {
    const response = await axios.get(url, config);
    return response;
  } catch (error) {
    if (
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500
    ) {
      console.log("Unauthorized Access.", error);
    } else {
      console.error(error);
      throw error;
    }
  }
};

export const createDeliveryOrder = async (token, data) => {
  const url = `${base_url}/create-delhivery-order/`;
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `token ${token}`,
    },
  };

  try {
    const response = await axios.post(url, data, config);
    return response;
  } catch (error) {
    if (
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500
    ) {
      console.log("Unauthorized Access.", error);
    } else {
      console.log(error);
      throw error;
    }
  }
};

export const createCancelOrder = async (token, data) => {
  const url = `${base_url}/cancel_order/`;
  console.log(data);
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `token ${token}`,
    },
  };

  try {
    const response = await axios.post(url, data, config);
    return response;
  } catch (error) {
    if (
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500
    ) {
      console.log("Unauthorized Access.", error);
    } else {
      console.log(error);
      throw error;
    }
  }
};

export const markProductUnavailable = async (token, order_item_id, data) => {
  const url = `${base_url}/order-item-x/${order_item_id}/`;
  console.log(data);
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `token ${token}`,
    },
  };

  try {
    const response = await axios.put(url, data, config);
    return response;
  } catch (error) {
    console.log(error);
    if (
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500
    ) {
      console.log("Unauthorized Access.", error);
    } else {
      console.error(error);
      throw error;
    }
  }
};

export const trackOrderDetails = async (token, waybill) => {
  const url = `${base_url}/order_tracking/?waybill=${waybill}`;
  console.log(token, waybill);
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `token ${token}`,
    },
  };

  try {
    const response = await axios.get(url, config);
    return response;
  } catch (error) {
    console.log(error);
    if (
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500
    ) {
      console.log("Unauthorized Access.", error);
    } else {
      console.error(error);
      throw error;
    }
  }
};

export const getShippingDetails = async (token, data) => {
  const url = `${base_url}/admin-shipping-calculation/`;
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `token ${token}`,
    },
  };

  try {
    const response = await axios.post(url, data, config);
    return response;
  } catch (error) {
    if (
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500
    ) {
      console.log("Unauthorized Access.", error);
    } else {
      console.log(error);
      throw error;
    }
  }
};

// ------------------------------ Category Tab ---------------------------------

export const getMasterCategory = async (token, restaurantId) => {
  const url = `${base_url}/get-master-category/${restaurantId}/`;
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `token ${token}`,
    },
  };

  try {
    const response = await axios.get(url, config);
    return response;
  } catch (error) {
    if (
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500
    ) {
      console.log("Unauthorized Access.", error);
    } else {
      console.error(error);
      throw error;
    }
  }
};

export const updateCategoryData = async (token, data) => {
  const url = `${base_url}/get-master-category/${data.master_category_id}/`;

  console.log(data, "this is the data");

  const formData = new FormData();

  Object.keys(data).forEach((key) => {
    if (
      key === "image" &&
      data[key] &&
      typeof data[key] !== "string" instanceof File
    ) {
      formData.append(key, data[key], data[key].name);
      formData.append("image_url", `${storage_url}/${data[key].name}`);
    } else if (
      (key === "image" && typeof data[key] === "string") ||
      (key === "image" && data[key] === null)
    ) {
    } else {
      // Append other data
      formData.append(key, data[key]);
    }
  });

  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `token ${token}`,
    },
  };

  try {
    const response = await axios.put(url, formData, config);
    return response;
  } catch (error) {
    if (
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500
    ) {
      console.log("Unauthorized Access.", error);
    } else {
      console.log(error);
      throw error;
    }
  }
};

export const deleteCategoryData = async (token, master_category_id) => {
  const url = `${base_url}/get-master-category/${master_category_id}/`;

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `token ${token}`,
    },
  };

  try {
    const response = await axios.delete(url, config);
    return response;
  } catch (error) {
    if (
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500
    ) {
      console.log("Unauthorized Access.", error);
    } else {
      console.error(error);
      throw error;
    }
  }
};

export const addCategoryData = async (token, data) => {
  const url = `${base_url}/get-master-category/`;

  const formData = new FormData();

  Object.keys(data).forEach((key) => {
    if (key === "image" && data[key] instanceof File) {
      formData.append(key, data[key], data[key].name);
      formData.append("image_url", `${storage_url}/${data[key].name}`);
    } else {
      // Append other data
      formData.append(key, data[key]);
    }
  });

  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `token ${token}`,
    },
  };

  try {
    const response = await axios.post(url, formData, config);
    return response;
  } catch (error) {
    if (
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500
    ) {
      console.log("Unauthorized Access.", error);
    } else {
      console.error(error);
      throw error;
    }
  }
};

export const addOpenHours = async (token, data) => {
  const url = `${base_url}/open-hours/`;

  const formData = new FormData();

  Object.keys(data).forEach((key) => {
    formData.append(key, data[key]);
  });

  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `token ${token}`,
    },
  };

  try {
    const response = await axios.post(url, formData, config);
    return response;
  } catch (error) {
    if (
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500
    ) {
      console.log("Unauthorized Access.", error);
    } else {
      console.error(error);
      throw error;
    }
  }
};

// ------------------------------ Sub Category Tab ---------------------------------

export const getSubCategory = async (token, restaurantId) => {
  const url = `${base_url}/v2/category/?restaurant_id=${restaurantId}`;
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `token ${token}`,
    },
  };

  try {
    const response = await axios.get(url, config);
    return response;
  } catch (error) {
    if (
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500
    ) {
      console.log("Unauthorized Access.", error);
    } else {
      console.error(error);
      throw error;
    }
  }
};

export const updateSubCategorySequence = async (token, types, data) => {
  const url = `${base_url}/v2/sequence/`;

  const body = {
    types: types,
    data: data,
  };

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `token ${token}`,
    },
  };

  // console.log(url);

  try {
    const response = await axios.put(url, body, config);
    return response;
  } catch (error) {
    if (
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500
    ) {
      console.log("Unauthorized Access.", error);
    } else {
      console.error(error);
      throw error;
    }
  }
};

export const updateSubCategoryData = async (token, data) => {
  const url = `${base_url}/v2/category/${data.category_id}/`;

  const formData = new FormData();

  Object.keys(data).forEach((key) => {
    if (key === "image" && data[key] instanceof File) {
      formData.append(key, data[key], data[key].name);
      formData.append("category_url", `${storage_url}/${data[key].name}`);
    } else if (data[key] === null) {
      // Append other data
    } else {
      // Append other data
      formData.append(key, data[key]);
    }
  });

  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `token ${token}`,
    },
  };

  console.log(data);

  try {
    const response = await axios.put(url, formData, config);
    return response;
  } catch (error) {
    if (
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500
    ) {
      console.log("Unauthorized Access.", error);
    } else {
      console.error(error);
      throw error;
    }
  }
};

export const addSubCategoryData = async (token, data) => {
  const url = `${base_url}/v2/category/`;

  const formData = new FormData();

  Object.keys(data).forEach((key) => {
    if (key === "image" && data[key] instanceof File) {
      formData.append(key, data[key], data[key].name);
      formData.append("category_url", `${storage_url}/${data[key].name}`);
    } else {
      // Append other data
      formData.append(key, data[key]);
    }
  });

  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `token ${token}`,
    },
  };

  try {
    const response = await axios.post(url, formData, config);
    return response;
  } catch (error) {
    if (
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500
    ) {
      console.log("Unauthorized Access.", error);
    } else {
      console.error(error);
      throw error;
    }
  }
};

export const deleteSubCategoryData = async (token, category_id) => {
  const url = `${base_url}/v2/category/${category_id}/`;

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `token ${token}`,
    },
  };

  try {
    const response = await axios.delete(url, config);
    return response;
  } catch (error) {
    if (
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500
    ) {
      console.log("Unauthorized Access.", error);
    } else {
      console.error(error);
      throw error;
    }
  }
};

// ----------------------------- Products Tab --------------------------------------------

export const getAllProducts = async (token, page, restaurantId) => {
  const url = `${base_url}/v2/catalog/?page=${page}&restaurant_id=${restaurantId}`;
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `token ${token}`,
    },
  };

  try {
    const response = await axios.get(url, config);
    return response;
  } catch (error) {
    if (
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500
    ) {
      console.log("Unauthorized Access.", error);
    } else {
      console.error(error);
      throw error;
    }
  }
};

export const addNewProduct = async (token, data) => {
  const url = `${base_url}/v2/catalog/`;

  const formData = new FormData();

  Object.keys(data).forEach((key) => {
    if (key === "image" && data[key] instanceof File) {
      formData.append(key, data[key], data[key].name);
      formData.append("product_url", `${storage_url}/${data[key].name}`);
    } else {
      // Append other data
      formData.append(key, data[key]);
    }
  });

  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `token ${token}`,
    },
  };

  try {
    const response = await axios.post(url, formData, config);
    return response;
  } catch (error) {
    if (
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500
    ) {
      console.log("Unauthorized Access.", error);
    } else {
      console.error(error);
      throw error;
    }
  }
};

export const updateProductData = async (token, data) => {
  const url = `${base_url}/v2/catalog/${data.product_id}/`;
  console.log(data);

  const formData = new FormData();

  Object.keys(data).forEach((key) => {
    if (
      key === "image" &&
      data[key] !== null &&
      data[key] &&
      typeof data[key] !== "string" instanceof File
    ) {
      formData.append(key, data[key], data[key].name);
      formData.append("product_url", `${storage_url}/${data[key].name}`);
    } else if ((key === "image" && data[key] === null) || data[key] === null) {
      // Append other data
    } else if (key === "image" && typeof data[key] === "string") {
      // Append other data
    } else {
      formData.append(key, data[key]);
    }
  });

  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `token ${token}`,
    },
  };

  try {
    const response = await axios.put(url, formData, config);
    return response;
  } catch (error) {
    if (
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500
    ) {
      console.log("Unauthorized Access.", error);
    } else {
      console.error(error);
      throw error;
    }
  }
};

export const getProductsByColorSize = async (token, product_id) => {
  const url = `${base_url}/get-product-size-color/?type=All&product_id=${product_id}`;
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `token ${token}`,
    },
  };

  try {
    const response = await axios.get(url, config);
    return response;
  } catch (error) {
    if (
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500
    ) {
      console.log("Unauthorized Access.", error);
    } else {
      console.error(error);
      throw error;
    }
  }
};

export const updateProductSizeColor = async (token, data) => {
  const url = `${base_url}/get-product-size-color/${data.color_id}/`;
  console.log(data);
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `token ${token}`,
    },
  };

  try {
    const response = await axios.patch(url, data, config);
    return response;
  } catch (error) {
    if (
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500
    ) {
      console.log("Unauthorized Access.", error);
    } else {
      console.error(error);
      throw error;
    }
  }
};

export const addProductSizeColor = async (token, data) => {
  const url = `${base_url}/get-product-size-color/`;

  console.log(token, data);

  const formData = new FormData();

  Object.keys(data).forEach((key) => {
    if (key === "image" && Array.isArray(data[key]) && data[key].length > 0) {
      data[key].forEach((file, index) => {
        if (file instanceof File) {
          formData.append(key, file, file.name);
          // formData.append(
          //   "image_url",
          //   `${storage_url}/${file.name}`
          // );
        }
      });
    } else {
      // Append other data
      formData.append(key, data[key]);
    }
  });

  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `token ${token}`,
    },
  };

  try {
    const response = await axios.post(url, formData, config);
    return response;
  } catch (error) {
    if (
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500
    ) {
      console.log("Unauthorized Access.", error);
    } else {
      console.log(error);
      throw error;
    }
  }
};

export const deleteProductSizeColor = async (token, color_id) => {
  const url = `${base_url}/product-color/${color_id}/`;

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `token ${token}`,
    },
  };

  try {
    const response = await axios.delete(url, config);
    return response;
  } catch (error) {
    if (
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500
    ) {
      console.log("Unauthorized Access.", error);
    } else {
      console.error(error);
      throw error;
    }
  }
};

export const getProductSpecification = async (token, product_id) => {
  const url = `${base_url}/product-specification/?product_id=${product_id}&type=Specification`;
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `token ${token}`,
    },
  };

  try {
    const response = await axios.get(url, config);
    return response;
  } catch (error) {
    if (
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500
    ) {
      console.log("Unauthorized Access.", error);
    } else {
      console.error(error);
      throw error;
    }
  }
};

export const getSpecificationLabel = async (token, industry_type) => {
  const url = `${base_url}/get-overview-labels/?industry_type=${industry_type}`;
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `token ${token}`,
    },
  };

  try {
    const response = await axios.get(url, config);
    return response;
  } catch (error) {
    if (
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500
    ) {
      console.log("Unauthorized Access.", error);
    } else {
      console.error(error);
      throw error;
    }
  }
};

export const addProductSpecification = async (token, data) => {
  const url = `${base_url}/product-specification/`;
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `token ${token}`,
    },
  };

  try {
    const response = await axios.post(url, data, config);
    return response;
  } catch (error) {
    if (
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500
    ) {
      console.log("Unauthorized Access.", error);
    } else {
      console.error(error);
      throw error;
    }
  }
};

export const deleteProductSpecification = async (token, specification_id) => {
  const url = `${base_url}/product-specification/${specification_id}`;
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `token ${token}`,
    },
  };

  try {
    const response = await axios.delete(url, config);
    return response;
  } catch (error) {
    if (
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500
    ) {
      console.log("Unauthorized Access.", error);
    } else {
      console.error(error);
      throw error;
    }
  }
};

// -------------------------------------------------------------------------------------

export const deleteProductData = async (token, category_id) => {
  const url = `${base_url}/v2/category/${category_id}/`;

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `token ${token}`,
    },
  };

  try {
    const response = await axios.delete(url, config);
    return response;
  } catch (error) {
    if (
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500
    ) {
      console.log("Unauthorized Access.", error);
    } else {
      console.error(error);
      throw error;
    }
  }
};

export const updateProductSequence = async (token, types, data) => {
  const url = `${base_url}/v2/sequence/`;

  const body = {
    types: types,
    data: data,
  };

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `token ${token}`,
    },
  };

  try {
    const response = await axios.put(url, body, config);
    return response;
  } catch (error) {
    if (
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500
    ) {
      console.log("Unauthorized Access.", error);
    } else {
      console.error(error);
      throw error;
    }
  }
};

export const markOrderDelivered = async (token, paymentID, data) => {
  const url = `${base_url}/order-payment/${paymentID}/`;
  console.log(token, paymentID, data);
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `token ${token}`,
    },
  };

  try {
    const response = await axios.put(url, data, config);
    return response;
  } catch (error) {
    console.log(error);
    if (
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500
    ) {
      console.log("Unauthorized Access.", error);
    } else {
      console.error(error);
      throw error;
    }
  }
};

// ---------------------------- Coupons Tab ------------------------------------------------
export const getAllCoupons = async (token, restaurantId) => {
  const url = `${base_url}/coupon/?restaurant_id=${restaurantId}`;
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `token ${token}`,
    },
  };

  try {
    const response = await axios.get(url, config);
    return response;
  } catch (error) {
    if (
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500
    ) {
      console.log("Unauthorized Access.", error);
    } else {
      console.error(error);
      throw error;
    }
  }
};

export const updateSingleCoupon = async (token, data) => {
  const url = `${base_url}/coupon/${data.coupon_id}/`;
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `token ${token}`,
    },
  };

  try {
    const response = await axios.put(url, data, config);
    return response;
  } catch (error) {
    if (
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500
    ) {
      console.log("Unauthorized Access.", error);
    } else {
      console.error(error);
      throw error;
    }
  }
};

export const addSingleCoupon = async (token, data) => {
  const url = `${base_url}/coupon/`;
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `token ${token}`,
    },
  };

  try {
    const response = await axios.post(url, data, config);
    return response;
  } catch (error) {
    if (
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500
    ) {
      console.log("Unauthorized Access.", error);
    } else {
      console.error(error);
      throw error;
    }
  }
};

export const deleteSingleCoupon = async (token, coupon_id) => {
  const url = `${base_url}/coupon/${coupon_id}/`;
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `token ${token}`,
    },
  };

  try {
    const response = await axios.delete(url, config);
    return response;
  } catch (error) {
    if (
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500
    ) {
      console.log("Unauthorized Access.", error);
    } else {
      console.error(error);
      throw error;
    }
  }
};

// ---------------------------- Setup Tab -----------------------------------------------------

// Invoice

export const saveInvoicePdf = async (token, formData, order_id) => {
  const url = `${base_url}/order/${order_id}/`;

  const config = {
    headers: {
      "Content-Type": "tiparmult/form-data",
      Authorization: `token ${token}`,
    },
  };

  try {
    const response = await axios.patch(url, formData, config);
    return response;
  } catch (error) {
    if (
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500
    ) {
      console.log("Unauthorized Access.", error);
    } else {
      console.error(error);
      throw error;
    }
  }
};

export const createClientSubscription = async (token, data) => {
  const url = `${base_url}/razorpay-subscription/`;
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `token ${token}`,
    },
  };

  try {
    const response = await axios.post(url, data, config);
    return response;
  } catch (error) {
    if (
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500
    ) {
      console.log("Unauthorized Access.", error);
    } else {
      console.log(error);
      throw error;
    }
  }
};

export const subscriptionActiveted = async (token, id) => {
  const url = `${base_url}/razorpay-subscription/${id}/`;

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `token ${token}`,
    },
  };

  try {
    const response = await axios.patch(url, {}, config);
    return response;
  } catch (error) {
    if (
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500
    ) {
      console.log("Unauthorized Access.", error);
    } else {
      console.log(error);
      throw error;
    }
  }
};

export const subscriptionCancelationRequest = async (token, id) => {
  console.log(token, id);

  const url = `${base_url}/razorpay-subscription/${id}/`;
  console.log(url)

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `token ${token}`,
    },
  };

  try {
    const response = await axios.delete(url, config);
    return response;
  } catch (error) {
    if (
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500
    ) {
      console.log("Unauthorized Access.", error);
    } else {
      console.log(error);
      throw error;
    }
  }
};
