import React, { useEffect, useState } from "react";
import Loader from "../../layout/Loader";
import { ToastContainer, toast } from "react-toastify";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import ReactPaginate from "react-paginate";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import update from "immutability-helper";
import {
  deleteSubCategoryData,
  getAllProducts,
  getSubCategory,
  updateProductData,
  updateProductSequence,
} from "../../../service/ecommerce.service";
import { useForm } from "react-hook-form";

export default function Menu() {
  const navigate = useNavigate();
  const token = useSelector((state) => state?.status?.token?.token);
  const restaurantId = useSelector((state) => state?.admin?.admin?.restaurant);
  const currencySymbol = useSelector(
    (state) => state?.restaurant?.restaurant?.currency_symbol
  );
  const [isLoading, setIsLoading] = useState(false);
  const [menuData, setMenuData] = useState([]);
  const [count, setCount] = useState();
  const [page, setPage] = useState(1);
  const [isButtonActive, setIsButtonActive] = useState(false); // Added state for button
  const [searchQuery, setSearchQuery] = useState("");

  const [itemsPerPage, setItemsPerPage] = useState(30);

  const startIndex = (page - 1) * itemsPerPage + 1;
  const endIndex = Math.min(startIndex + itemsPerPage - 1, count);

  const fetchProducts = (pageNo) => {
    setIsLoading(true);     
    getAllProducts(token, pageNo, restaurantId).then((res) => {
      console.log("res......", res);
      const sortData = res?.data?.results?.sort((a,b) => b.product_id - a.product_id);
      setMenuData(sortData);
      setCount(res?.data?.count);
      setIsLoading(false);
    });
  };
  const handlePageChange = ({ selected }) => {
    const newPage = selected + 1;
    setPage(newPage);
  };

  useEffect(() => {
    window.scrollTo(0, 1);
    fetchProducts(page);
  }, [page]);

  // Function to toggle button's active state
  const toggleButton = () => {
    setIsButtonActive(!isButtonActive);
  };

  const moveRow = (dragIndex, hoverIndex) => {
    const startIndex = (page - 1) * itemsPerPage;  // ✅ Correct base index
    const updatedDragIndex = startIndex + dragIndex;
    const updatedHoverIndex = startIndex + hoverIndex;

    if (
        updatedDragIndex < 0 || updatedDragIndex >= menuData.length ||
        updatedHoverIndex < 0 || updatedHoverIndex >= menuData.length
    ) {
        console.error("Invalid index values, skipping moveRow");
        return;
    }

    const draggedRow = menuData[updatedDragIndex];
    
    const updatedOrders = [...menuData];  // ✅ Create a new array
    updatedOrders.splice(updatedDragIndex, 1);
    updatedOrders.splice(updatedHoverIndex, 0, draggedRow);

    console.log("updatedOrders.....", updatedOrders);

    setMenuData(updatedOrders);  // ✅ Update state

    const data = updatedOrders.map((order, i) => ({
      sequence: i + 1,
      product_id: order?.product_id ?? null,  // ✅ Prevent `undefined`
    }));

    console.log("data.....", data);

    // ✅ Ensure `data` is valid before calling API
    if (!data.length || data.some((item) => item.product_id === null)) {
        console.error("Invalid data, skipping API call.");
        return;
    }

    // ✅ Send a properly formatted JSON string
    try {
        updateProductSequence(token, "Product", JSON.stringify(data));
    } catch (error) {
        console.error("Error sending request:", error);
    }
  };

   // Function to handle editing
   const handleEdit = (item) => {
    // console.log(item);
    navigate("/update-menu", {
      state: {
        menuDetails: item,
      },
    });
  };

  const handleStatusChange = (data) => {
    if (data.status === "INACTIVE") {
      data.status = "ACTIVE";
    } else {
      data.status = "INACTIVE";
    }
    // console.log(data);
    updateProductData(token, data).then((res) => {
      fetchProducts(page);
      // console.log(res);
    });
  };

  const handleInStock = (data) => {
    data.in_stock = !data.in_stock;

    // console.log(data);
    updateProductData(token, data).then((res) => {
      fetchProducts(page);
      // console.log(res);
    });
  };

  return (
    <div>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="page-content ms-3 fade-in-up">
          <ToastContainer position="top-center" className="mt-4" limit={1} />
          <div className="ibox">
            <div className="ibox-body m-3">
              <Link to="/add-menu">
                <div
                  className={`sticky-button ${isButtonActive ? "active" : ""}`}
                  onClick={() => toggleButton()}
                  // data-toggle="modal"
                  // data-target="#addSubCategoryModal"
                >
                  <i className="fa fa-plus"></i>
                </div>
              </Link>
              <div className="d-flex justify-content-between my-3">
                <div>
                  Show{" "}
                  <select
                    value={itemsPerPage}
                    onChange={(e) =>
                      setItemsPerPage(parseInt(e.target.value, 10))
                    }
                  >
                    {[30].map((num) => (
                      <option key={num} value={num}>
                        {num}
                      </option>
                    ))}
                  </select>{" "}
                  Results
                </div>
                <div className="floating-notification fw-light">
                  NOTE - For Sequence Change You just have to Drag the Rows...!
                </div>
                <div>
                  Search:{" "}
                  <input
                    type="text"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    className="btn-outline-secondary"
                  />
                </div>
              </div>

              <DragDropContext
              // onDragEnd={handleDragEnd}
              onDragEnd={(result) =>
                moveRow(result.source.index, result.destination.index)
              }
              >
                <Droppable droppableId="categories">
                  {(provided) => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                      <table className="table table-striped table-bordered table-hover">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Menu Name</th>
                            <th>Category</th>
                            <th>Sequence</th>
                            <th>Image</th>
                            <th>Selling Price</th>
                            <th>Menu Price(MRP)</th>
                            <th>Action</th>
                            <th>Live</th>
                            <th>In Stock</th>
                          </tr>
                        </thead>
                        <tbody>
                          {menuData
                            ?.filter(
                              (item) =>
                                item.product_name
                                  ?.toLowerCase()
                                  .includes(searchQuery.toLowerCase()) ||
                                item.category_name
                                  ?.toLowerCase()
                                  .includes(searchQuery.toLowerCase()) ||
                                  item.sequence
                                  ?.toString()
                                  .includes(searchQuery.toLowerCase()) ||
                                  item.selling_price
                                  ?.toString()
                                  .includes(searchQuery.toLowerCase()) ||
                                  item.mrp
                                  ?.toString()
                                  .includes(searchQuery.toLowerCase())
                            )
                            .map((item, index) => (
                              <Draggable
                                key={item.id || `draggable-${index}`}
                                draggableId={
                                  item.id
                                    ? item.id.toString()
                                    : `draggable-${index}`
                                }
                                index={index}
                              >
                                {(provided) => (
                                  <TableRow
                                    provided={provided}
                                    index={startIndex + index - 1}
                                    item={item}
                                    currencySymbol={currencySymbol}
                                    moveRow={moveRow}
                                    handleEdit={handleEdit}
                                    handleStatusChange={handleStatusChange}
                                    handleInStock={handleInStock}
                                  />
                                )}
                              </Draggable>
                            ))}
                        </tbody>
                      </table>
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
              {count > itemsPerPage && (
                <div className="d-flex justify-content-between my-3">
                  <div className="fw-light">
                    Showing {count > 0 ? startIndex : 0} to{" "}
                    {count > 0 ? endIndex : 0} of {count} entries
                  </div>
                  <div>
                    {count > itemsPerPage && (
                      <ReactPaginate
                        previousLabel={
                          <button
                            type="button"
                            className="btn btn-outline-secondary w-100"
                          >
                            Previous
                          </button>
                        }
                        nextLabel={
                          <button
                            type="button"
                            className="btn btn-outline-secondary w-100"
                          >
                            Next
                          </button>
                        }
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={Math.ceil(count / itemsPerPage)}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={handlePageChange}
                        containerClassName={"pagination"}
                        subContainerClassName={"pages pagination"}
                        activeClassName={
                          "active-class btn btn-outline-secondary w-100 fw-bold"
                        }
                        pageClassName={
                          "page-class btn btn-outline-secondary w-100"
                        }
                      />
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

// TableRow Component
const TableRow = ({ provided, item, index, currencySymbol, moveRow, handleEdit, handleStatusChange, handleInStock }) => (
  <tr
  ref={provided.innerRef}
  {...provided.draggableProps}
  {...provided.dragHandleProps}
  >
    <td>{index + 1}</td>
    <td>{item.product_name || "N/A"}</td>
    <td>{item.category_name || "N/A"}</td>
    <td>{item.sequence || "N/A"}</td>
    <td>
      {item.product_url ? (
        <img
          src={item.product_url}
          alt="Menu"
          style={{ width: "50px", height: "50px" }}
        />
      ) : (
        "No Image"
      )}
    </td>
    <td>
      {currencySymbol}
      {item.price || "0.00"}
    </td>
    <td>
      {currencySymbol}
      {item.MRP || "0.00"}
    </td>
    <td>
      {item.status === "ACTIVE" ? (
        <i
          className="fa fa-pencil popup me-3"
          onClick={() => handleEdit(item)}
        ></i>
      ) : (
        <i className="fa fa-pencil disabled-pencil me-3"></i>
      )}

      {/* <i
        className="fa fa-puzzle-piece me-3"
        // onClick={() => handleAddProductDetails(item)}
      ></i> */}

      {/* <i
        className="fa fa-list-alt "
        // onClick={() => handleSpecification(item)}
      ></i> */}
    </td>
    <td>
      <div className="form-check product-check form-switch d-flex justify-content-center fs-4">
        <input
          className="form-check-input product-input"
          type="checkbox"
          id={`flexSwitchCheckChecked_${item.product_id}`}
          value={item.status === "ACTIVE" ? true : false}
          checked={item.status === "ACTIVE" ? true : false}
          onChange={() => handleStatusChange(item)}
        />
      </div>
    </td>
    <td>
      <div className="form-check product-check form-switch d-flex justify-content-center fs-4">
        <input
          className="form-check-input product-input"
          type="checkbox"
          id={`flexSwitchCheckChecked_${item.product_id}`}
          value={item.in_stock}
          checked={item.in_stock}
          onChange={() => handleInStock(item)}
        />
      </div>
    </td>
  </tr>
);
