import React, { useEffect, useState } from "react";
import Loader from "../../layout/Loader";
import { ToastContainer, toast } from "react-toastify";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import ReactPaginate from "react-paginate";
import { useSelector } from "react-redux";
// import update from "immutability-helper";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { deleteOnboardingDetails, getOnboardingDetails } from "../../../service/superAdmin.service";

export default function Onboarding() {
  const navigate = useNavigate();
  const token = useSelector((state) => state?.status?.token?.token);
  const [isLoading, setIsLoading] = useState(false);
  const [onboardingData, setOnboardingData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [isButtonActive, setIsButtonActive] = useState(false);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [deletedItem, setDeletedItem] = useState(null);
  const [onboardingViewDetails, setOnboardingViewDetails] = useState("");

  // Fetch onboarding data with pagination
  const fetchOnboardingData = async (pageNumber) => {
    setIsLoading(true);
    getOnboardingDetails(token, pageNumber).then((res) => {
      setOnboardingData(res?.data);
      setCount(res?.data?.length);
      setIsLoading(false);
    });
  };
  // Call the get onboarding details API
  useEffect(() => {
    window.scrollTo(0, 1);
    fetchOnboardingData(page);
  }, [page]);

  // Filtered the onboarding details
  const filteredOnboarding = onboardingData.filter(
    (item) =>
      item.name
        .toLowerCase()
        .includes(searchQuery.replace(/\s+/g, " ").trim().toLowerCase()) ||
      item.restaurant_name
        .toLowerCase()
        .includes(searchQuery.replace(/\s+/g, " ").trim().toLowerCase()) ||
      item.email
        .toLowerCase()
        .includes(searchQuery.replace(/\s+/g, " ").trim().toLowerCase()) ||
      item.phone_no
        .toLowerCase()
        .includes(searchQuery.replace(/\s+/g, " ").trim().toLowerCase()) ||
      item.created_at
        .toLowerCase()
        .includes(searchQuery.replace(/\s+/g, " ").trim().toLowerCase())
  );

  const startIndex = currentPage * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const paginatedData = filteredOnboarding.slice(startIndex, endIndex);

  // handle Items as per page
  const handleItemsPerPageChange = (e) => {
    setItemsPerPage(parseInt(e.target.value, 10));
    setCurrentPage(0);
    setPage(1); // Reset to first page whenever the items per page is changed
  };

  // set the current state of page
  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  // set the search change value
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  // Function to toggle button's active state
  const toggleButton = () => {
    setIsButtonActive(!isButtonActive);
  };

  // delete form
  const {
    register: register2,
    handleSubmit: handleSubmitDelete,
    watch,
    reset: reset2,
    formState: { errors: errors2 },
  } = useForm();

  // handle View
  const handleView = (item) => {
    setOnboardingViewDetails(item);
  };

  // Function to handle editing
  const handleDelete = (item) => {
    reset2({ confirmOnboarding: "" });
    setDeletedItem(item);
  };

  // Delete method
  const deleteData = () => {
    deleteOnboardingDetails(token,deletedItem?.id).then((res) => {
      toast.error("Onboarding Request Deleted...!", {
        autoClose: 1500,
        style: {
          backgroundColor: "black",
          color: "white",
        },
      });
      reset2({ confirmBusiness: "" });
      document.getElementById("closeDeleteModal").click();
      fetchOnboardingData(1);
    });
  };

  return (
    <div>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="page-content ms-3 fade-in-up">
          <ToastContainer position="top-center" className="mt-4" limit={1} />

          <div className="ibox">
            <div className="ibox-body m-3">
              <div className="d-flex justify-content-between my-3">
                <div className="fw-light">
                  Show{" "}
                  <select
                    value={itemsPerPage}
                    onChange={handleItemsPerPageChange}
                    className="fw-light"
                  >
                    {/* <option value="5">5</option> */}
                    <option value="10">10</option>
                    {/* <option value="15">15</option> */}
                    <option value="20">20</option>
                    {/* <option value="25">25</option> */}
                    <option value="30">30</option>
                    <option value="40">40</option>
                    <option value="50">50</option>
                    <option value="60">60</option>
                    <option value="70">70</option>
                    <option value="80">80</option>
                    <option value="90">90</option>
                    <option value="100">100</option>
                  </select>{" "}
                  Results
                </div>
                <div className="fw-light">
                  Search:{" "}
                  <input
                    type="text"
                    value={searchQuery}
                    onChange={handleSearchChange}
                    className="btn-outline-secondary"
                  />
                </div>
              </div>

              <DragDropContext>
                <Droppable droppableId="Products">
                  {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      <table
                        className="table table-striped table-bordered table-hover"
                        cellSpacing="0"
                        width="100%"
                      >
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Name</th>
                            <th>Business Name</th>
                            <th>Email</th>
                            <th>Phone Number</th>
                            <th>Created (Date/Time)</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {paginatedData.map((item, i) => (
                            <Draggable
                              key={item.id}
                              draggableId={item.id.toString()}
                              index={i}
                            >
                              {(provided) => (
                                <TableRow
                                  provided={provided}
                                  key={i}
                                  index={startIndex + i}
                                  item={item}
                                  handleView={handleView}
                                  handleDelete={handleDelete}
                                />
                              )}
                            </Draggable>
                          ))}
                        </tbody>
                      </table>
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>

              {count > itemsPerPage && (
                <div className="d-flex justify-content-between my-3">
                  <div className="fw-light">
                    Showing{" "}
                    {filteredOnboarding.length > 0
                      ? startIndex + 1
                      : startIndex}{" "}
                    to {Math.min(endIndex, filteredOnboarding.length)} of{" "}
                    {filteredOnboarding.length} entries
                  </div>
                  <div>
                    {filteredOnboarding.length > itemsPerPage && (
                      <ReactPaginate
                        previousLabel={
                          <button
                            type="button"
                            className="btn btn-outline-secondary w-100"
                          >
                            Previous
                          </button>
                        }
                        nextLabel={
                          <button
                            type="button"
                            className="btn btn-outline-secondary w-100"
                          >
                            Next
                          </button>
                        }
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={Math.ceil(
                          filteredOnboarding.length / itemsPerPage
                        )}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={handlePageChange}
                        containerClassName={"pagination"}
                        subContainerClassName={"pages pagination"}
                        activeClassName={
                          "active-class btn btn-outline-secondary w-100 fw-bold"
                        }
                        pageClassName={
                          "page-class btn btn-outline-secondary w-100"
                        }
                      />
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      {/* <!-- Delete Modal --> */}
      <div className="container ">
        <div className="modal fade" id="deleteOnboardingModal" role="dialog">
          <div className="modal-dialog">
            {/* <!-- Delete Modal content--> */}
            <form
              id="deleteForm"
              data-form-label="delete"
              onSubmit={handleSubmitDelete(deleteData)}
            >
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title fw-bold">WARNING</h4>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    id="closeDeleteModal"
                  >
                    &times;
                  </button>
                </div>
                <div className="modal-body">
                  <p className="fs-5 fw-bold">
                    Do you want to delete this 'Onboarding Request'!
                  </p>
                </div>
                <div className="modal-footer">
                  <button type="submit" className="btn btn-default">
                    DELETE
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      {/* <!-- View Modal --> */}
      <div className="container">
        <div className="modal fade" id="viewOnboardingModal" role="dialog">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title fw-bold">ONBOARDING DETAILS</h4>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  id="closeDeleteModal"
                >
                  &times;
                </button>
              </div>
              <div className="modal-body">
                <div className="row g-3">
                  {[
                    { label: "Full Name", value: onboardingViewDetails?.name },
                    {
                      label: "Business Name",
                      value: onboardingViewDetails?.restaurant_name,
                    },
                    { label: "Email", value: onboardingViewDetails?.email },
                    {
                      label: "Phone No",
                      value: onboardingViewDetails?.phone_no,
                    },
                    { label: "Address", value: onboardingViewDetails?.address },
                    { label: "Country", value: onboardingViewDetails?.country },
                    { label: "State", value: onboardingViewDetails?.state },
                    { label: "City", value: onboardingViewDetails?.city },
                    { label: "ZIP", value: onboardingViewDetails?.zip },
                    {
                      label: "Working Hours",
                      value: onboardingViewDetails?.working_hours,
                    },
                    // {
                    //   label: "About Us",
                    //   value: onboardingViewDetails?.about_us,
                    // },
                    {
                      label: "Website Link",
                      value: onboardingViewDetails?.website_link,
                    },
                  ].map(({ label, value }, index) => (
                    <div className="col-md-6" key={index}>
                      <div className="d-flex">
                        <strong className="me-2" style={{ width: "32%" }}>
                          {label}
                        </strong>
                        <strong className="me-2" style={{ width: "3%" }}>
                          :
                        </strong>
                        <span style={{ width: "65%" }}>{value}</span>
                      </div>
                    </div>
                  ))}

                  <div className="col-md-12">
                    <div className="d-flex">
                      <strong className="me-2" style={{width: '15%'}}>
                        About Us
                      </strong>
                      <strong className="me-2" style={{ width: "3%" }}>:</strong>
                      <span style={{ width: "82%" }}>
                        {onboardingViewDetails?.about_us}
                      </span>
                    </div>
                  </div>

                  {/* Is Logo Upload */}
                  <div className="col-md-12">
                    <div className="d-flex">
                      <strong className="me-2">
                        Is Application Logo Uploaded from Website:
                      </strong>
                      <span>
                        {onboardingViewDetails?.is_logo === 0 ? "NO" : "YES"}
                      </span>
                    </div>
                  </div>

                  {/* Logo */}
                  {onboardingViewDetails?.is_logo === 0 ? (
                    <div className="col-md-12">
                      <div className="d-flex align-items-center">
                        <strong className="me-2">App Logo:</strong>
                        {onboardingViewDetails?.logo && (
                          <a
                            href={onboardingViewDetails?.logo}
                            target="_blank"
                            download
                          >
                            <img
                              src={onboardingViewDetails?.logo}
                              alt="App Logo"
                              className="img-thumbnail"
                              style={{ width: "50px", height: "50px" }}
                            />
                          </a>
                        )}
                      </div>
                    </div>
                  ) : null}

                  {/* Product & Images Upload */}
                  <div className="col-md-12">
                    <div className="d-flex">
                      <strong className="me-2">
                        Is Product and Product Images Uploaded from Website:
                      </strong>
                      <span>
                        {onboardingViewDetails?.is_menu === 0 ? "NO" : "YES"}
                      </span>
                    </div>
                  </div>

                  {/* Product Files */}
                  {onboardingViewDetails?.is_menu != 1 ? (
                    <>
                      <div className="col-md-6">
                        <div className="d-flex">
                          <strong className="me-2">Products File:</strong>
                          <a href={onboardingViewDetails?.menu} download>
                            Download
                          </a>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="d-flex">
                          <strong className="me-2">Product Images File:</strong>
                          <a href={onboardingViewDetails?.menu_images} download>
                            Download
                          </a>
                        </div>
                      </div>
                    </>
                  ) : null}
                </div>
              </div>
              <div className="modal-footer justify-content-center">
                <button
                  type="button"
                  className="btn btn-default w-50"
                  data-dismiss="modal"
                  id="closeDeleteModal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const TableRow = ({ provided, item, index, handleView, handleDelete }) => (
  <tr
    ref={provided.innerRef}
    {...provided.draggableProps}
    {...provided.dragHandleProps}
    style={{ position: "relative", cursor: "auto" }}
  >
    <td>{index + 1}</td>
    <td>{item.name}</td>
    <td>{item.restaurant_name}</td>
    <td>{item.email}</td>
    <td>{item.phone_no}</td>
    <td>
      {new Intl.DateTimeFormat("en-US", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      }).format(new Date(item.created_at))}
    </td>

    <td>
      <button
        type="button"
        className="btn btn-link p-0"
        onClick={() => handleView(item)}
        data-toggle="modal"
        data-target="#viewOnboardingModal"
      >
        <i className="fa fa-eye popup me-4 eye-small"></i>
      </button>
      <button
        type="button"
        className="btn btn-link p-0"
        onClick={() => handleDelete(item)}
        data-toggle="modal"
        data-target="#deleteOnboardingModal"
      >
        <i className="fa fa-trash popup me-4 trash-small"></i>
      </button>
    </td>
  </tr>
);
