import { GENERATE_TOKEN, CLEAR_TOKEN } from "../constants/Constants";

let initialState = {
  token: [],
};

const generateTokenReducer = (state = initialState, action) => {
  switch (action.type) {
    case GENERATE_TOKEN:
      return {
        token: action.payload.token,
      };

      case CLEAR_TOKEN:
        return { ...initialState };

    default:
      break;
  }
  return state;
};

export default generateTokenReducer;
